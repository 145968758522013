import { Component, OnInit, HostListener ,NgZone} from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { Globals } from '../globals';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-searched-list',
  templateUrl: '../commondesign/commondesign.html',
  styleUrls: ['./searched-list.component.css']
})

export class SearchedListComponent implements OnInit {
  globals: Globals;
  Buyselllist: any;
  buysell: number = 0;
  searchstring: string = '';
  searchedarray: any[];
  selllist: any = [];
  buylist: any = [];
  bannerid: number;
  checkbanner: number;
  realoffset: number = 1
  realmaxload: number;
  keypressed: number = 0; //added by Kunal on 03/16/2020
  // Geolocation module added by Kunal bansal on 23 june 2021 start
  loggedstatus: number;
  locationstatus: number;
  Districtname: string;
  // end
  // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
  countrylocation: string;
  tab: number;
  // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
  storelabel = [];
  labellisting = [];
  activepage: string;
  parentlabel:string;

  // ...end...

  constructor(private ngZone: NgZone,private storage: SessionStorageService, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, globals: Globals) {
    this.globals = globals;
  }
  // page major modifications done by Kunal bansal on 11jan2021
  ngOnInit() {
    // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
    this.countrylocation = sessionStorage.getItem("phase5countrylocation");

    // ...end...\
    // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
    this.storelabel = this.storage.retrieve('brandlabelslisting');
    if(this.storelabel==null){
      this.storelabel=[];
    }
    // ...end...
    this.activepage = "searchpage";
    // ...end...


    // this.buylist = this.storage.retrieve('bannersearch');

    this.keypressed = this.storage.retrieve('pressedkey')       //added by Kunal on 03/16/2020
    this.searchstring = this.storage.retrieve('searchstring');
    this.bannerid = this.storage.retrieve('bannerid');
    this.checkbanner = this.storage.retrieve('checkbanner');

    this.buysell = this.storage.retrieve('pressedkey');
    // New scenario if link copy and open directly it should navigate to homepage directly start added by Kunal on 07 july 2021

    if (this.buysell == null) {
      this.router.navigate(['/homepage/productListing']);

    }
    else {
      // Geolocation module added by Kunal bansal on 23 june 2021 start
      this.locationstatus = this.storage.retrieve("locationstatus");
      this.loggedstatus = this.storage.retrieve("islogged");
      this.Districtname = this.storage.retrieve("districtname");
      // end
      if (this.searchstring == null || this.searchstring == undefined) {
      this.tab=this.keypressed;
        // commented by Kunal bansal on 18 june 2020 start
        // this.router.navigate(['/homepage/productListing']);
        // end
      }
      else if (this.keypressed == 0) {        //added by Kunal on 03/16/2020
        this.defaultapi(0, this.realoffset)
        this.buysell = 0;
        this.tab = 0;
      }
      else if (this.keypressed == 1) {        //added by Kunal on 03/16/2020
        this.defaultapi(1, this.realoffset)
        this.buysell = 1;
        this.tab = 1;
      }

      // alert('a')
      if (this.checkbanner == 1) {
        this.bannersearching();
      }
    }

  }

  ngOnDestroy() {
    //  this.storage.clear('checkbanner')
  }

  @HostListener("window:scroll", ["$event"])

  onWindowScroll() {
    // pagination issue fixed by Kunal bansal on 05feb2021
    //  phase 5 pagination scenario removed as per discussion with client
    // let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    // let max = document.documentElement.scrollHeight;
    // if (Math.round(pos) == max) {

    //   if ((this.buysell == 0 && this.realmaxload > this.buylist.length) || (this.buysell == 1 && this.realmaxload > this.selllist.length)) {

    //    this.defaultapi(this.buysell,this.realoffset)
    //   }
    // }
  }


  // banner search module added by Kunal bansal on 22 june 2020 start
  bannersearching() {
   
    this.authservice.apipost('searchListing/', {
      "banner_id": this.bannerid,
      "platform": 0,
      "tab": this.buysell,
      "offset": 1,
      // Geolocation module added by Kunal bansal on 23 june 2021 start
      "location": this.locationstatus == 1 && this.buysell == 0 ? this.Districtname : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation,
      "remove_list":[]
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
  
         // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
        this.labellisting = response.response.request_data;
        this.parentlabel=response.response.search_string;
        console.log(this.labellisting)
        // ...end...
        if (this.buysell == 0) {
       
          this.buylist = response.response.buying_list;
       

         
        }
        else {

          this.selllist = response.response.selling_list

        }
  
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  // end

  // <!-- phase 5 new scenario added by Kunal bansal on 27 sep 2021 start -->
  Removelabel(id,child) {

    if (id == 0) {
      this.router.navigate(['/homepage/productListing']);
    }
    else {


      this.storelabel.push(id);
      // phase 5 auto child append for removal added by Kunal bansal on 01 oct 2021 start
      if (child != undefined) {
        if (child.length > 0) {
          for (let b = 0; b < child.length; b++) {
            this.storelabel.push(child[b])
          }
        }
      }
      // ...end...

      this.storage.store('brandlabelslisting',this.storelabel);
      let body={};
      if(this.checkbanner==1){
        body={
          "banner_id": this.bannerid,
          "platform": 0,
          "tab": this.buysell,
          "offset": this.realoffset,
          // Geolocation module added by Kunal bansal on 23 june 2021 start
          "location": this.locationstatus && this.buysell == 0 ? this.Districtname : "",
          // end
          // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          "country": this.countrylocation,
          "remove_list":this.storelabel
        }
      }
     else{
      body={
        "search_string": this.searchstring,
        "platform": 0,
        "tab": this.buysell,
        "offset": this.realoffset,
        // Geolocation module added by Kunal bansal on 23 june 2021 start
        "location": this.locationstatus && this.buysell == 0 ? this.Districtname : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation,
        "remove_list":this.storelabel
        }
     }
      
      this.authservice.apipost('searchListing/', 
        body
      ).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
          this.labellisting = response.response.request_data;
          this.parentlabel=response.response.search_string;
      // ...end...
     
          if (this.buysell == 0) {

            this.buylist = response.response.buying_list

          }
          else {

            this.selllist = response.response.selling_list

          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  //  ...end...


  defaultapi(tab, offset) {

    if (this.checkbanner == 1) {
 
      this.authservice.apipost('searchListing/', {
        "banner_id": this.bannerid,
        "platform": 0,
        "tab": this.buysell,
        "offset": this.realoffset,
        // Geolocation module added by Kunal bansal on 23 june 2021 start
        "location": this.locationstatus && this.buysell == 0 ? this.Districtname : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation,
        "remove_list":this.storelabel
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          
          // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
          this.labellisting = response.response.request_data;
          this.parentlabel=response.response.search_string;
      // ...end...
          if (this.buysell == 0) {

            this.buylist = response.response.buying_list;
          

          }
          else {

            this.selllist = response.response.selling_list;

          }

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

    else {

      this.authservice.apipost('searchListing/', {
        "search_string": this.searchstring,
        "platform": 0,
        "tab": this.buysell,
        // "offset": this.realoffset,
        "offset": 1,
        // Geolocation module added by Kunal bansal on 23 june 2021 start
        "location": this.locationstatus && this.buysell == 0 ? this.Districtname : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation,
        "remove_list":this.storelabel
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
          this.labellisting = response.response.request_data;
          this.parentlabel=response.response.search_string;
      // ...end...
          this.realmaxload = response.response.total_count;
          if (this.buysell == 0) {
            this.buylist = response.response.buying_list;
            // this.buylist = this.realoffset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
            // this.realoffset = this.realoffset + 1;
          }
          else {
            this.selllist = response.response.selling_list;
            // this.selllist = this.realoffset== 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
            // this.realoffset = this.realoffset + 1;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  buysellchange(i, offset) {
    if (i == 1) {
      this.storage.store("pressedkey", 1);            //added by Kunal on 03/16/2020
      this.buysell = i;
      this.tab = i;
      this.realoffset = 1;
      this.defaultapi(1, 1)
    }
    else {
      this.storage.store("pressedkey", 0);           //added by Kunal on 03/16/2020
      this.buysell = i;
      this.tab = i;
      this.realoffset = 1;
      this.defaultapi(i, offset)

    }
  }
  querybutton234() { //Added by Kunal bansal on 24 april 2020
    this.storage.store("missproduct", 2)
    this.router.navigate(['./homepage/asknow']);
  }
  // phase 5 modified by Kunal bansal on 27 sep 2021

  pdetail(id, cateid) {

    this.storage.store("pdid", id);

    this.storage.store("ptype", 0);
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: cateid, ptype: 0 } });
  }


  productdetail(id, cateid) {

    this.storage.store("pdid", id);

    this.storage.store("ptype", 1);

    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: cateid, ptype: 1 } });
  }

}
