import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router, NavigationEnd } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css','../../assets/Newwebfiles/style.css']
})
export class HeaderComponent implements OnInit {
  contents: any[] = [];
  loginstatus:number=0;

  constructor(private server: DataService,private router: Router,private storage: SessionStorageService) { }
// Added routing modules and Login logout sysytem in header by Kunal bansal on 13 august 2020
  ngOnInit() {
    this.getHeaders();
    this.loginstatus=this.storage.retrieve('islogged')

  }

  private getHeaders() {
    this.server.getHeaders().then((response: any) => {
      this.contents = response.data[0];
    });
  }

  openpage(a) {
    if (a == 1) {
      if(this.router.url == "/AboutEOL/what-we-do"){
        document.querySelector('#section1').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      else{
        this.router.navigate(['AboutEOL/what-we-do']);
      }
    }
    else if(a==0){
      this.router.navigate(['AboutEOL/AboutUs']);
    }
    else if (a == 2) {
        // workflow navigate from any page added by Kunal bansal on 06 may 2021
      if(this.router.url == "/AboutEOL/what-we-do"){
        document.querySelector('#my-flow').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      else{
        this.router.navigate(['AboutEOL/what-we-do']);
        setTimeout(() => {
          document.querySelector('#my-flow').scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 1100);
      }

    }
    else if (a == 3) {
        // <!-- phase 6 new website correction navigate to section module added by kunal bansal on 27 may 2022 start -->
      // this.router.navigate(['AboutEOL/technology']);
      if(this.router.url == "/AboutEOL/what-we-do"){
        document.querySelector('#section5').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      else{
      this.router.navigate(['AboutEOL/what-we-do']);
      setTimeout(() => {
        document.querySelector('#section5').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 1100);
    }
    // ....end.....
    }
    else if (a == 4) {
      this.router.navigate(['AboutEOL/who-we-are']);
    }
    else if (a == 5) {
      this.router.navigate(['AboutEOL/Ourteam']);
    }
    else if (a == 6) {
      this.router.navigate(['AboutEOL/career']);
    }
    else if (a == 7) {
      this.router.navigate(['AboutEOL/post-your-requirement']);
    }
    else if (a == 8) {
      this.router.navigate(['homepage/productListing']);
    }
    else if (a == 9) {
      this.router.navigate(['AboutEOL/faq']);
    }
    else if (a == 10) {
      this.router.navigate(['AboutEOL/terms-conditions']);
    }
    else if (a == 11) {
      this.router.navigate(['AboutEOL/contactus2']);
    }
    else if (a == 12) {
      this.router.navigate(['homepage/login']);
    }
    else if (a == 13) {
      this.storage.store("islogged", 0);
      this.storage.clear("token");
      this.router.navigate(['/homepage/login']);
    }
    else {
      this.router.navigate(['AboutEOL/AboutUs']);
    }
  }



}
