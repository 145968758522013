import { Component, OnInit, HostListener, Output, Input } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-my-orders-sell',
  templateUrl: './my-orders-sell.component.html',
  styleUrls: ['./my-orders-sell.component.css']
})

export class MyOrdersSellComponent implements OnInit {
  orders: any[];
  allorother: number = 0;
  closeResult: string;
  orderid: number;
  trackdate: any;
  tracknumber: any;
  todaydate: any;
  cancelreason: string = '';
  cancelcategory: string = '1';
  weight: any;
  breadth: any;
  count: any = 1;
  height: any;
  length: any;
  piece: any;
  pickupdate: any;
  pickuptime: any;
  collectammount: any;
  listing: any = [];
  selectedtab: number = 1;
  totalcount: any;
  flag: number = 1;
  dispatchtype: number = 1;

  selftrackdate: any;
  selfinstructions: string = '';
  selfexpectdate: any;

  othertrackdate: any;
  otherinstructions: string = '';
  otherexpecttrackdate: any;
  othertracknumber: string = '';
  modalReference: NgbModalRef;
  category: any[];
  filter = [];
  activefilter: number = 0;
  realoffset: number = 1;
  status: string;

  faq: any[];
  temp: number;
  temp2: number = 0;
  checkedsel: number = 1;
  checkradio: number = 0;
  pickupdatefrom: string = "";
  pickupdateto: string = "";
  selectedorderno: any[];
  selectedorderstatus: any[];
  selectedordertype: any[];

  pushorderno = [];
  pushorderstatus = [];
  pushordertype = [];
  ordernumbers = [];
  categorylisting = [];
  categorylisting2 = [];
  showcategory = [];

  dropdownSettings2 = {};
  dropdownSettings3 = {};
  selectactive: number = 1;

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  showlabel: number = 0;
  Appliedcatfilters = [];
  duplicatecatfilters = [];
  Applieddatefrom = [];
  Applieddateto = [];
  Appliedordero = [];
  Appliedorderstatus = [];
  Appliedordertype = [];
  // end
  tab: number = 0;
  // added by Kunal bansal on 02feb2021 to add validation on dates
  tomindate: string = "";
  frommaxdate: string = "";
  // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 02 march 2021 start -->
  agentcodedata = [];
  selectedagentcode: any[];
  agentcodeapi = [];
  Appliedagentcodeapi = [];

  address: any[];
  manualsearchfield: string;
  agentSettings = {};
  // phase 4 new cr partial payment module update payment added by Kunal bansal on 09 aug 2021 start -->
  paymentmodes = ["Cash", "Net Banking", "Other"]
  Activepayment: number = 0;

  Topay: string;
  selectedpayment: string = "Cash";
  Remarks: string;
  Accountnumber: string;
  Paymentdetails: string;
  order_number: string;
  Order_id: number;
  amount_payble: number;
  prepaid_amount: number;
  cod_amount: number;
  // end

  // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 09 august 2021 start
  selectedpayments = [];
  selectedpaymentlabels = [];
  Appliedpaymentlabels = [];
  paymentmodule = [];
  paymentlisting: any[];
  // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
  selectedfromdate: string;
  frommaxdateupdate: string;
  paymenttime: any;

  // end
  shippingaddressradio: boolean = true;

  country: string = "India";

  ewayuploadedfile:any;
  ordernumber:number;

  



  constructor(public datepipe: DatePipe, config: NgbModalConfig, private modalService: NgbModal, private authservice: AuthService, private spinner: NgxSpinnerService, private router: Router, private storage: SessionStorageService) {


  }

  // Pagination Issue Fixed  and new pagination module added on 1 sep 2020 by Kunal bansal

  ngOnChanges(changes: any) {
    if (changes.orders.currentValue != undefined) {
      this.listing = changes.orders.currentValue.selling_list.in_progress
    }
  }
  @HostListener("window:scroll", ["$event"])

  // onWindowScroll(e) {

  //   let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
  //   let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   let max1 = document.body.scrollHeight - 10;
  //   //console.log(Math.round(pos) +" == "+ max +" and "+ Math.round(pos1) +" == "+ max1)
  //   if (Math.round(pos) == max || Math.round(pos1) == max1 || Math.round(pos1) > max1) {
  //     if (this.listing.length < this.totalcount) {
  //       if (this.activefilter == 0) {
  //         this.getlist(this.selectedtab, this.flag);
  //       }
  //       else {
  //         this.filter=this.storage.retrieve('filterorders')

  //         this.Applyfilter(this.allorother,this.realoffset)
  //       }
  //     }
  //   }
  onWindowScroll(e) {

    if (($(window).scrollTop() + $(window).innerHeight()) >= $(document).height() - this.getScrollbarWidth()) {
      if (this.listing.length < this.totalcount) {
        if (this.activefilter == 0) {
          this.getlist(this.selectedtab, this.flag);
        }
        else {
          this.filter = this.storage.retrieve('filterorders')

          this.Applyfilter(this.allorother, this.realoffset)
        }
      }
    }

  }

  getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  }


  // @HostListener("window:scroll", ["$event"])

  // onWindowScroll(e) {
  //   //In chrome and some browser scroll is given to body tag
  //   let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
  //   let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   let max1 = document.body.scrollHeight - 10;
  //   //console.log(Math.round(pos) +" == "+ max +" and "+ Math.round(pos1) +" == "+ max1)
  //   if (Math.round(pos) == max || Math.round(pos1) == max1 || Math.round(pos1) > max1) {
  //     if (this.listing.length < this.totalcount) {
  //       if(this.activefilter=0){
  //       this.getlist(this.selectedtab, this.flag);
  //       }
  //       else{
  //         this.Applyfilter()
  //       }
  //     }
  //   }
  // }



  ngOnInit() {
    this.country = sessionStorage.getItem('phase5country');
    // this.agentcodedata.push({ 'name': "Kunal12345", 'id': 1 }, { 'name': "Abc123444F", 'id': 2 })
    this.getlist(0, 1)
    let date = new Date();
    this.todaydate = this.datepipe.transform(date, 'yyyy-MM-dd');

    this.categorylisting2.push({
      "name": "Offline", "id": 1
    }, { "name": "Online", "id": 0 })
    this.dropdownSettings2 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableCheckAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "name",
      classes: "font_drop",
      maxHeight: 200

    };
    this.dropdownSettings3 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableCheckAll: false,
      enableSearchFilter: false,
      text: "Select",

      labelKey: "ordernumber",
      classes: "font_drop",
      maxHeight: 200

    };
    this.agentSettings = {
      singleSelection: false,
      enableFilterSelectAll: true,
      enableSearchFilter: false,
      text: "Select",
      enableCheckAll: true,
      labelKey: "code",
      classes: "font_drop",
      maxHeight: 200

    };

    // added reset scenario stay on that tab on 25jan2021 by Kunal bansal
    this.tab = this.storage.retrieve("myorderselltab");
    if (this.tab == null) {
      this.getlist(0, 1)
      this.allorother = 0;

    }
    else {
      this.getlist(this.tab, 1)
      if (this.tab == 0) {
        this.allorother = 0;
      }
      else {
        this.allorother = 1;
      }
    }

    // <!-- phase 4 cr payment option filteration module added by Kunal bansal on 09 august 2021 start -->
    this.paymentmodule = [{ "name": "Online Payment", id: 2 }, { "name": "POD", id: 1 }, { "name": "Partial Payment", id: 3 }];
    // end
  }


  ngOnDestroy() {
    this.activefilter = 0;
    this.storage.clear('myorderselltab');
  }

  changedispatch(i) {

    this.formempty();
    this.dispatchtype = i


    if (i == 4) {
      this.shiprocketmode()
    }
  }

  formempty() {
    this.breadth = [];
    this.height = [];
    this.length = [];
    this.weight = [];
    this.pickupdate = [];
    this.pickuptime = [];
    this.status = '';
    this.selfinstructions = '';
    this.selftrackdate = [];
    this.selfexpectdate = [];
    this.otherexpecttrackdate = [];
    this.otherinstructions = '';
    this.othertrackdate = [];
    this.othertracknumber = '';
  }

  shiprocketmode() {
    // modified by Kunal bansal on 22jan 2021
    this.authservice.apipost('getShipRocketServiceMessage/', {
      "order_id": this.orderid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        this.status = '';
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.status = '';
        if (response.response.service_name) {

          this.status = response.response;
        }
        else {
          this.dispatchtype = 4;


        }

        // alert(JSON.stringify(response.response))
      }
      else if (response.status == 404) {
        this.status = '';
        Swal.fire(response.message);


      }
      else {
        this.status = '';
        Swal.fire(response.message)
      }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // phase 4 new cr partial payment module update payment added by Kunal bansal on 09 august 2021 start -->
  updatepay(update, orderno, totalamount, prepaidamount, codamount, orderid) {
    this.Activepayment = 0;
    this.selectedpayment = "Cash";
    this.order_number = orderno;
    this.Order_id = orderid;
    this.amount_payble = totalamount;
    this.prepaid_amount = prepaidamount;
    this.cod_amount = codamount;
    this.modalService.open(update, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
    // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if (month.toString().length == 1 && day.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + day;
      this.frommaxdateupdate = fulldate;

    }
    else if (month.toString().length == 2 && day.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + day;
      this.frommaxdateupdate = fulldate;

    }
    else if (month.toString().length == 1 && day.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + day;
      this.frommaxdateupdate = fulldate;

    }
    else {
      var fulldate = year + "-" + month + "-" + day;
      this.frommaxdateupdate = fulldate;

    }
    // end
  }

  // phase 4 new cr partial payment module update payment added by Kunal bansal on 09 aug 2021 start -->
  paymentswitch(f) {
    this.Activepayment = f;
    if (f == 0) {
      this.selectedpayment = "Cash";
    }
    else if (f == 1) {
      this.selectedpayment = "Net Banking";
    }
    else if (f == 2) {
      this.selectedpayment = "OTHER";
    }
  }
  // end

  // phase 4 new cr partial payment module gettimestamp function added by Kunal bansal on 09 aug 2021 start
  getTimeStamp() {
    var now = new Date();
    return (now.getFullYear() + '-' +
      ((now.getMonth() + 1 < 10)
        ? ("0" + (now.getMonth() + 1))
        : (now.getMonth() + 1)) + '-' +
      ((now.getDate() < 10)
        ? ("0" + now.getDate())
        : (now.getDate())) + " " +
      now.getHours() + ':' +
      ((now.getMinutes() < 10)
        ? ("0" + now.getMinutes())
        : (now.getMinutes())) + ':' +
      ((now.getSeconds() < 10)
        ? ("0" + now.getSeconds())
        : (now.getSeconds())));
  }
  // end

  // phase 4 new cr partial payment module gettimestamp function added by Kunal bansal on 09 august 2021 start
  validate(e) {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 190 || e.keyCode == "08") {
      return true
    }
    else {
      return false
    }
  }


  // phase 4 new cr partial payment module gettimestamp function added by Kunal bansal on 09 aug 2021 start
  submitpayment() {

    if (this.Topay == null || this.Topay.trim() == "") {
      this.Topay = "";
      Swal.fire("Please Enter Amount")
    }
    else if (Number(this.Topay) * 1 == 0) {

      Swal.fire("Amount cannot be Zero. Enter Valid Amount")
    }
    else if (this.Activepayment == 1 && this.Accountnumber == null || this.Activepayment == 1 && this.Accountnumber.trim() == "") {
      this.Accountnumber = "";
      Swal.fire("Please enter account number")
    }
    else if (this.Activepayment == 2 && this.Paymentdetails == null || this.Activepayment == 2 && this.Paymentdetails.trim() == "") {
      this.Paymentdetails = "";
      Swal.fire("Please enter payment details")
    }
    // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
    else if (this.selectedfromdate == undefined || this.selectedfromdate == "") {
      Swal.fire("Please select date")
    }
    else if (this.paymenttime == undefined) {
      Swal.fire("Please select time")
    }
    // end

    else {
      // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
      var date = this.selectedfromdate.split('-').join('-');
      var customtimestamp = date + " " + this.paymenttime;
      // end
      let paymentdetail = "";
      if (this.Activepayment == 0) {
        paymentdetail = ""
      }
      else if (this.Activepayment == 1) {
        paymentdetail = this.Accountnumber;
      }
      else if (this.Activepayment == 2) {
        paymentdetail = this.Paymentdetails;
      }

      this.authservice.apipost('Update_Partial_Payment/', {
        "order_id": this.Order_id,
        "to_pay": Number(this.Topay),
        "payment_mode": this.selectedpayment,
        "pay_date": customtimestamp,
        "remarks": this.Remarks,
        "payment_details": paymentdetail,


      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.modalService.dismissAll();
          Swal.fire(response.message);
          this.Topay = "";
          this.selectedpayment = "Cash";
          this.Remarks = "";
          this.Paymentdetails = "";
          this.Accountnumber = "";

          this.ngOnInit();


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("Connect to the Internet")
          Swal.fire("Network Error")
        }
      )
    }

  }
  // end

  opencategorymodal(shopbycategory) {
    this.realoffset = 1;
    this.showcategory = [];
    this.selectactive = 1;
    this.selectedorderno = [];
    this.selectedorderstatus = [];
    this.selectedordertype = [];
    // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 02 march 2021 start -->
    this.selectedagentcode = [];
    this.agentcodeapi = [];
    // end
    this.pickupdatefrom = "";
    this.pickupdateto = "";
    this.tomindate = "";
    // module added by Kunal bansal on 2 feb 2021 start
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    // modified by Kunal bansal on 05 march 2021 start
    if (month.toString().length == 1 && day.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + day;
      this.frommaxdate = fulldate;

    }
    else if (month.toString().length == 2 && day.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + day;
      this.frommaxdate = fulldate;

    }
    else if (month.toString().length == 1 && day.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + day;
      this.frommaxdate = fulldate;

    }
    else {
      var fulldate = year + "-" + month + "-" + day;
      this.frommaxdate = fulldate;

    }

    // end

    this.pushorderno = [];
    this.pushorderstatus = [];
    this.pushordertype = [];

    this.temp = undefined;
    this.temp2 = undefined;
    this.checkradio = 0;
    this.filter = [];
    this.checkedsel = 1;
    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    this.authservice.apipost('CategoryList/', {
      "flag": "filtercatgory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))
        this.category = response.response;
        this.faq = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

    // api for Order number start

    this.authservice.apiget('static_Filter_Data/?rtype=sell').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))


        this.ordernumbers = response.response;


      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end


    // api for order status
    this.authservice.apiget('static_Filter_Data/?rtype=status').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))


        this.categorylisting = response.response;


      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end

    // api added by Kunal bansal on 22 march 2021 start
    this.authservice.apipost('static_Filter_Data/?rtype=agent_code', { tab: 1 }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))


        this.agentcodedata = response.response;
        this.agentcodedata.push({
          id: response.response.length + 1, "code": "Blank"
        })


      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
  }

  selectall(a) {
    this.selectactive = 0;
    for (let index = 0; index < this.category.length; index++) {
      for (let Innerindex = 0; Innerindex < this.category[index].child_category.length; Innerindex++) {

        if (this.filter.includes(this.category[index].child_category[Innerindex].id)) {
        }
        else {

          this.filter.push(this.category[index].child_category[Innerindex].id);
          this.Appliedcatfilters.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id });
          this.showcategory.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id, 'parindex': index, 'chiindex': Innerindex });
          //  $(".yup[type='checkbox']").prop('checked', true);
          for (let i = 0; i < this.category.length; i++) {
            for (let k = 0; k < this.category[i].child_category.length; k++) {
              this.category[i].child_category[k].checked = true;
            }

          }
        }
      }
    }
    this.temp2 = 1;
    this.temp = 1;
  }

  Deselectall() {

    this.Appliedcatfilters = [];
    this.selectactive = 1;
    this.filter = [];
    this.showcategory = [];
    // this.temp=undefined;
    // this.temp2=undefined;
    for (let i = 0; i < this.category.length; i++) {
      for (let k = 0; k < this.category[i].child_category.length; k++) {
        this.category[i].child_category[k].checked = false;
      }

    }
  }

  openfaq(i) {

    if (this.temp == i) {
      this.temp = undefined;
    }
    else {

      this.temp = i

    }
  }
  // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 22 march 2021 start -->
  manualsearch() {
    this.realoffset = 1;
    this.authservice.apipost('myOrder_sell/', {
      "platform": 0,
      "tab": this.allorother,
      "offset": this.realoffset,
      "search_string": this.manualsearchfield
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.Applieddatefrom = [];
        this.Applieddateto = [];
        this.duplicatecatfilters = [];
        this.Appliedordero = [];
        this.Appliedorderstatus = [];
        this.Appliedordertype = [];
        this.Appliedagentcodeapi = [];
        if (this.realoffset == 1) {
          this.listing = response.response.selling_list;
          this.totalcount = response.response.total_count;
        }
        else {
          this.listing = this.listing.concat(response.response.buying_list);
        }
        //  this.offset=this.offset+1;
        this.manualsearchfield = "";



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("Connect to the Internet")
        Swal.fire("Network Error")
      }
    )
    // this.listing=[];
  }
  agentchange(item: any) {
    // console.log(item)
    // alert(JSON.stringify(this.agentcodedata))
    if (this.agentcodeapi.includes(item.code)) {

    }
    else {
      this.agentcodeapi.push(item.code);
    }
    if (item.name == "Blank") {

    }
    // for(let f=0;f<this.agentcodedata.length;f++){
    //   if(this.agentcodedata[f].code=="Blank"){

    //   }
    // }
  }

  Removeagentchange(item: any) {
    if (this.agentcodeapi.includes(item.code)) {
      let k = this.agentcodeapi.indexOf(item.code);
      this.agentcodeapi.splice(k, 1);
    }
  }
  // added by Kunal bansal on 22 march 2021 start
  onSelectAllagentcode(event: any) {

    for (let z = 0; z < this.agentcodedata.length; z++) {
      if (this.agentcodeapi.includes(event[z].code)) {

      }
      else {
        this.agentcodeapi.push(event[z].code);
      }
    }
  }
  // end

  onDeSelectAllagentcode(event: any) {
    this.agentcodeapi = [];
  }
  // end

  openfaq2(c) {
    // this.checkradio=5;
    if (this.temp2 == c) {
      this.temp = undefined;
      this.temp2 = undefined;
    }
    else {


      this.temp2 = 1;

    }

  }

  onChange(a) {
    this.temp = undefined;
    this.temp2 = undefined;
    this.checkradio = a;
    if (a == 1) {
      this.pushorderno = [];
      this.pushorderstatus = [];
      this.pushordertype = [];

      this.selectedorderno = [];
    }

    if (a == 2) {
      this.pickupdatefrom = "";
      this.pickupdateto = "";
      this.pushorderstatus = [];
      this.pushordertype = [];
      this.authservice.apiget('static_Filter_Data/?rtype=sell').subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // alert(JSON.stringify(response.response.buying_list))


          this.ordernumbers = response.response;


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    if (a == 3) {
      this.pickupdatefrom = "";
      this.pickupdateto = "";
      this.pushorderno = [];
      this.pushordertype = [];
      this.authservice.apiget('static_Filter_Data/?rtype=status').subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // alert(JSON.stringify(response.response.buying_list))


          this.categorylisting = response.response;


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    if (a == 4) {
      this.pickupdatefrom = "";
      this.pickupdateto = "";
      this.pushorderno = [];
      this.pushorderstatus = [];

    }
  }

  removeshowcategory(s, idchild, parent, child) {


    let index = this.filter.indexOf(idchild)

    this.Appliedcatfilters.splice(s, 1);
    this.filter.splice(index, 1)

    this.showcategory.splice(s, 1)


    this.category[parent].child_category[child].checked = false;





  }
  commonfilter() {
    var fromdate = this.pickupdatefrom.split('-').reverse().join('/');
    var todate = this.pickupdateto.split('-').reverse().join('/');
    if (this.pickupdateto.length == 0 && this.pickupdatefrom.length != 0) {
      Swal.fire("Please select To Date")
    }
    else {
      this.authservice.apipost('orderFilter/', {
        "platform": 0,
        "tab": this.selectedtab,
        "offset": 1,
        "category_id": this.filter,
        "orderdate": this.pickupdatefrom.length != 0 && this.pickupdateto.length != 0 ? fromdate + "-" + todate : "",
        "ordernumber": this.pushorderno,
        "orderstatus": this.pushorderstatus,
        "ordertype": this.pushordertype,
        "agent_code": this.agentcodeapi,

        "order_tab": "sell",
        // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start
        "payment_mode": this.selectedpayments
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          this.modalService.dismissAll();
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          if (this.realoffset == 1) {
            this.listing = response.response.selling_list;

          }
          else {
            this.listing = this.listing.concat(response.response.selling_list);
          }
          this.totalcount = response.response.total_count;
          this.filter = [];
          //  this.realoffset=this.realoffset+1;
          // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start

          this.paymentlisting = [];
          this.selectedpayments = [];
          this.Appliedpaymentlabels = this.selectedpaymentlabels;
          this.selectedpaymentlabels = [];


          //  .........................end......................
          this.activefilter = 1;
          alert('yes')
          this.modalService.dismissAll();
          this.realoffset = this.realoffset + 1;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

  }



  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  fromdate(event) {

    var fromdate = event.target.value.split('-').reverse().join('/');
    this.Applieddatefrom = fromdate;
    // added by Kunal bansal on 02feb2021 to add validation on dates
    this.tomindate = event.target.value;

  }

  // end


  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  todate(event) {
    // added by Kunal bansal on 02feb2021 to add validation on dates
    if (this.pickupdatefrom.length == 0) {
      Swal.fire("please select upper date first")
      this.pickupdateto = "";
    }
    else {

    }
    var todate = event.target.value.split('-').reverse().join('/');
    this.Applieddateto = todate;
  }

  // end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeordernumber(index, id) {
    this.Appliedordero.splice(index, 1)
    var ordno = this.pushorderno.indexOf(id)
    this.pushorderno.splice(ordno, 1)
    this.commonfilter();
  }
  // end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeorderstatus(index, id) {
    this.Appliedorderstatus.splice(index, 1)
    var ordstatus = this.pushorderstatus.indexOf(id)
    this.pushorderstatus.splice(ordstatus, 1)
    this.commonfilter();
  }
  // end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeordtype(index, id) {
    this.Appliedordertype.splice(index, 1)
    var ordtype = this.pushordertype.indexOf(id)
    this.pushordertype.splice(ordtype, 1)
    this.commonfilter();
  }
  // end


  toggleVisibility(e, a, catname, i, k) {


    var index2 = this.filter.indexOf(a);


    if (e.target.checked == true) {
      this.filter.push(a)
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      if (this.Appliedcatfilters.includes(catname)) {

      }
      else {
        this.Appliedcatfilters.push({ 'id': a, 'name': catname });

      }
      this.showcategory.push({ 'name': catname, "id": a, "parindex": i, "chiindex": k })



    }
    else {
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      var element2 = this.Appliedcatfilters.indexOf(catname);
      this.Appliedcatfilters.splice(element2, 1)
      // end
      for (let index = 0; index < this.showcategory.length; index++) {
        var element = this.showcategory[index].name;
        if (element == catname) {
          this.showcategory.splice(index, 1)

        }
      }
      this.filter.splice(index2, 1)

    }


  }

  // phase 4 new cr partial payment module in my order params added by Kunal bansal on 09 august 2021 start
  paymentselection(event) {
    if (this.selectedpayments.includes(event.id)) {

    }
    else {
      this.selectedpayments.push(event.id);
      this.selectedpaymentlabels.push({ "name": event.name });
    }
  }

  paymentDeselection(event) {
    let find = this.selectedpayments.indexOf(event.id);
    this.selectedpayments.splice(find, 1);
    this.selectedpaymentlabels.splice(find, 1);
  }

  // end

  Removefilter() {

    // this.showcategory = [];
    // this.filter = [];
    // this.pickupdatefrom = "";
    // this.pickupdateto = "";
    // this.selectedorderno = [];
    // this.selectedorderstatus = [];
    // this.selectedordertype = [];
    // this.pushorderno=[];
    // this.pushorderstatus=[];
    // this.pushordertype;
    // $(".yup[type='checkbox']").prop('checked', false);
    // for (let index = 0; index < this.category.length; index++) {
    //   for (let Innerindex = 0; Innerindex < this.category[index].child_category.length; Innerindex++) {
    //     if (this.selectallfor.includes(this.category[index].child_category[Innerindex].id)) {
    //     }
    //     else {

    //       this.selectallfor.push(this.category[index].child_category[Innerindex].id);
    //     }

    //   }
    // }
    location.reload();
  }

  onOpen() {

    this.temp2 = undefined;
    this.temp = undefined;
    // if (this.filter.length == 0) {
    //   Swal.fire('Please select Category First')
    // }

  }

  Byorderno(item: any) {
    this.pushorderno.push(item.ordernumber);
    // this.Appliedordero.push(item)

    // alert(item.ordernumber)
  }

  Byorderstatus(item: any) {

    this.pushorderstatus.push(item.id);
    //  this.Appliedorderstatus.push(item)
  }

  Byordertype(item: any) {

    this.pushordertype.push(item.id);
    //  this.Appliedordertype.push(item)
  }

  removeorderno(item: any) {
    var orno = this.pushorderno.indexOf(item.ordernumber);

    this.pushorderno.splice(orno, 1)

  }
  removestatus(item: any) {
    var orstatus = this.pushorderstatus.indexOf(item.id);

    this.pushorderstatus.splice(orstatus, 1)

  }
  removeordertype(item: any) {
    var ortype = this.pushorderstatus.indexOf(item.id);

    this.pushordertype.splice(ortype, 1)

  }

  Applyfilter(a, b) {
    var fromdate = this.pickupdatefrom.split('-').reverse().join('/');
    var todate = this.pickupdateto.split('-').reverse().join('/');




    this.storage.store('filterorders', this.filter)
    if (this.pickupdateto.length == 0 && this.pickupdatefrom.length != 0) {
      Swal.fire("Please select To Date")
    }
    else {
      // if(this.filter.length==0 ){
      //   Swal.fire("Select at least one category filter cannot be empty")
      //       }
      //       else{

      this.authservice.apipost('orderFilter/', {
        "platform": 0,
        "tab": a,
        "offset": b,
        "category_id": this.filter,
        "orderdate": this.pickupdatefrom.length != 0 && this.pickupdateto.length != 0 ? fromdate + "-" + todate : "",
        "ordernumber": this.pushorderno,
        "orderstatus": this.pushorderstatus,
        "ordertype": this.pushordertype,
        "agent_code": this.agentcodeapi,
        "order_tab": "sell",
        // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start
        "payment_mode": this.selectedpayments
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 100) {

          this.modalService.dismissAll();
          Swal.fire(response.message)
        }
        // status 500 added and separately managed by Kunal bansal on 25jan 2021
        else if (response.status == 500) {
          this.duplicatecatfilters = this.Appliedcatfilters;
          this.Appliedcatfilters = [];
          this.Appliedordero = this.selectedorderno;
          this.Appliedorderstatus = this.selectedorderstatus;
          this.Appliedordertype = this.selectedordertype;
          // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 02 march 2021 start -->
          this.Appliedagentcodeapi = this.agentcodeapi;
          // end
          this.showlabel = 1;
          this.totalcount = response.response.total_count;
          this.filter = [];

          // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start

          this.paymentlisting = [];
          this.selectedpayments = [];
          this.Appliedpaymentlabels = this.selectedpaymentlabels;
          this.selectedpaymentlabels = [];


          //  .........................end......................
          this.activefilter = 1;

          this.listing = response.response.selling_list;

          this.modalService.dismissAll();
        }
        else if (response.status == 200) {

          this.duplicatecatfilters = this.Appliedcatfilters;
          this.Appliedcatfilters = [];
          this.Appliedordero = this.selectedorderno;
          this.Appliedorderstatus = this.selectedorderstatus;
          this.Appliedordertype = this.selectedordertype;
          // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 02 march 2021 start -->

          this.Appliedagentcodeapi = this.agentcodeapi;
          // end

          this.showlabel = 1;

          if (this.realoffset == 1) {
            this.listing = response.response.selling_list;

          }
          else {
            this.listing = this.listing.concat(response.response.selling_list);

          }
          this.totalcount = response.response.total_count;;

          this.filter = [];


          //  this.realoffset=this.realoffset+1;
          // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start

          this.paymentlisting = [];
          this.selectedpayments = [];
          this.Appliedpaymentlabels = this.selectedpaymentlabels;
          this.selectedpaymentlabels = [];


          //  .........................end......................
          this.activefilter = 1;

          this.modalService.dismissAll();
          this.realoffset = this.realoffset + 1;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    // }


  }

  getlist(list, flag) {
    this.authservice.apipost('myOrder_sell/', {
      "platform": 0,
      "tab": list,
      "offset": flag
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.activefilter = 0;
        if (flag == 1) {
          this.listing = response.response.selling_list;
          this.totalcount = response.response.total_count;
          this.selectedtab = list
        }
        else {
          this.listing = this.listing.concat(response.response.selling_list)
        }
        this.flag = this.flag + 1
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("Connect to the Internet")
        Swal.fire("Network Error")
      }
    )
  }

  orderdetails(id) {
    this.storage.store("orderid", id);
    this.storage.store("from_buyorsell", 1);
    this.router.navigate(['/homepage/orderdetails']);
  }

  cancelorder(cancel, id) {
    this.orderid = id
    this.modalService.open(cancel, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }

  cancelor() {
    if (this.cancelcategory == '' || this.cancelcategory == null) {
      Swal.fire('Please select a valid category from the dropdown');
    }
    else if (this.cancelreason == '' || this.cancelreason == null) {
      Swal.fire('Please add a valid reason for cancelling the order');
    }
    else {
      this.authservice.apipost('orderAction/', {
        action: "Cancelled",
        order_id: this.orderid,
        special_notes: this.cancelcategory,
        reason: this.cancelreason
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.activefilter = 0;
          this.modalService.dismissAll();
          location.reload();
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  viewchange() {

    if (this.allorother == 0) {

      this.allorother = 1;


      this.flag = 1
      // if (this.activefilter == 0) {
      // added on 25jan2021 to add above line by Kunal bansal 
      this.getlist(1, 1)
      this.storage.store("myorderselltab", 1)
      this.Applieddatefrom = [];
      this.Applieddateto = [];
      this.duplicatecatfilters = [];
      this.Appliedordero = [];
      this.Appliedorderstatus = [];
      this.Appliedordertype = [];
      this.Appliedagentcodeapi = [];
      // }
      // else {
      //   this.filter = this.storage.retrieve('filterorder')
      //   this.offset = 1;
      //   this.Applyfilter(1, 0)
      //   this.storage.store("myorderbuytab",1)
      // }
      //console.log(this.listing+"total")
    }
    else {

      this.allorother = 0;

      // added on 25jan2021 to add above line by Kunal bansal 
      this.flag = 1
      // if (this.activefilter == 0) {
      this.getlist(0, 1)
      this.storage.store("myorderselltab", 0)
      this.Applieddatefrom = [];
      this.Applieddateto = [];
      this.duplicatecatfilters = [];
      this.Appliedordero = [];
      this.Appliedorderstatus = [];
      this.Appliedordertype = [];
      this.Appliedagentcodeapi = [];
      // }
      // else {
      //   this.filter = this.storage.retrieve('filterorder')
      //   this.offset = 1;
      //   this.Applyfilter(0, 1)
      //   this.storage.store("myorderbuytab",0)
      // }
      //console.log(this.listing+"open")
    }
  }

  open2(dispatch, id, trackingid) {
    this.orderid = id;
    this.tracknumber = trackingid;
    $(document).ready(function () {
      $('form').on('blur', 'input[type=number]', function (e) {
        $(this).off('mousewheel.disableScroll')
      })

      $("input[type=number]").keypress(function (evt) {
        if ((evt.which < 48 || evt.which > 57) || ($(this).val().length == 10)) {
          if (evt.which == 8 || evt.which == 0 || evt.which == 46) { }
          else { evt.preventDefault(); }
        }
      })
    })
    this.modalService.open(dispatch, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }

  changeaddss(name, mob, add1, add2, land, pin, city, state, id) {

    this.storage.store("goingthroughgeneratepickup", 1);
    let storeaddress = {
      name, mob, add1, add2, land, pin, city, state, id
    }
    this.storage.store("storedadd", storeaddress);
    this.storage.store("fullname", name)
    this.storage.store("mobile", mob),
      this.storage.store("Add1", add1)
    this.storage.store("Add2", add2)
    this.storage.store("land", land)
    this.storage.store('pincode1', pin)
    this.storage.store('city1', city)
    this.storage.store('state1', state)
    this.modalService.dismissAll();
    this.router.navigate(['/homepage/changeaddrss']);

  }
  submitaddress(pickup) {
    // added by Kunal bansal on 30 nov 2021 after phase 5 cr addded of set shipping address when dispatch order 
    if (this.shippingaddressradio == true) {
      this.modalService.dismissAll();
      this.modalService.open(pickup, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.dispatchtype = 1;

        this.closeResult = `Dismissed `;
      });
      this.shiprocketmode();
    }
    else {
      Swal.fire("Please select shipping address before proceeding")
    }

  }

  // <!-- phase 3 new cr selecting or add address module added by Kunal bansal on 15 march 2021 start-->
  addaddress() {
    // this.modalService.dismissAll();
    this.storage.store("goingthroughgeneratepickup", 1);
     // phase 6 added by Kunal bansal on 13 may 2022 start
     this.storage.store("address_type",2)
     // ...end...
    this.modalService.dismissAll();
    this.router.navigate(['/homepage/addAddress'])
  }
  // end

  markdefault(e, id) {
    // added by Kunal bansal on 30 nov 2021 after phase 5 cr set shipping address when dispatch order 
    this.shippingaddressradio = true;
    // Address/?flag=2'

    this.authservice.apipost('UpdateAddress/', {
      adid: id,
      order_id: this.orderid,
      set_shipping: true,
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        //this.router.navigate(['/homepage/profile']);
        // Swal.fire(response.message);
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  open(pickup, id, price, selectaddress, fullarray) {
    this.ordernumber=fullarray.order_number;


    this.shippingaddressradio = false;
    this.orderid = id

    this.dispatchtype = 4;
    // this.shiprocketmode();
    if (fullarray.payment_method == 'Partial' && fullarray.cod_amount > 0) {
      this.collectammount = fullarray.cod_amount;

    }
    else {
      this.collectammount = price
    }

    $(document).ready(function () {
      $('form').on('blur', 'input[type=number]', function (e) {
        $(this).off('mousewheel.disableScroll')
      })

      $("input[type=number]").keypress(function (evt) {
        if ((evt.which < 48 || evt.which > 57) || ($(this).val().length == 10)) {
          if (evt.which == 8 || evt.which == 0 || evt.which == 46) { }
          else { evt.preventDefault(); }
        }
      })
    })
    // this.modalService.open(pickup, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.dispatchtype = 1;

    //   this.closeResult = `Dismissed `;
    // });
    this.authservice.apiget('Profile/?flag=1').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.address = response.response.Address[1].address_list;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

    this.modalService.open(selectaddress, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      // this.modalService.open(pickup, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      //       this.closeResult = `Closed with: ${result}`;
      //     }, (reason) => {
      //       this.dispatchtype = 1;

      //       this.closeResult = `Dismissed `;
      //     });
      this.closeResult = `Dismissed `;
    });


  }

  submitpickup(ewaypopup) {
    // console.log(this.ewayuploadedfile)
    if (this.dispatchtype == 1) {
      // phase 6 code comment by kunal bansal on 25 may 2022 as flow on pause by client due to transporter id pending 
      // this.modalService.dismissAll();
      // this.modalService.open(ewaypopup,{centered:true})

      this.bluedart()
    }
    else if (this.dispatchtype == 2) {
      this.selfpickup()
    }
    else if (this.dispatchtype == 3) {
      this.othercourier()
    }
    else if (this.dispatchtype == 4) {
      this.shipRocket();
    }
  }

  selfpickup() {
    if ($.trim(this.selftrackdate).length == 0 || $.trim(this.selfinstructions).length == 0 || $.trim(this.selfexpectdate).length == 0) {
      Swal.fire("Please fill in the mandatory fields");
    }
    else {

      this.authservice.apipost('orderDispatch/', {
        order_id: this.orderid,//tbd
        date: this.selftrackdate,
        remark: this.selfinstructions,
        expected_date: this.selfexpectdate,
        r_type: 2
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.activefilter = 0;
          //Swal.fire(response.message);
          this.modalService.dismissAll();
          location.reload();
        }
        else { Swal.fire(response.message) }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )

    }

  }

  othercourier() {
    if ($.trim(this.othertrackdate).length == 0 || $.trim(this.otherinstructions).length == 0 || $.trim(this.otherexpecttrackdate).length == 0) {
      Swal.fire("Please fill in the mandatory fields");
    }
    else {

      this.authservice.apipost('orderDispatch/', {
        order_id: this.orderid,//tbd
        date: this.othertrackdate,
        remark: this.otherinstructions,
        traking_id: this.othertracknumber,
        expected_date: this.otherexpecttrackdate,
        r_type: 3
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.activefilter = 0;
          //Swal.fire(response.message);
          this.modalService.dismissAll();
          location.reload();
        }
        else { Swal.fire(response.message) }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )

    }
  }

  bluedart() {
    if ($.trim(this.weight).length == 0 || $.trim(this.breadth).length == 0 || $.trim(this.count).length == 0 || $.trim(this.height).length == 0 || $.trim(this.length).length == 0 || $.trim(this.pickupdate).length == 0 || $.trim(this.pickuptime).length == 0 || $.trim(this.collectammount).length == 0) {
      Swal.fire("Please fill in the mandatory fields");
    }
    else {
      this.authservice.apipost('returnTrakingId/', {
        order_id: this.orderid,
        ActualWeight: this.weight,
        Breadth: this.breadth,
        Count: this.count,
        Height: this.height,
        Length: this.length,
        PickupDate: this.pickupdate,
        PickupTime: this.pickuptime.split(':')[0] + this.pickuptime.split(':')[1],
        PieceCount: this.count,
        //PieceCount:this.piece,
        ProductCode: "A",
        CollectableAmount: this.collectammount
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
          let error = ""
          for (let i = 0; i < response.message.WayBillGenerationStatus.length; i++) {
            error += i + 1 + '. ' + response.message.WayBillGenerationStatus[i].StatusInformation + '<br>'
          }
          Swal.fire({ html: error, title: 'Error' });
          //Swal.fire(error)
        }
        else if (response.status == 200) {
          this.activefilter = 0;
          this.modalService.dismissAll();
          if (response.response.url != "" || response.response.url != null || response.response.url != undefined) {
            window.open(response.response.url, '_blank', 'status=0,scrollbars=1,resizable=1,location=1');
          }
          location.reload();
        }
        else if (response.status == 401) {

          Swal.fire(JSON.stringify(response.message));

        }
        else { Swal.fire(JSON.stringify(response.message)); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  shipRocket() {

    if ($.trim(this.weight).length == 0 || $.trim(this.breadth).length == 0 || $.trim(this.count).length == 0 || $.trim(this.height).length == 0 || $.trim(this.length).length == 0 || $.trim(this.pickupdate).length == 0 || $.trim(this.pickuptime).length == 0 || $.trim(this.collectammount).length == 0) {
      Swal.fire("Please fill in the mandatory fields");
    }
    else {
      this.authservice.apipost('returnTrakingId/', {
        order_id: this.orderid,
        ActualWeight: this.weight,
        Breadth: this.breadth,
        Count: this.count,
        Height: this.height,
        Length: this.length,
        PickupDate: this.pickupdate,
        PickupTime: this.pickuptime.split(':')[0] + this.pickuptime.split(':')[1],
        PieceCount: this.count,
        //PieceCount:this.piece,
        ProductCode: "A",
        CollectableAmount: this.collectammount,
        service_type: "shiprocket"
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          let error = ""
          for (let i = 0; i < response.message.WayBillGenerationStatus.length; i++) {
            error += i + 1 + '. ' + response.message.WayBillGenerationStatus[i].StatusInformation + '<br>'
          }
          Swal.fire({ html: error, title: 'Error' });
          //Swal.fire(error)
        }
        else if (response.status == 200) {
          this.modalService.dismissAll();
          if (response.response.url != "" || response.response.url != null || response.response.url != undefined) {
            window.open(response.response.url, '_blank', 'status=0,scrollbars=1,resizable=1,location=1');
          }
          location.reload();
        }

        else if (response.status == 401) {

          Swal.fire(JSON.stringify(response.message));
          this.status = "";
        }
        else if (response.status == 404) {

          Swal.fire(response.message);


        }
        else { Swal.fire(JSON.stringify(response.message)); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }


  submitdispatch() {
    if (this.trackdate == "" || this.tracknumber == "" || $.trim(this.trackdate).length == 0 || $.trim(this.tracknumber).length == 0) {
      Swal.fire("Please fill in the mandatory fields")
    }
    else {
      this.authservice.apipost('orderDispatch/', {
        order_id: this.orderid,
        date: this.trackdate,
        traking_id: this.tracknumber,
        r_type: 1
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.activefilter = 0;
          //Swal.fire(response.message);
          this.modalService.dismissAll();
          location.reload();
        }
        else { Swal.fire(response.message) }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  closeall() {
    this.modalService.dismissAll();

  }

  // <!--phase 6 way bill ui created by Kunal on 29 april 2022 start  -->
  receivename($event: any) {  
    console.log($event)
    } 
 
receiveinvoicefile($event:any){
  console.log($event)
}
// ....end...

}
