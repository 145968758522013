import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
  
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;

  constructor(private router: Router, private formBuilder: FormBuilder, private authservice: AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      phonenumber: ['',  Validators.maxLength(50)]
    });
  }

  forgot({ value, valid }) {
      if (JSON.stringify(value.phonenumber).length == 0 || $.trim(value.phonenumber).length == 0) {
        Swal.fire("Please enter a valid Mobile number or email id")
      }
      else {
        this.authservice.apipost('forgotPwd/', {
          email: value.phonenumber //Abhin332@gmail.com
        }).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            Swal.fire(response.message)
            this.router.navigate(['/homepage/login']);
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
      }
  }

}