import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.css','../../assets/Newwebfiles/style.css']
})
export class TechnologyComponent implements OnInit {

  contents: any ={};
  imagesUrl: any ={};

  constructor(private server: DataService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.getTechnologyContents();
  }

  private getTechnologyContents() {
    this.server.getTechnologyContents().then((response: any) => {
      this.contents = response.data[0];
    });
  }

}
