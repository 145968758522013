import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import * as $ from 'jquery';
import { environment } from '../../environments/environment';

var x;

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})

export class OrderDetailsComponent implements OnInit {
  id: any;
  from: any;
  orderdetails: any;
  selectedvid: string = '';
  selectedsid: string = '';
  closeResult: string;
  // phase 4 new cr partial payment module update payment added by Kunal bansal on 28 july 2021 start -->
  paymentmodes = ["Cash", "Net Banking", "Other"]
  Activepayment: number = 0;

  Topay: string;
  selectedpayment: string = "Cash";
  Remarks: string;
  Accountnumber: string;
  Paymentdetails: string;
  // end
  // fileUrl;
  
  paymentdate: any;
  // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
  selectedfromdate:string;
  frommaxdate:string;
  paymenttime: any;
 
  // end
  country:string;

  constructor(private storage: SessionStorageService, private sanitizer: DomSanitizer, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal) { }

  ngOnInit() {



    this.id = this.storage.retrieve('orderid');
    if (this.id == null || this.id == undefined) {
      this.router.navigate(['/homepage/orders']);
    }
    else {
      this.from = this.storage.retrieve('from_buyorsell');
      this.authservice.apipost('orderDetails/', { order_id: this.id, type: 0 }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {
          // alert(JSON.stringify(response.response))
          this.orderdetails = response.response;
          this.country=response.response.country;
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
  }

  // phase 4 new cr partial payment module update payment added by Kunal bansal on 28 july 2021 start -->
  paymentswitch(f) {
    this.Activepayment = f;
    if (f == 0) {
      this.selectedpayment = "Cash";
    }
    else if (f == 1) {
      this.selectedpayment = "Net Banking";
    }
    else if (f == 2) {
      this.selectedpayment = "OTHER";
    }
  }
  // end

  navordertraking(ordno, paymentmode) {
    this.storage.store("ordertrekkingno", ordno);
    this.storage.store("ordertrekkingpaymentmode", paymentmode);
    this.router.navigate(['/homepage/ordertracking']);
  }

  track(url) {
    if (url != "" && url != null && url != undefined) {
      window.open(url, "_blank");
    }
  }

  // downloadinvoice(id) {
  //   this.authservice.apipost('downloadInvoice/', {order_id:id }).subscribe((res: any) => {
  // const data = 'some text';
  // const blob = new Blob([res._body], { type: 'application/octet-stream' });

  // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  // console.log(res._body)
  // this.pdf=res._body;
  // alert(this.pdf);
  //   var newBlob = new Blob([res._body], { type: "application/pdf" });

  //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveOrOpenBlob(newBlob);
  //     return;
  // }
  //  // For other browsers: 
  //         // Create a link pointing to the ObjectURL containing the blob.
  //         const data = window.URL.createObjectURL(newBlob);

  //         var link = document.createElement('a');
  //         link.href = data;
  //         link.download = "receipt.pdf";
  //         // this is necessary as link.click() does not work on the latest firefox
  //         link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

  //         setTimeout(function () {
  //             // For Firefox it is necessary to delay revoking the ObjectURL
  //             window.URL.revokeObjectURL(data);
  //         }, 100);

  // }, error => {
  //   console.log(error);
  // })
  // window.open(res._body,'_blank','status=0,scrollbars=1,resizable=1,location=1')

  // alert(res._body)
  // let response = res.json();
  //   if (response.status == 400 || response.status == 500 || response.status == 100) {
  //     Swal.fire(response.message);
  //   }
  //   else if (response.status == 200) {
  //     window.open(response,'_blank','status=0,scrollbars=1,resizable=1,location=1')
  //     console.log(response)
  //   }
  //   else { Swal.fire("Server Down"); }
  // }
  // , err => {   
  //   this.spinner.hide();
  //   Swal.fire("Connect to the Internet");
  // }
  //   )
  // }

  // phase 4 new cr partial payment module update payment added by Kunal bansal on 27 july 2021 start -->
  updatepay(update) {
    this.Activepayment = 0;
    this.selectedpayment = "Cash"
    this.modalService.open(update, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
    // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if (month.toString().length == 1 && day.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + day;
      this.frommaxdate = fulldate;
    
    }
    else if (month.toString().length == 2 && day.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + day;
      this.frommaxdate = fulldate;
   
    }
    else if (month.toString().length == 1 && day.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + day;
      this.frommaxdate = fulldate;
    
    }
    else {
      var fulldate = year + "-" + month + "-" + day;
      this.frommaxdate = fulldate;
      
    }
    // end

  }





  // add date time module to convert 24 hour time to 12 hour format in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
  // formatAMPM() {
  //   var hours = this.paymenttime[0] + this.paymenttime[1];

  //   var minutes = this.paymenttime[3] + this.paymenttime[4];
  //   var ampm = hours >= 12 ? 'pm' : 'am';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? '0' + minutes : minutes;
  //   if (hours < 10) {
  //     hours = "0" + hours
  //   }
  //   else {
  //     hours = hours;
  //   }
  //   var strTime = hours + ':' + minutes + ' ' + ampm;
  //   return strTime;
  // }



  // ...............................end.......................

  // phase 4 new cr partial payment module gettimestamp function added by Kunal bansal on 28 july 2021 start
  getTimeStamp() {
    var now = new Date();
    return (now.getFullYear() + '-' +
      ((now.getMonth() + 1 < 10)
        ? ("0" + (now.getMonth() + 1))
        : (now.getMonth() + 1)) + '-' +
      ((now.getDate() < 10)
        ? ("0" + now.getDate())
        : (now.getDate())) + " " +
      now.getHours() + ':' +
      ((now.getMinutes() < 10)
        ? ("0" + now.getMinutes())
        : (now.getMinutes())) + ':' +
      ((now.getSeconds() < 10)
        ? ("0" + now.getSeconds())
        : (now.getSeconds())));
  }
  // end


  // phase 4 new cr partial payment module gettimestamp function added by Kunal bansal on 28 july 2021 start
  validate(e) {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 190 || e.keyCode == "08") {
      return true
    }
    else {
      return false
    }
  }

  // phase 4 new cr partial payment module gettimestamp function added by Kunal bansal on 28 july 2021 start
  submitpayment() {
    
   
  
    if (this.Topay == null || this.Topay.trim() == "") {
      this.Topay = "";
      Swal.fire("Please Enter Amount")
    }
    else if (Number(this.Topay) * 1 == 0) {

      Swal.fire("Amount cannot be Zero. Enter Valid Amount")
    }
    else if (this.Activepayment == 1 && this.Accountnumber == null || this.Activepayment == 1 && this.Accountnumber.trim() == "") {
      this.Accountnumber = "";
      Swal.fire("Please enter account number")
    }
    else if (this.Activepayment == 2 && this.Paymentdetails == null || this.Activepayment == 2 && this.Paymentdetails.trim() == "") {
      this.Paymentdetails = "";
      Swal.fire("Please enter payment details")
    }
       // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
    else if(this.selectedfromdate==undefined || this.selectedfromdate==""){
      Swal.fire("Please select date")
    }
    else if(this.paymenttime==undefined){
      Swal.fire("Please select time")
    }
    // end

    else {
       // add date time module in part payment phase 4 cr added by KUnal bansal on 10 augu 2021 start
    var date=this.selectedfromdate.split('-').join('-');
    var customtimestamp=date +" " + this.paymenttime ;
    // end
      let paymentdetail = "";
      if (this.Activepayment == 0) {
        paymentdetail = ""
      }
      else if (this.Activepayment == 1) {
        paymentdetail = this.Accountnumber;
      }
      else if (this.Activepayment == 2) {
        paymentdetail = this.Paymentdetails;
      }

      this.authservice.apipost('Update_Partial_Payment/', {
        "order_id": this.orderdetails.order_id,
        "to_pay": Number(this.Topay),
        "payment_mode": this.selectedpayment,
        "pay_date": customtimestamp,
        "remarks": this.Remarks,
        "payment_details": paymentdetail,


      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.modalService.dismissAll();
          Swal.fire(response.message);
          this.Topay = "";
          this.selectedpayment = "Cash";
          this.Remarks = "";
          this.Paymentdetails = "";
          this.Accountnumber = "";

          this.ngOnInit();


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("Connect to the Internet")
          Swal.fire("Network Error")
        }
      )
    }

  }
  // end

  closeall() {
    this.modalService.dismissAll();
  }
  // end

  // module added by Kunal bansal on 06/11/2020
  downloadinvoice(id) {

    window.open(`${environment.baseurl}downloadInvoice?order_id=` + id);
    //  staging
    // window.open("https://admin.eolstocks.com/app_api/downloadInvoice?order_id=" + id);
    // live
  }

  // module added by Kunal bansal on 06/11/2020
  downloadcommissioninvoice(id) {
    window.open(`${environment.baseurl}sellerCommissionInvoice?order_id=` + id);
    //  staging
    // window.open("https://admin.eolstocks.com/app_api/sellerCommissionInvoice?order_id=" + id);
    // live
  }

  // downloadcommissioninvoice(id) {
  // this.authservice.apipost('sellerCommissionInvoice/', {order_id:id }).subscribe((res: any) => {
  //   let response = res.json();
  //     if (response.status == 400 || response.status == 500 || response.status == 100) {
  //       Swal.fire(response.message);
  //     }
  //     else if (response.status == 200) {
  //       window.open(response.response,'_blank','status=0,scrollbars=1,resizable=1,location=1')
  //     }
  //     else { Swal.fire("Server Down"); }
  //   }
  //   , err => {   
  //     this.spinner.hide();
  //     Swal.fire("Connect to the Internet");
  //   }
  // )
  // }

  ratepro(ratepop, vid, sid) {
    this.selectedvid = vid;
    this.selectedsid = sid;
    this.modalService.open(ratepop, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
    $(document).ready(function () {
      $('input').click(function (e) {
        var tmp;
        if (e.currentTarget.id == 'radio-1') {
          tmp = '1'
        } else if (e.currentTarget.id == 'radio-2') {
          tmp = '2'
        } else if (e.currentTarget.id == 'radio-3') {
          tmp = '3'
        } else if (e.currentTarget.id == 'radio-4') {
          tmp = '4'
        } else { tmp = '5' }
        x = tmp
      });
    });
  }

  submitrate() {
    this.authservice.apipost('productReviews/', { rating: x, seller_id: this.selectedsid, variant_id: this.selectedvid }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {
        Swal.fire(response.response)
        this.modalService.dismissAll();
        //this.orderdetails = response.response;
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }

}
