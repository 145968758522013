import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { Http, Response, Headers } from '@angular/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxImgZoomService } from 'ngx-img-zoom';
import Swal from 'sweetalert2';
import { NgbModal, NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { Globals } from '../globals';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { RNS3 } from 'react-native-s3-upload-new';
import * as converter from 'xml-js';
import { NgxXml2jsonService } from 'ngx-xml2json';
// autorotation image library imprt on 09feb2021 start
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ChangePasswordComponent } from '../change-password/change-password.component';
// end
import { Statecitylisting } from '../Statecitylisting';
import { Countrylisting } from '../Services/Countrylisting';
import { map } from 'rxjs/operators';



let id;

// Image directly upload to s3 bucket scenario added by Kunal bansal on 14 april 2021 start
let chpanaayaarplease = [];
// end
let ima;
let formData = new FormData();
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})

export class ProductDetailsComponent implements OnInit {
  @ViewChild('viewchild') viewchild: ElementRef;
  // share link module added on 09feb2021 start
  id: number;
  // chpanaayaarplease = [];
  // end
  testing = [];
  allproperties: any;
  popupdetails: any;
  productdetails: any;
  images: any;
  Quantitysell: number = 0;
  sellingprice: number = 0;
  moq: number = 1;
  producttype: number = 1; // 0 for buy , 1 for sell
  delii: string = "";
  // selectedstorage: string = "";
  // selectedmemory: string = "";
  // selectedcolor: string = "";
  position1: string = "";
  position2: string = "";
  position3: string = "";
  position4: string = "";

  closeResult: string;
  collapsed: boolean = true;
  sellers: any[] = [];
  sellerproductlisting: any[];
  response: any[];
  selectedimage: any;
  islogged: number = 0;
  math = Math;
  variantid: string = '';
  availabletobuy: number = 1;
  errormessage: string = "";
  currentseller: any;
  sellingstatus: string;
  specialInstructions: string = "";
  des: string = "";
  assigned: number = 0;
  grouparray = [];
  groupname = [];
  // param added for sell module groupby and user start

  checkradio: number = 1;

  // end
  tab: number;
  catid: number;
  selectoption;
  option: any[];
  genericproperties: any[];
  categoryname: string;
  Groupby: any[];
  groupbyapi: any[];
  selectedItems: any[];
  dropdownSettings = {};

  categoryid: number;
  sellid: number;
  coupon: '';
  logstatus: number;

  genericpropertylength: number;
  position1key: any[];
  position2key: any[];
  position3key: any[];
  position1value: any[];
  position2value: any[];
  position3value: any[];

  checkcolor1: number;
  checkcolor2: number;
  checkcolor3: number;
  checkcolor4: number;
  descactive: number = 0;

  selectedorderno: any[];
  dropdownSettings2 = {};
  language: string;

  // autorotation image library imprt on 09feb2021 start
  checkactive: number = 0;
  childindex: number = 0;

  // Image directly upload to s3 bucket scenario added by Kunal bansal on 14 april 2021 start
  selectedFiles: FileList;

  imageupload = [];
  checkimages = []
  s3bucketimagelocation = [];
  updateForm: FormGroup;
  imagesinapi = [];
  selecteduploadimage = "";
  zoomselectedimage: any[];
  zoomimageindex: number = 0;

  inventoryimagelength: number;
  Actualimages = [];
  Actualselectedimage: any[];
  Actualimageindex: number = 0;
  Actualimageseyeactivate: number = 0;
  sorebuckettrans = [];
  Loading: number = 0;
  // end
  cityfetch: any = Statecitylisting;
  temp: number;
  Selectedcity = [];
  secret: number;

  // <!-- Phase 4 new cr Adding payment mode choice in sell now by Kunal bansal on 13 july 2021 start -->
  // Paymentmodes
  online: number = 1;
  cod: number = 0;
  partial: number = 0;
  // end

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "autoplay": true,
    "pauseOnHover": false,
    // "autoplaySpeed": 2000
    "autoplaySpeed": 1500
  };

  // end
  // <!-- Share via social sited phase 3 new cr module added by Kunal bansal on 08 march 2021 start -->
  sharelink: string;
  whatsapplink: SafeUrl;
  gmaillink: string;
  // end
  Description: String;

  testArr = [];
  // Geolocation module added by Kunal bansal on 23 june 2021 start
  loggedstatus: number;
  locationstatus: number;
  Districtname: string;
  googleapiresult: any[];
  State: string;
  // end
  // Sharing inventory upcoming new cr Demo testing added by Kunal bansal on 06 july 2021 start
  sellerid: number;
  // end
  // phase 4 new cr partial payment module params added by Kunal bansal on 26 july 2021 start
  maximum_order_quantity: number;
  payment_mode = ["RazorPay", "Partial", "COD"];
  // phase 4 new cr partial payment module seller switch payment change added by Kunal bansal on 29 july 2021 start
  sellerpaymentmode = [];
  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  countrymodulelist = [];
  temp3: number;
  country = [];
  selectedcountry: string;
  // ...end...
  working: any;
  countrycurrency: string;
  // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
  countrylocation: string;

  // ...end...

  // added by Kunal bansal on 23 sep 2021 
  selectedcurrency: string = "India";
  // phase 6 add address alert scenario by kunal bansal on 07 april 2022 start
  addressadded: number = 1;

  // phase 6 added by kunal bansal on 21 april 2022 start
  minimumquantity: number;
  brandname: string = "";

  // phase 6 popup banner added by kunal bansal on 12 may 2022
  popupimage: string = "";
  popupimageweb: string = "";
  popupimageapp: string = "";
  actiontype: number;
  bannerimage: string = "";
  bannersubimage: string = "";
  bannerid: number;
  disablepopup: number = 1;

  sellerstatus: string = 'nosellerfound'



  constructor(private http: Http, private ngxImgZoom: NgxImgZoomService, private ngxXml2jsonService: NgxXml2jsonService, private route: ActivatedRoute, private globals: Globals, private formBuilder: FormBuilder, private storage: SessionStorageService, public sanitizer: DomSanitizer, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal, private ref: ChangeDetectorRef, private changeDetector: ChangeDetectorRef, private statefetch: Statecitylisting, private countrymodule: Countrylisting) {
    this.cityfetch = statefetch;
    // share link module added on 09feb2021 start
    this.route.queryParams.subscribe(params => {
      console.log(params['pid'], params['catid'], params['ptype'])
      id = params['pid'];
      this.catid = params['catid'];
      this.producttype = params['ptype'];
      // Sharing inventory upcoming new cr Demo testing added by Kunal bansal on 06 july 2021 start
      if (document.URL.includes("psellerid")) {
        this.sellerid = params['psellerid']
      }
      else {

      }

    });
    // end
    // let check=window.location;



    this.ngxImgZoom.setZoomBreakPoints([{ w: 100, h: 100 }, { w: 150, h: 150 }, { w: 200, h: 200 }, { w: 250, h: 250 }, { w: 300, h: 300 }]);
    console.log('Called Constructor');
    // end
  }

  ngOnInit() {
    // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
    this.countrylocation = sessionStorage.getItem("phase5countrylocation");
    // ...end...
    // Geolocation module added by Kunal bansal on 23 june 2021 start
    // this.locationstatus=this.storage.retrieve("locationstatus");
    // this.loggedstatus=this.storage.retrieve("islogged");
    // this.Districtname=this.storage.retrieve("districtname");
    this.getcurrentlocation();
    // end
    //  Added by Kunal bansal on 06 july 2021 start
    let currentLocation: string
    currentLocation = window.location.toString();
    sessionStorage.setItem("pageurl", currentLocation);
    // end


    // <!-- phase 5 module country module in sell now added by Kunal bansal on 25 aug 2021 start -->
    this.countrymodule.getcountrylisting().map(res => {

      // added by Kunal bansal on 23 sep 2021 
      this.countrymodulelist.push(res.listing[0]);
      console.log(this.countrymodulelist);
    })
    this.country = ['India', 'UAE'];
    this.selectedcountry = "India";
    // ...end...


  }

  //  added by KUnal bansal on 06 july 2021 start

  producthomepage() {
    this.checklisting();
    this.updateForm = this.formBuilder.group({
      image: ['']
    });
    this.testing.push("https://images-na.ssl-images-amazon.com/images/I/81-oX6URIoL._AC_SL1500_.jpg", "https://images-na.ssl-images-amazon.com/images/I/81-oX6URIoL._AC_SL1500_.jpg", "https://images-na.ssl-images-amazon.com/images/I/81-oX6URIoL._AC_SL1500_.jpg");
    // if(document.URL.includes("?vid")){
    //   alert(document.URL.charAt(URL.length-2))
    // }
    // else{
    //   alert('not present')
    // }
    // this.Actualimages.push({ 'img': "https://images-na.ssl-images-amazon.com/images/I/717ywJDnAHL._AC_SL1500_.jpg" },
    //   { 'img': "https://images-na.ssl-images-amazon.com/images/I/81-oX6URIoL._AC_SL1500_.jpg" },
    //   { 'img': "https://images-na.ssl-images-amazon.com/images/I/81Y0H0xNa6L._AC_SL1500_.jpg" },
    //   { "img": "https://images-na.ssl-images-amazon.com/images/I/71ph3gViXPL._AC_SL1500_.jpg" },
    //   { "img": "https://images-na.ssl-images-amazon.com/images/I/71kuSzYG5pL._AC_SL1500_.jpg" });

    $(".hackbutton").click(function () {
      $(".imm").trigger("click");
    });
    this.language = this.storage.retrieve('language');

    // <!-- Share via social sited phase 3 new cr module added by Kunal bansal on 08 march 2021 start -->
    let fetchlink = document.URL;
    let encrypt = fetchlink.split('&').join('%26');
    this.sharelink = encrypt;
    // whatsapp latest updated api link added by Kunal bansal on 10 june 2021 start
    this.whatsapplink = this.sanitizer.bypassSecurityTrustResourceUrl("https://api.whatsapp.com/send?text=" + encrypt);
    // end
    let encrgmaillink = encrypt.split('=').join('%3D');
    this.gmaillink = encrgmaillink;


    // end

    this.logstatus = this.storage.retrieve('islogged');
    if (this.logstatus == 1) {
      this.authservice.apiget('groupList/').subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          console.log(response.message)
        }
        else if (response.status == 200) {

          this.groupbyapi = response.response;
          this.dropdownSettings2 = {
            singleSelection: false,
            enableFilterSelectAll: false,
            enableSearchFilter: false,
            text: "Select",
            labelKey: "name",
            classes: "font_drop",
            maxHeight: 200

          };

        }

        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }



    let x = this.storage.retrieve('islogged');
    let gAng = this;

    if (x == 0 || x == undefined || x == null) {
      this.islogged = 0;
    }
    else {
      this.islogged = 1;
    }
    // share link module added on 09feb2021 start
    // this.producttype = this.storage.retrieve('ptype');
    // this.catid = this.storage.retrieve('catid')
    // id = this.storage.retrieve('pdid');
    // end
    this.tab = this.storage.retrieve('tabdetail');
    this.categoryname = this.storage.retrieve('categoname')

    if (id == null || id == undefined) {
      this.router.navigate(['/homepage/productlisting'])
    }
    else {
      let body = {}

      if (this.producttype == 0) { //buy
        body = {
          variant_id: id,
          selected_list: 0,
          // Geolocation module added by Kunal bansal on 23 june 2021 start
          "location": this.locationstatus == 1 ? this.Districtname : "",
          // end
          // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          "country": this.countrylocation,
          platform: 0

        }
      }
      else if (this.producttype == 1) { //sell
        body = {
          variant_id: id,
          selected_list: 1,
          // Geolocation module added by Kunal bansal on 23 june 2021 start
          "location": "",
          // end
          // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          "country": this.countrylocation,
          platform: 0
        }
      }

      this.authservice.apipost('ProductDetails/', body).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // phase 6 popup banner added by kunal bansal on 12 may 2022 for responsiveness start
          if (response.response.pop_up_banner.length == 2) {
            response.response.pop_up_banner.forEach(element => {


              if (element.banner_platform) {
                if (screen.width < 768) {
                  if (element.banner_platform == 'Mobile') {
                    this.popupimageapp = element.image;

                    this.actiontype = element.action_type;
                    this.bannerid = element.id;
                    this.bannerimage = element.image;
                    this.bannersubimage = element.subimage;
                    this.disablepopup = 0;

                  }
                  else {

                    // this.disablepopup = 1;
                  }

                }
                if (screen.width > 768) {
                  if (element.banner_platform == 'Web') {
                    this.popupimageweb = element.image;

                    this.actiontype = element.action_type;
                    this.bannerid = element.id;
                    this.bannerimage = element.image;
                    this.bannersubimage = element.subimage;
                    this.disablepopup = 0;
                  }


                  else {
                    // this.disablepopup = 1;
                  }
                }



              }


            });
            let findsize = screen.width;
            if (findsize < 768) {
              this.popupimage = this.popupimageapp;
            }
            else {
              this.popupimage = this.popupimageweb;
            }
            console.log(this.popupimageweb)
            // ......end.............
          }

          else if (response.response.pop_up_banner.length == 1) {
            let element = response.response.pop_up_banner[0];
            if (element.banner_platform == 'Mobile') {
              this.popupimageapp = element.image;
              if (screen.width < 768) {
                this.actiontype = element.action_type;
                this.bannerid = element.id;
                this.bannerimage = element.image;
                this.bannersubimage = element.subimage;
                this.popupimage = this.popupimageapp;
                this.disablepopup = 0;
              }

              else if (screen.width > 768) {
                this.disablepopup = 1;
              }

              else {
                this.disablepopup = 1;
              }

            }
            else if (element.banner_platform == 'Web') {
              this.popupimageweb = element.image;
              if (screen.width > 768) {
                this.actiontype = element.action_type;
                this.bannerid = element.id;
                this.bannerimage = element.image;
                this.bannersubimage = element.subimage;
                this.popupimage = this.popupimageweb;
                this.disablepopup = 0;



              }
              else if (screen.width < 768) {
                this.disablepopup = 1;

              }

              else {
                this.disablepopup = 1;
              }
            }
            else {

            }

          }

          else {
            this.disablepopup = 1;

          }
          // ......end.............


          this.categoryname = response.response.category_name;
          this.categoryid = response.response.category_id;
          this.productdetails = response.response;
          this.images = response.response.images;
          this.Description = response.response.description;

          this.storage.store("brandid", response.response.brand_id)
          this.storage.store("productid", response.response.product_id)
          // alert(this.images.length)
          // this.images.map((value, key) => {
          //   return (
          //     this.galleryImages.push({
          //       small: this.images[key].image,
          //       medium: this.images[key].image,
          //       big: this.images[key].image
          //     })
          //   )
          // })


          ima = response.response.images;
          this.variantid = response.response.variant_id;
          // <!-- phase 5 point Design changes in Product Details screen by Kunal bansal on 26 aug 2021 start -->
          this.authservice.apipost('description/', { variant_id: this.variantid }).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 400 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message)
            }
            else if (response.status == 200) {
              this.popupdetails = response.response.generic_properties;
              // phase 6 added by kunal bansal on 21 april 2022 start
              this.brandname = response.response.brand_name;
              // ...end...
              $("#magic").animate({ scrollTop: 0 }, "fast");
            }
            else { Swal.fire("Server Down") }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet")
            }
          )
          // ....end....
          this.selectedimage = response.response.images[0].image
          // this.position3 = response.response.selected_properties.Tablet_Quantity;
          this.genericproperties = response.response.generic_properties;
          this.genericpropertylength = response.response.generic_properties.length;
          // spinner not rotating working module start
          this.position1key = response.response.generic_properties[0].db_key;
          this.position2key = response.response.generic_properties[1].db_key;

          this.position3key = response.response.generic_properties[2].db_key;
          this.position1value = response.response.generic_properties[0].p_value;
          this.position2value = response.response.generic_properties[1].p_value;
          this.position3value = response.response.generic_properties[2].p_value;

          if (response.response.selected_generic_properties[0].p_value[0].code) {
            this.position1 = response.response.selected_generic_properties[0].p_value.name;

            this.checkcolor1 = 1;
          }
          else {
            this.position1 = response.response.selected_generic_properties[0].p_value;

            this.checkcolor1 = 0;
          }

          if (response.response.selected_generic_properties[1].p_value[0].code) {
            this.position2 = response.response.selected_generic_properties[1].p_value.name;
            this.checkcolor2 = 1;
          }
          else {
            this.checkcolor2 = 0;
            this.position2 = response.response.selected_generic_properties[1].p_value;
          }
          if (response.response.selected_generic_properties[2].p_value[0].code) {
            this.position3 = response.response.selected_generic_properties[2].p_value.name;
            this.checkcolor3 = 1;

          }
          else {
            this.checkcolor3 = 0;
            this.position3 = response.response.selected_generic_properties[2].p_value;
          }
          if (response.response.selected_generic_properties.length >= 4) {
            if (response.response.selected_generic_properties[3].p_value[0].code) {
              this.position4 = response.response.selected_generic_properties[3].p_value.name;
              this.checkcolor4 = 1;

            }
            else {
              this.checkcolor4 = 0;
              this.position4 = response.response.selected_generic_properties[3].p_value;
            }
          }





          // end

          // this.position1 = response.response.selected_properties.Dosage;
          // this.position2 = response.response.selected_properties.Color;

          this.allproperties = response.response.properties;
          if (response.response.sellers) {
            this.sellers = response.response.sellers;


            // alert(this.inventoryimagelength)
            if (this.sellers.length > 0) {
              this.inventoryimagelength = this.sellers[0].inventory_image.length;
              for (let k = 0; k < this.sellers[0].inventory_image.length; k++) {
                this.Actualimages.push({ 'img': this.sellers[0].inventory_image[k].url })
              }
              // phase 6 min q module added by kunal bansal on 21 april 2022 start
              this.Quantitysell = this.sellers[0].minq;
              this.minimumquantity = this.sellers[0].minq;
              this.sellid = this.sellers[0].product_seller_id;
              this.countrycurrency = this.sellers[0].country;
              // ...end...
            }
            else {
              // Swal.fire('there are no sellers for this product')
            }

            // this.Actualimages.push({ 'img': "https://images-na.ssl-images-amazon.com/images/I/717ywJDnAHL._AC_SL1500_.jpg" },
            // { 'img': "https://images-na.ssl-images-amazon.com/images/I/81-oX6URIoL._AC_SL1500_.jpg" },
            // { 'img': "https://images-na.ssl-images-amazon.com/images/I/81Y0H0xNa6L._AC_SL1500_.jpg" },
            // { "img": "https://images-na.ssl-images-amazon.com/images/I/71ph3gViXPL._AC_SL1500_.jpg" },
            // { "img": "https://images-na.ssl-images-amazon.com/images/I/71kuSzYG5pL._AC_SL1500_.jpg" });


          }

          if (this.producttype == 0) {
            // Sharing inventory new cr added by Kunal bansal on 06 july 2021 start
            if (document.URL.includes("psellerid")) {
              for (let k = 0; k < this.sellers.length; k++) {
                if (this.sellers[k].product_seller_id == this.sellerid) {
                  this.currentseller = this.sellers[k]
                  // phase 4 new cr partial payment module seller switch payment change added by Kunal bansal on 29 july 2021 start
                  this.sellerpaymentmode = [];
                  for (let v = 0; v < this.sellers[k].payment_mode.length; v++) {
                    this.sellerpaymentmode.push(this.sellers[k].payment_mode[v])
                  }
                  // end
                }
                else {
                  if (this.sellers.length > 0) {
                    this.currentseller = this.sellers[0];
                    // phase 4 new cr partial payment module seller switch payment change added by Kunal bansal on 29 july 2021 start
                    this.sellerpaymentmode = [];

                    for (let v = 0; v < this.sellers[0].payment_mode.length; v++) {
                      this.sellerpaymentmode.push(this.sellers[0].payment_mode[v])
                    }
                    // end
                  }
                }
              }
            }
            else {
              if (this.sellers.length > 0) {
                this.currentseller = response.response.sellers[0];
                // phase 4 new cr partial payment module seller switch payment change added by Kunal bansal on 29 july 2021 start
                this.sellerpaymentmode = [];

                for (let v = 0; v < this.sellers[0].payment_mode.length; v++) {
                  this.sellerpaymentmode.push(this.sellers[0].payment_mode[v])
                }
                // end
              }


            }
            //  end
          }

          // <!-- phase 5 point 8 product detail changes variant not avalaible automatic came to working variant add by Kunal bansal on 27 aug 2021 start -->
          let body = {
            "variant_id": this.variantid,
            "position1": this.position1,
            "position3": this.position3,
            "position2": this.position2,
            "selected_list": Number(this.producttype),
            "is_searched": this.producttype,
            "location": this.locationstatus == 1 ? this.Districtname : "",
            // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
            "country": this.countrylocation
          }

          this.working = body;
          // ...end...
          console.log(this.working)
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )


      this.authservice.apipost('productStatusList/', { "category_id": this.catid }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          console.log(response.message)
        }
        else if (response.status == 200) {

          this.option = response.response;
          // this.sellingstatus=response.response[0].product_status__name;
          // alert(this.sellingstatus)


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )



      // this.galleryOptions = [
      //   {
      //     width: '500px',
      //     height: '350px',
      //     thumbnailsColumns: 4,
      //     imageAnimation: NgxGalleryAnimation.Slide,
      //     imageSize: NgxGalleryImageSize.Contain,
      //     thumbnailSize: NgxGalleryImageSize.Contain,
      //     thumbnailsOrder: NgxGalleryOrder.Column,
      //     preview: false,
      //     layout:'thumbnails-top'
      //   },
      //   {
      //     breakpoint: 800,
      //     width: '100%',
      //     height: '600px',
      //     imagePercent: 80,
      //     thumbnailsPercent: 20,
      //     thumbnailsMargin: 20,
      //     thumbnailMargin: 20,
      //     //layout:NgxGalleryLayout.Top
      //   },
      //   {
      //     breakpoint: 400
      //   },
      //   // { "preview": false },
      //   //{ layout: "thumbnails-top" },
      //   // { "breakpoint": 500, "width": "300px", "height": "300px", "thumbnailsColumns": 3 },
      //   // { "breakpoint": 300, "width": "100%", "height": "200px", "thumbnailsColumns": 2 }
      // ];
      // this.galleryImages = [ ];
      // setInterval(() => {
      //   for(let i=0;i<this.images.length;i++){
      //     this.selectedimage=this.images[i].image;
      //   }
      //         },)

      this.dropdownSettings = {

        text: "Select",
        labelKey: "name",


        maxHeight: 200
      };

      // alert(JSON.stringify(this.images))
    }




    $(document).ready(function () {

      $(".image_main").on("touchstart", function (event) {
        //console.log("this 1", gAng, gAng.selectedimage);
        let path = ($(event.target).attr("src"));
        var xClick = event.originalEvent.touches[0].pageX;
        $(event.target).one("touchmove", function (event2) {
          var xMove = event2.originalEvent.touches[0].pageX;
          if (Math.floor(xClick - xMove) > 5) {
            gAng.next(path)
          }
          else if (Math.floor(xClick - xMove) < -5) {
            gAng.prev(path)
          }
        });
        $(".image_main").on("touchend", function (e) {
          $(e.target).off("touchmove");
        });
      });


    });




    // alert(this.images)

    //         setTimeout(function(){
    //     for(let i=0;i<this.images.length;i++){
    //   this.selectedimage=this.images[i].image

    // }
    //   this.selectedimage=this.images[1].image;

    // },4000)

  }



  // get user current location function and api added by KUnal bansal on 06 july 2021 start
  getcurrentlocation() {

    this.getPosition().then(pos => {

      this.geocoding(`AIzaSyBvQhRMAEoYfqwjNkt1fhM12JZ71yMsD6o&latlng=${pos.lat},${pos.lng}`).subscribe((res: any) => {
        let response = res.json();
        console.log(response);
        this.googleapiresult = response.results;
        for (let h = 0; h < this.googleapiresult[0].address_components.length; h++) {
          if (this.googleapiresult[0].address_components[h].types.includes("administrative_area_level_2")) {
            // lower case added by Kunal bansal on 05 july 2021 start
            this.Districtname = this.googleapiresult[0].address_components[h].short_name.toLowerCase();
            this.storage.store("districtname", this.Districtname)
            // end

          }
          if (this.googleapiresult[0].address_components[h].types.includes("administrative_area_level_1")) {
            this.State = this.googleapiresult[0].address_components[h].long_name;
          }
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          if (this.googleapiresult[0].address_components[h].types.includes("country")) {
            sessionStorage.setItem("phase5countrylocation", this.googleapiresult[0].address_components[h].long_name.toLowerCase());
            // added by kunal bansal on 12 may 2022 to fix sseller name and price not coming on product link share
            this.countrylocation = this.googleapiresult[0].address_components[h].long_name.toLowerCase();
          }
          // ...end...
        }
        console.log(this.State);
        console.log(this.Districtname);
        // Geolocation module added by Kunal bansal on 23 june 2021 start


        this.storage.store("districtname", this.Districtname);
        this.producthomepage();
        // end



        // this.hithomepageapi();


      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
      // this.Longitude=pos.lng;
      // this.latitude=pos.lat;
      // console.log('longitude : '+pos.lng+"  "+'latitude : '+pos.lat);
      // alert(this.Longitude);
      // alert(this.latitude);
    })
  }
  // end

  // get user current location function and api added by KUnal bansal on 06 july 2021 start
  getPosition(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.spinner.show();
      navigator.geolocation.getCurrentPosition(resp => {
        this.storage.store("locationstatus", 1);
        this.locationstatus = 1;
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });

      },
        err => {
          this.storage.store("locationstatus", 0);
          this.locationstatus = 0;
          this.storage.store("districtname", '');
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          sessionStorage.setItem("phase5countrylocation", "");
          // ...end...
          // Swal.fire('Please allow location to view Location specific inventory');
          this.producthomepage();
          // this.hithomepageapi();

          reject(err);

        });
    });


  }


  // phase 4 new cr partial payment module added by Kunal bansal on 26 july 2021 start
  enablepayment(event, checkmode) {


    if (event.target.checked == true && checkmode == 2) {
      this.payment_mode.push("Partial");
    }
    else if (event.target.checked == false && checkmode == 2) {
      let find = this.payment_mode.indexOf("Partial");
      this.payment_mode.splice(find, 1);
    }
    else if (event.target.checked == true && checkmode == 1) {
      this.payment_mode.push("COD");
    }
    else if (event.target.checked == false && checkmode == 1) {
      let find = this.payment_mode.indexOf("COD");
      this.payment_mode.splice(find, 1);
    }
    else {

    }
    console.log(this.maximum_order_quantity);

  }
  // end


  // get user current location function and api added by KUnal bansal on 06 july 2021 start
  geocoding(url) {

    // var headers = new Headers();
    // var token = this.storage.retrieve('token');
    // headers.append('Content-Type', 'application/json');
    // headers.append('Token', token);
    return this.http.get("https://maps.googleapis.com/maps/api/geocode/json?key=" + url).pipe(map(
      (data: Response) => {
        this.spinner.hide();
        return data;
      }
    ))
  }

  // end


  Resetcitystate() {
    this.Selectedcity = [];
    this.setglobalcitystatedefault();
  }

  submitcitystate() {
    this.modalService.dismissAll();
  }

  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  setglobalcitystatedefault() {
    for (let y = 0; y < this.countrymodulelist.length; y++) {
      for (let i = 0; i < this.countrymodulelist[y].listing.length; i++) {
        this.countrymodulelist[y].listing[i].checked = false;
        for (let j = 0; j < this.countrymodulelist[y].listing[i].dist_name.length; j++) {
          this.countrymodulelist[y].listing[i].dist_name[j].checked = false;
        }
      }
    }
  }

  // phase 4 googleapi module New fucntionality added if all child selecting parent automatic selected by Kunal bansal on 23 june 2021 start
  onebyone(event, y, i, c) {

    // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
    if (event.target.checked == true) {
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].dist_name[c].c)) {
      }
      else {
        this.Selectedcity.push(this.countrymodulelist[y].listing[i].dist_name[c].c);

      }
      // Added By Kunal bansal on 01 july 2021 start
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
      }
      else {
        this.Selectedcity.push(this.countrymodulelist[y].listing[i].State_name);

      }
      this.countrymodulelist[y].listing[i].checked = true;
      // end
      let g = 0;
      for (let n = 0; n < this.countrymodulelist[y].listing[i].dist_name.length; n++) {
        if (this.countrymodulelist[y].listing[i].dist_name[n].checked == true) {
          // console.log(n)
          // console.log('true available')
          g = g + 1;
          // console.log(k);
        }
        else {
          // console.log("not found");
        }
        // console.log(k)
      }

      if (this.countrymodulelist[y].listing[i].dist_name.length == g) {
        console.log("all selected");
        this.countrymodulelist[y].listing[i].checked = true;
        // Added By Kunal bansal on 01 july 2021 start
        if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
        }
        else {
          this.Selectedcity.push(this.countrymodulelist[y].listing[i].State_name);

        }
        // this.Selectedcity.push(this.cityfetch.listing[i].State_name);
      }
      // console.log(g)
      console.log(this.Selectedcity);
    }
    // end

    else {
      // commented by Kunal bansal on 01 july 2021 start
      // this.cityfetch.listing[i].checked = false;
      // end
      let p = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].dist_name[c].c);
      this.Selectedcity.splice(p, 1);
      // commented by Kunal bansal on 01 july 2021 start
      // if (this.Selectedcity.includes(this.cityfetch.listing[i].State_name)) {
      //   let po = this.Selectedcity.indexOf(this.cityfetch.listing[i].State_name);
      //   this.Selectedcity.splice(po, 1);

      // }
      // end
      console.log(this.Selectedcity);
    }

    let k = 0;
    for (let n = 0; n < this.countrymodulelist[y].listing[i].dist_name.length; n++) {
      if (this.countrymodulelist[y].listing[i].dist_name[n].checked == true) {
        k = k + 1;
        // console.log(k);
      }
      else {
        // console.log("not found");
      }
      // console.log(k)
    }
    if (k == 0) {
      this.countrymodulelist[y].listing[i].checked = false;
      let f = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].State_name);
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
        this.Selectedcity.splice(f, 1);
      }
      console.log(this.Selectedcity)
    }
    // end
    // alert(JSON.stringify(this.Selectedcity))
  }
  // end

  // phase 4 googleapi module New fucntionality added if all child selecting parent automatic selected by Kunal bansal on 23 june 2021 start
  Selectall(event, y, i) {

    if (event.target.checked == true) {
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {

      }
      else {
        this.Selectedcity.push(this.countrymodulelist[y].listing[i].State_name)
      }
      for (let k = 0; k < this.countrymodulelist[y].listing[i].dist_name.length; k++) {
        this.countrymodulelist[y].listing[i].dist_name[k].checked = true;
        if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].dist_name[k].c)) {

        }
        else {
          this.Selectedcity.push(this.countrymodulelist[y].listing[i].dist_name[k].c)
        }
      }
      console.log(this.Selectedcity)
    }
    else {
      for (let k = 0; k < this.countrymodulelist[y].listing[i].dist_name.length; k++) {
        this.countrymodulelist[y].listing[i].dist_name[k].checked = false;
        if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].dist_name[k].c)) {
          let v = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].dist_name[k].c);
          this.Selectedcity.splice(v, 1);
        }
      }
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
        let po = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].State_name);
        this.Selectedcity.splice(po, 1);
      }
      console.log(this.Selectedcity)
    }
  }
  // end


  // <!-- phase 3 new cr image upload module magnification image added by Kunal bansal on 01 april 2021 2021 start -->
  zoomselection(image, ind) {
    this.zoomselectedimage = image;
    this.zoomimageindex = ind;
  }

  Actualimgselection(image, ind) {
    this.Actualselectedimage = image;
    this.Actualimageindex = ind;
  }

  actualimgpopup(actimg, img, index) {

    this.Actualselectedimage = img;
    this.Actualimageindex = index;
    this.Actualimageseyeactivate = 1;
    this.modalService.open(actimg, { ariaLabelledBy: 'modal-basic-title', }).result.then((result) => { }, (reason) => {
      this.Actualimageseyeactivate = 0;
    });
  }

  zoom(event) {
    console.log(event)
  }
  // end

  // <!-- phase 3 new cr image upload module added by Kunal bansal on 25 march 2021 start -->
  resetimage() {
    $("#imagereset").val("");
  }

  openanywhereclick() {
    // alert('anywhere')
  }

  resetimages() {
    this.imageupload = [];
  }

  imagedelete(z) {
    this.imageupload.splice(z, 1);
    this.imagesinapi.splice(z, 1);
  }

  zoomfeature(img, preview) {
    this.modalService.open(preview, { ariaLabelledBy: 'modal-basic-title', }).result.then((result) => { }, (reason) => { });
    this.selecteduploadimage = img;
  }



  // Image directly upload to s3 bucket scenario added by Kunal bansal on 14 april 2021 start
  uploadfile(file) {

    return new Promise((resolve, reject) => {
      try {
        console.log(file)
        // react native methos implement here by Kunal bansal on 19 april 2021 start
        let headers = new Headers();

        const options = {
          keyPrefix: "inventory/" + file.name,
          bucket: "eolproductimages",
          region: "ap-south-1",
          // latest changed cred of s3 added by Kunal bansal on 12 oct 2021 start
          accessKey: "AKIAX55O2DCSCI2V27AN",
          secretKey: "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",
          // ...end...
          successActionStatus: 201,
          Key: "images/myimage.jpg"
        };
        RNS3.put(file, options)
          .then((response) => {
            console.log("------RNS3----response-------->", response);
            if (response.status !== 201) {
              reject({ message: "status failed s3 upload" })
            } else if (response.status == 201) {
              console.log("-----201------>", response);
              // const parser = new DOMParser();
              // const xml = parser.parseFromString(response.text, 'text/xml');
              // const obj = [];
              // obj.push(this.ngxXml2jsonService.xmlToJson(xml));

              // var slice = obj[0].PostResponse.location;
              // slice = slice.slice(0, slice.length - 1);
              // var remofir = slice;
              // remofir = remofir.slice(1);
              var remofir = response.body.postResponse.key;
              resolve(remofir);

              // this.sorebuckettrans.push(response.body.postResponse.location);


            }
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reject(e);
      }
    });

  }

  // for(let g=0;g<this.imagesinapi.length;g++){
  //   this.sorebuckettrans.push(chpanaayaarplease)
  // }


  // end



  callThisFunction(data): void {

    console.log(data);
    this.testArr.push(data);

    console.log(this.testArr);

  }

  uploadimages(event: any) {

    if (event.target.files.length > 5 || event.target.files.length + this.imageupload.length > 5) {
      Swal.fire("Maximum 5 images can be uploaded")
    }
    else {

      for (let k = 0; k < event.target.files.length; k++) {
        if (event.target.files[k].size / 1020 / 1020 > 4) {

          // alert(event.target.files[k].name + " image size is greater or equal to 4mb so it cannot be uploaded")
          Swal.fire("Maximum Image Upload size can be 4mb/image")
        }

        else {
          this.imagesinapi.push(event.target.files[k])
          formData.append('name', event.target.files[k]);
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[k])

          reader.onload = (event: ProgressEvent) => {

            this.imageupload.push({ "img": (<FileReader>event.target).result });

          }
        }
      }
    }
    this.resetimage();
  }
  //  // end

  // <!-- Share via social sited phase 3 new cr module added by Kunal bansal on 08 march 2021 start -->
  share(a) {

    if (a == 1) {
      window.open("mailto:?subject=Check this product on EolStocks&body=" + this.gmaillink, "_blank")
    }
    else if (a == 2) {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + this.sharelink, "_blank")
    }
    else if (a == 3) {
      window.open("http://www.twitter.com/share?url=" + this.sharelink, "_blank")

    }
    else if (a == 4) {

      window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + this.gmaillink)

    }

    else {

    }
  }
  // end

  ngOnDestroy() {
    this.modalService.dismissAll();
    //Global declared state city set to default state script added by Kunal bansal on 24 june 2021 start
    this.setglobalcitystatedefault();

    // end
  }

  onChange(shopbycategory, secretcode, check) {
    if (check == 1) {
      this.assigned = 1;
      this.descactive = 1;
      this.groupname = [];
      this.grouparray = [];
      this.secret = secretcode;
      this.selectedorderno = [];
      this.checkradio = secretcode;
      // this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title', }).result.then((result) => { }, (reason) => { });

    }
    else if (check == 2) {
      this.assigned = 0;
      this.groupname = [];
      this.grouparray = [];
      this.selectedorderno = [];
      this.descactive = 0;
      this.temp = undefined;
      this.secret = secretcode;
      this.checkradio = secretcode;
      this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
      // this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title', }).result.then((result) => { }, (reason) => { });

    }
    else {
      this.assigned = 0;
      this.descactive = 0;
      this.groupname = [];
      this.grouparray = [];
      this.secret = secretcode;
      this.checkradio = secretcode;
      this.selectedorderno = [];

    }

  }
  openfaq(i) {

    if (this.temp == i) {
      this.temp = undefined;
    }
    else {


      this.temp = i

    }
  }

  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  opencountry(y) {
    if (this.temp3 == y) {
      this.temp3 = undefined;
    }
    else {


      this.temp3 = y

    }
  }

  changecountry(event) {

    // added by Kunal bansal on 23 sep 2021 
    this.countrymodulelist = [];

    this.temp3 = undefined;
    this.selectedcountry = event.target.value;

    if (event.target.value == "India") {
      this.country = ['India', 'UAE'];
      this.selectedcurrency = "India";
      this.countrymodule.getcountrylisting().map(res => {


        this.countrymodulelist.push(res.listing[0]);
        console.log(this.countrymodulelist);
      })
      this.Resetcitystate();
    }
    else {

      this.countrymodule.getcountrylisting().map(res => {


        this.countrymodulelist.push(res.listing[1]);
        console.log(this.countrymodulelist);
      })
      this.country = ['UAE', 'India'];
      this.selectedcurrency = "UAE";
      this.Resetcitystate();

    }
    // ...end...


  }

  // ..............end.............
  // ....end....


  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  deletedistrict(b) {
    let k: string;
    k = this.Selectedcity[b];


    this.Selectedcity.splice(b, 1);
    for (let y = 0; y < this.countrymodulelist.length; y++) {
      for (let i = 0; i < this.countrymodulelist[y].listing.length; i++) {
        if (this.countrymodulelist[y].listing[i].State_name == k) {

          this.countrymodulelist[y].listing[i].checked = false;
        }
        for (let j = 0; j < this.countrymodulelist[y].listing[i].dist_name.length; j++) {
          if (this.countrymodulelist[y].listing[i].dist_name[j].c == k) {
            this.countrymodulelist[y].listing[i].dist_name[j].checked = false;
          }
        }
      }
    }
    console.log(this.Selectedcity)
  }

  // opencitystatepopup(statecity) {
  //   this.temp = undefined;
  //   this.modalService.open(statecity, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  // }

  checklisting() {
    // this.statefetch.Fetchstatecity().then(pos=>{
    //   console.log(pos)
    // })
    // console.log(this.cityfetch.listing)
  }


  opengallery() {
    // login module on upload  image added by Kunal bansal on 28 april 2021 start
    if (this.islogged == 1) {
      $(".imm").trigger("click");

    }
    else {
      this.router.navigate(['/homepage/login'])
    }
    // end
  }

  next(path) {
    for (let i = 0; i < ima.length; i++) {
      if (path == ima[i].image) {
        this.selectedimage = ima[i + 1].image
        break;
      }
    }
    this.ref.detectChanges();
  }

  prev(path) {
    for (let i = 0; i < ima.length; i++) {
      if (path == ima[i].image) {
        this.selectedimage = ima[i - 1].image
        break;
      }
    }
    this.ref.detectChanges();
  }


  // autorotation image library imprt on 09feb2021 start
  change(path, ind, zoommagnify) {

    this.selectedimage = path;
    this.checkactive = 1;
    setTimeout(() => {
      this.checkactive = 0;
    }, 1500);
    this.childindex = ind;
    this.zoomimageindex = ind;
    this.modalService.open(zoommagnify, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
    this.zoomselectedimage = path;
  }


  afterChange(event) {
    // alert(event.currentSlide)
    // added by Kunal bansal on 27 jan 2021
    this.childindex = event.currentSlide;
  }

  // end

  opensellers() {

    if (this.collapsed == true) {
      this.collapsed = false
    }
    else {
      this.collapsed = true
    }
  }

  // <!-- phase 5 point 8 product detail changes variant not avalaible automatic came to working variant add by Kunal bansal on 27 aug 2021 start -->
  variantnotavailable() {
    this.authservice.apipost('ProductDetails/', this.working).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 500 || response.status == 100) {

        Swal.fire(response.message)
      }
      else if (response.status == 102) {

        // this.availabletobuy = 0;
        this.errormessage = response.message;
        // <!-- modified by Kunal bansal on 15feb2021 -->
        this.availabletobuy = 0;

      }
      else if (response.status == 200) {
        this.errormessage = "";
        // modified by Kunal bansal on 04 jan 2021
        this.productdetails = response.response;
        this.images = response.response.images;
        this.Description = response.response.description;
        // alert(this.Description)
        this.variantid = response.response.variant_id;
        // modifed by Kunal bansal on 19feb2021 start
        if (response.response.images.length != 0) {
          this.selectedimage = response.response.images[0].image;
        }
        else {

        }
        // end


        this.allproperties = response.response.properties;
        // <!-- modified by Kunal bansal on 15feb2021 -->
        this.availabletobuy = 1;
        if (response.response.sellers) {
          if (response.response.sellers.length == 0) {
            this.availabletobuy = 0;
            this.errormessage = "This variant not available";

          }
          else {
            this.errormessage = "";
          }
        }
        // if(x==0){
        //   this.position1 = content;
        // }
        // else if (x==1){
        //   this.position2 = content;
        // }
        // else{
        //   this.position3 = content;
        // }




        // this.images.map((value,key) => {
        //   return (
        //     this.galleryImages.push({
        //       small: this.images[key].image,
        //       medium: this.images[key].image,
        //       big: this.images[key].image
        //     })
        //   )
        // })

        // this.position3 = response.response.selected_properties.Tablet_Quantity;

        // this.position1 = response.response.selected_properties.Dosage;
        // this.position2 = response.response.selected_properties.Color;
        // this.position1 = response.response.selected_properties.Dosage;
        // this.position2 = response.response.selected_properties.Color;
        if (response.response.selected_generic_properties[0].p_value[0].code) {
          this.position1 = response.response.selected_generic_properties[0].p_value.name;
        }
        else {
          this.position1 = response.response.selected_generic_properties[0].p_value;
        }

        if (response.response.selected_generic_properties[1].p_value[0].code) {
          this.position2 = response.response.selected_generic_properties[1].p_value.name;
        }
        else {
          this.position2 = response.response.selected_generic_properties[1].p_value;
        }
        if (response.response.selected_generic_properties[2].p_value[0].code) {
          this.position3 = response.response.selected_generic_properties[2].p_value.name;
        }
        else {
          this.position3 = response.response.selected_generic_properties[2].p_value;
        }
        if (response.response.selected_generic_properties.length >= 4) {
          if (response.response.selected_generic_properties[3].p_value[0].code) {
            this.position4 = response.response.selected_generic_properties[3].p_value.name;


          }
          else {

            this.position4 = response.response.selected_generic_properties[3].p_value;
          }
        }

        // modified by Kunal bansal on 14 jan 2021
        if (response.response.sellers) {
          this.sellers = response.response.sellers;

          // alert(this.inventoryimagelength)
          if (this.sellers.length > 0) {
            this.inventoryimagelength = this.sellers[0].inventory_image.length;
            for (let k = 0; k < this.sellers[0].inventory_image.length; k++) {
              this.Actualimages.push({ 'img': this.sellers[0].inventory_image[k].url })
            }
            // phase 6 min q module added by kunal bansal on 21 april 2022 start
            this.Quantitysell = this.sellers[0].minq;
            this.minimumquantity = this.sellers[0].minq;
            // ...end...
          }

        }
        // alert(JSON.stringify(this.sellers))
        if (this.producttype == 0) {
          if (this.sellers.length > 0) {
            this.currentseller = response.response.sellers[0]
          }

        }
      }
      else {
        Swal.fire("Server Down")
      }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  // .......end.......

  // product details new module and params name change and added on 15 july 2020 by Kunal bansal start
  changevariant(i, content) {


    let body;
    let x: number;
    if (this.producttype == 0) { //buy
      x = 0
    }
    else if (this.producttype == 1) { //sell
      x = 1
    }
    if (i == 0) {
      //change color memory internal_storage
      this.popupdetails[0].p_value=content;
      this.position1 = content;
      body = {
        "variant_id": id,
        "position1": content,
        "position3": this.position3,
        "position2": this.position2,
        "selected_list": x,
        "is_searched": this.producttype,
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation,
        "location": this.locationstatus == 1 ? this.Districtname : "",
        "currency": this.productdetails.uae_courrency_conversion,
        "variant_type":'color'
      }
    }
    else if (i == 1) {
      this.popupdetails[1].p_value=content;
      this.position2 = content;
      body = {
        "variant_id": id,
        "position1": this.position1,
        "position3": this.position3,
        "position2": content,
        "selected_list": x,
        "is_searched": this.producttype,
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation,
        "location": this.locationstatus == 1 ? this.Districtname : "",
        "currency": this.productdetails.uae_courrency_conversion,
        "variant_type":'memory'
      }
    }
    else if (i == 2) {
      this.popupdetails[2].p_value=content;
      
      this.position3 = content;

      body = {
        "variant_id": id,
        "position1": this.position1,
        "position3": content,
        "position2": this.position2,
        "selected_list": x,
        "is_searched": this.producttype,
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation,
        "location": this.locationstatus == 1 ? this.Districtname : "",
        "currency": this.productdetails.uae_courrency_conversion,
        "variant_type":'internal_storage'
      }
    }

    this.authservice.apipost('ProductDetails/', body).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 500 || response.status == 100) {

        Swal.fire(response.message)
      }
      else if (response.status == 102) {

        // this.availabletobuy = 0;
        this.errormessage = response.message;
        Swal.fire(response.message)
        // <!-- modified by Kunal bansal on 15feb2021 -->
        this.availabletobuy = 0;
        // <!-- phase 5 point 8 product detail changes variant not avalaible automatic came to working variant add by Kunal bansal on 27 aug 2021 start -->
        this.variantnotavailable();
        // ...end...

      }
      else if (response.status == 200) {

        console.log(this.working)
        // modified by Kunal bansal on 04 jan 2021
        this.productdetails = response.response;
        this.images = response.response.images;
        this.Description = response.response.description;
        // alert(this.Description)
        this.variantid = response.response.variant_id;
        // modifed by Kunal bansal on 19feb2021 start
        if (response.response.images.length != 0) {
          this.selectedimage = response.response.images[0].image;
        }
        else {

        }
        // end


        this.allproperties = response.response.properties;
        // <!-- modified by Kunal bansal on 15feb2021 -->
        this.availabletobuy = 1;

        if (response.response.sellers) {


          if (response.response.sellers.length == 0) {
            this.availabletobuy = 0;
            this.errormessage = "This variant not available";
            // <!-- phase 5 point 8 product detail changes variant not avalaible automatic came to working variant add by Kunal bansal on 27 aug 2021 start -->
            Swal.fire("This variant not available");
            this.variantnotavailable();
            // .....end.....
          }
          else {
            this.errormessage = "";
            this.working = body;
          }
        }
        // if(x==0){
        //   this.position1 = content;
        // }
        // else if (x==1){
        //   this.position2 = content;
        // }
        // else{
        //   this.position3 = content;
        // }




        // this.images.map((value,key) => {
        //   return (
        //     this.galleryImages.push({
        //       small: this.images[key].image,
        //       medium: this.images[key].image,
        //       big: this.images[key].image
        //     })
        //   )
        // })

        // this.position3 = response.response.selected_properties.Tablet_Quantity;

        // this.position1 = response.response.selected_properties.Dosage;
        // this.position2 = response.response.selected_properties.Color;
        // this.position1 = response.response.selected_properties.Dosage;
        // this.position2 = response.response.selected_properties.Color;
        // if (response.response.selected_generic_properties[0].p_value[0].code) {
        //   this.position1 = response.response.selected_generic_properties[0].p_value.name;
        // }
        // else {
        //   this.position1 = response.response.selected_generic_properties[0].p_value;
        // }

        if (response.response.selected_generic_properties[1].p_value[0].code) {
          this.position2 = response.response.selected_generic_properties[1].p_value.name;
        }
        else {
          this.position1 = response.response.selected_generic_properties[0].p_value;
          this.popupdetails[0].p_value= this.position1;

          this.position2 = response.response.selected_generic_properties[1].p_value;
          this.popupdetails[1].p_value= this.position2;

          this.position3 = response.response.selected_generic_properties[2].p_value;
          this.popupdetails[2].p_value= this.position3;
        }
        // if (response.response.selected_generic_properties[2].p_value[0].code) {
        //   this.position3 = response.response.selected_generic_properties[2].p_value.name;
        // }
        // else {
        //   this.position3 = response.response.selected_generic_properties[2].p_value;
        // }
        if (response.response.selected_generic_properties.length >= 4) {
          if (response.response.selected_generic_properties[3].p_value[0].code) {
            this.position4 = response.response.selected_generic_properties[3].p_value.name;


          }
          else {

            this.position4 = response.response.selected_generic_properties[3].p_value;
          }
        }

        // modified by Kunal bansal on 14 jan 2021
        if (response.response.sellers) {
          this.sellers = response.response.sellers;

          // alert(this.inventoryimagelength)
          if (this.sellers.length > 0) {
            this.inventoryimagelength = this.sellers[0].inventory_image.length;
            for (let k = 0; k < this.sellers[0].inventory_image.length; k++) {
              this.Actualimages.push({ 'img': this.sellers[0].inventory_image[k].url })
            }
            // phase 6 min q module added by kunal bansal on 21 april 2022 start
            this.Quantitysell = this.sellers[0].minq;
            this.minimumquantity = this.sellers[0].minq;
            // ...end...
          }
        }
        // alert(JSON.stringify(this.sellers))
        if (this.producttype == 0) {
          if (this.sellers.length > 0) {
            this.currentseller = response.response.sellers[0]
          }
        }
      }
      else {
        Swal.fire("Server Down")
      }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  changeseller(value, index) {

    // this.Quantitysell = 0;

    this.currentseller = value;
    // phase 6 min q module added by kunal bansal on 21 april 2022 start
    this.Quantitysell = value.minq;
    this.minimumquantity = value.minq;
    // .....end....
    this.sellid = value.product_seller_id;
    // added by Kunal bansal on 05 may 2021 start
    this.Actualimages = [];
    // end
    // upload image by seller added by Kunal bansal on 23 april 2021 start
    for (let k = 0; k < this.sellers[index].inventory_image.length; k++) {
      this.inventoryimagelength = this.sellers[index].inventory_image.length;
      this.Actualimages.push({ 'img': this.sellers[index].inventory_image[k].url })
    }
    // end
    // phase 4 new cr partial payment module seller switch payment change added by Kunal bansal on 29 july 2021 start
    this.sellerpaymentmode = [];
    for (let v = 0; v < this.sellers[index].payment_mode.length; v++) {
      this.sellerpaymentmode.push(this.sellers[index].payment_mode[v])
    }
    // end
    this.collapsed = true;
  }

  addtocart(id) {


    if (this.islogged == 1) {
      // phase 6 added by kunal bansal on 21 april 2022 start
      this.addressadded = Number(sessionStorage.getItem('addresslength'));

      // ...end...
      // phase 6 add address alert scenario by kunal bansal on 07 april 2022 start
      if (this.addressadded == 0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 3);
            // phase 6 added by Kunal bansal on 13 may 2022 start
            this.storage.store("address_type", 3)
            // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }

      else {
        if (this.Quantitysell < 1) {
          if (this.language == 'Hindi') {
            Swal.fire("कृपया एक मान्य मात्रा जोड़ें")
          }
          else if (this.language == 'Gujrati') {
            Swal.fire("કૃપા કરી માન્ય માત્રા ઉમેરો")
          }
          else {
            Swal.fire("Please add a valid quantity")
          }
        }
        // add to cart available quanity showing as said by client in phase 4 defect sheet by Kunal bansal 0n 20 april 2021 start
        else if (this.Quantitysell > this.currentseller.quantity) {
          // add to cart available quanity showing as said by client in phase 4 defect sheet by Kunal bansal 0n 20 april 2021
          // Swal.fire("Order quantity is greater than available quantity")
          Swal.fire("Available Quantity is &nbsp;" + this.currentseller.quantity + ",&nbsp;" + "please add Quantity accordingly");
        }
        // end
        else {
          this.authservice.apipost('addToCart/', { order_items_quantity: this.Quantitysell, product_seller_id: id }).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 102 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message)
            }

            else if (response.status == 200) {
              this.globals.cartcount = response.response.cart_count;
              // this.Quantitysell = 0;
              Swal.fire(response.message)
            }
            else { Swal.fire(response.message) }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet")
            }
          )
        }
      }
    }
    else {
      this.router.navigate(['/homepage/login'])
    }
  }

  // directbuy() {
  //   if (this.islogged == 1) {
  //     if (this.Quantitysell < 1) {
  //       Swal.fire("Please add a valid quantity")
  //     }
  //     else if (this.Quantitysell < this.currentseller.MOQ) {
  //       Swal.fire("Minimum Order Quantity for this product is " +this.currentseller.MOQ)
  //     }
  //     else if (this.Quantitysell > this.currentseller.quantity) {
  //       Swal.fire("Order quantity is greater than available quantity")
  //     }
  //     else {
  //       if (this.productdetails.is_address == 1) {
  //         this.storage.store("cart_from", 1)
  //         this.storage.store("direct_buy_data", {
  //           quantity: this.Quantitysell,
  //           sellerid: this.sellers[0].product_seller_id
  //         })
  //         this.router.navigate(['/homepage/cart'])
  //       }
  //       else {
  //         Swal.fire("Please add address from your profile to continue")
  //       }
  //     }
  //   }
  //   else {
  //     this.router.navigate(['/homepage/login'])
  //   }
  // }

  newmodule() {

    if (this.islogged == 1) {
      // phase 6 added by kunal bansal on 21 april 2022 start
      this.addressadded = Number(sessionStorage.getItem('addresslength'));

      // ...end...
      // phase 6 add address alert scenario by kunal bansal on 07 april 2022 start
      if (this.addressadded == 0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 3);
            // phase 6 added by Kunal bansal on 13 may 2022 start
            this.storage.store("address_type", 3)
            // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }

      else {
        if (this.Quantitysell < 1) {
          if (this.language == 'Hindi') {
            Swal.fire("कृपया एक मान्य मात्रा जोड़ें")
          }
          else if (this.language == 'Gujrati') {
            Swal.fire("કૃપા કરી માન્ય માત્રા ઉમેરો")
          }
          else {
            Swal.fire("Please add a valid quantity")
          }
        }
        else if (this.Quantitysell < this.currentseller.MOQ) {
          Swal.fire("Minimum Order Quantity for this product is " + this.currentseller.MOQ)
        }
        else if (this.Quantitysell > this.currentseller.quantity) {
          // buy now alert modified as said by client in phase 4 defect sheet by Kunal bansal 0n 20 april 2021
          // Swal.fire("Order quantity is greater than available quantity")
          Swal.fire("Available Quantity is &nbsp;" + this.currentseller.quantity + ",&nbsp;" + "please add Quantity accordingly");
        }

        else {
          // if (this.productdetails.is_address == 1) {
          let body = {
            coupon: this.coupon,
            quantity: this.Quantitysell,
            product_seller_id: this.sellid
          }
          this.authservice.apipost('buyNow/', body).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 102 || response.status == 500 || response.status == 100) {

              Swal.fire(response.message);
            }
            else if (response.status == 200) {
              this.directbuy();

            }
            else if (response.status == 404) {
              Swal.fire(response.message);

            }
            else { Swal.fire(response.message); }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet");
            }
          )
          // }
          // else {
          //   Swal.fire("Please add address from your profile to continue")
          // }
        }
      }
      // ...end...

    }
    else {
      // var currentLocation = window.location;
      // sessionStorage.setKey("pageurl",currentLocation);
      this.router.navigate(['/homepage/login'])
    }
  }

  directbuy() {
    // if (this.islogged == 1) {
    //   if (this.Quantitysell < 1) {
    //     Swal.fire("Please add a valid quantity")
    //   }
    //   else if (this.Quantitysell < this.currentseller.MOQ) {
    //     Swal.fire("Minimum Order Quantity for this product is " + this.currentseller.MOQ)
    //   }
    //   else if (this.Quantitysell > this.currentseller.quantity) {
    //     Swal.fire("Order quantity is greater than available quantity")
    //   }
    //   else {
    //     if (this.productdetails.is_address == 1) {
    this.storage.store("directbuyactivate", 1)
    this.storage.store("cart_from", 1)
    this.storage.store("direct_buy_data", {
      quantity: this.Quantitysell,
      sellerid: this.sellid
    })
    this.router.navigate(['/homepage/cart'])
    //     }
    //     else {
    //       Swal.fire("Please add address from your profile to continue")
    //     }
    //   }
    // }
    // else {
    //   this.router.navigate(['/homepage/login'])
    // }
  }


  dismiss() {
    this.modalService.dismissAll();
  }

  // <!-- alter Description module on 28 july 2020 by Kunal bansal start -->
  description(desc) {

    this.authservice.apipost('description/', { variant_id: this.variantid }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this
        this.popupdetails = response.response.generic_properties;
        // phase 6 added by kunal bansal on 21 april 2022 start
        this.brandname = response.response.brand_name
        // ...end...


        this.modalService.open(desc, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed `;
        });
        $("#magic").animate({ scrollTop: 0 }, "fast");
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  // end

  closeall() {
    this.modalService.dismissAll();
  }

  delivery(deli) {
    this.authservice.apipost('delivery/', {}).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.delii = response.response.message;
        this.modalService.open(deli, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed `;
        });
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }


  async submitsell(id) {

    if (this.secret == 2 && this.Selectedcity.length == 0) {
      Swal.fire('Please select at least on State/District')
    }
    else if (this.maximum_order_quantity == 0) {
      Swal.fire('Please enter valid Max quanity')

    }

    else {
      this.Loading = 1;
      this.spinner.show();
      let body = {}
      if (this.moq == 0 || this.moq == null || this.moq == undefined) {
        console.log(chpanaayaarplease);
        // Image directly upload to s3 bucket scenario added by Kunal bansal on 14 april 2021 start
        // for (let c = 0; c < this.imagesinapi.length; c++) {


        //   try{
        //     const file = this.imagesinapi[c];
        //     let urlfroms3 = await this.uploadfile(file);
        //     this.sorebuckettrans.push(urlfroms3);
        //   }catch(e){
        //     console.log(e);
        //   }

        // }

        if (this.selectedcountry == "India") {
          body = {
            "variant_id": id,
            "price": this.sellingprice,
            "quantity": this.Quantitysell,
            "product_status": this.sellingstatus,
            "product_instruction": this.specialInstructions,
            "image": this.sorebuckettrans

          }
        }
        else {
          body = {
            "variant_id": id,
            "price": this.sellingprice,
            "quantity": this.Quantitysell,
            "product_status": this.sellingstatus,
            "product_instruction": this.specialInstructions,
            "image": this.sorebuckettrans

          }
        }


      }
      else {
        // alert(JSON.stringify(this.sorebuckettrans));
        // console.log(chpanaayaarplease);
        // Image directly upload to s3 bucket scenario added by Kunal bansal on 14 april 2021 start
        for (let c = 0; c < this.imagesinapi.length; c++) {


          try {
            const file = this.imagesinapi[c];
            let urlfroms3 = await this.uploadfile(file);
            this.sorebuckettrans.push(urlfroms3);
          } catch (e) {
            console.log(e);
          }


        }
        // console.log(chpanaayaarplease);
        // console.log(this.testing);


        // this.sorebuckettrans=chpanaayaarplease;
        // let test = chpanaayaarplease;
        // this.changeDetector.markForCheck();
        if (this.selectedcountry == 'India') {
          // phase 5 new cr added decimal scenario by Kunal bansal on 08 oct 2021 start
          body = {

            "variant_id": id,
            "price": Number(this.sellingprice.toFixed(2)),
            "quantity": this.Quantitysell,
            "bulk_qty": this.moq,

            "product_status": this.sellingstatus,
            "product_instruction": this.specialInstructions,
            "description": this.descactive == 1 ? this.des : '',
            "assign_to": this.assigned,
            "image": this.sorebuckettrans,
            "location": this.secret == 2 ? this.Selectedcity : [],
            // phase 4 new cr partial payment module params added by Kunal bansal on 26 july 2021 start
            "maximum_order_quantity": this.maximum_order_quantity,
            "payment_mode": this.selectedcountry == "India" ? this.payment_mode : ["COD"],
            // end
            // <!-- phase 5 module country module in sell now added by Kunal bansal on 25 aug 2021 start -->
            // "country": this.checkradio == 1 ? this.selectedcountry : "",
            "country": this.selectedcountry,
            "inventory_currency": "INR"

            // "group": this.grouparray
            // sell now module param added group user module

          }

        }
        else {
          body = {

            "variant_id": id,
            "price": Number(this.sellingprice.toFixed(2)),
            "quantity": this.Quantitysell,
            "bulk_qty": this.moq,

            "product_status": this.sellingstatus,
            "product_instruction": this.specialInstructions,
            "description": this.descactive == 1 ? this.des : '',
            "assign_to": this.assigned,
            "image": this.sorebuckettrans,
            "location": this.secret == 2 ? this.Selectedcity : [],
            // phase 4 new cr partial payment module params added by Kunal bansal on 26 july 2021 start
            "maximum_order_quantity": this.maximum_order_quantity,
            "payment_mode": this.selectedcountry == "India" ? this.payment_mode : ["COD"],
            // end
            // <!-- phase 5 module country module in sell now added by Kunal bansal on 25 aug 2021 start -->
            // "country": this.checkradio == 1 ? this.selectedcountry : "",
            "country": this.selectedcountry,
            "inventory_currency": "AED"


            // "group": this.grouparray
            // sell now module param added group user module

          }
        }

      }

      this.authservice.apipost('sellNow/', body).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          this.Loading = 0;
          Swal.fire(response.message);

        }
        else if (response.status == 200) {
          this.Loading = 0;
          Swal.fire(response.response)
          if (response.tab_code == 3) {
            this.router.navigate(['/homepage/inventory/rejected'])
          }
          else if (response.tab_code == 0) {
            this.router.navigate(['/homepage/inventory/pending'])
          }
          else if (response.tab_code == 1) {
            this.router.navigate(['/homepage/inventory/approved'])
          }
          else if (response.tab_code == 2) {
            this.router.navigate(['/homepage/inventory/expired'])
          }
          else {
            this.router.navigate(['/homepage/inventory/pending'])
          }

        }
        else {
          this.Loading = 0;
          Swal.fire("Server Down")
        }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  // product Status integer to string type module changed on 07 august 2020 by Kunal bansal
  sell(id) {

    if (this.islogged == 1) {
      // phase 6 navigate to add address thorugh product detail added by kunal bansal on 02 june 2022 start
      let shippingaddressadded = Number(sessionStorage.getItem('kycshippingaddresslength'));
      let billingaddressadded = Number(sessionStorage.getItem('kycbillingaddresslength'));
      // ...end...
      // phase 6 add mutliple address check scenario added by kunal bansal on 02 june 2022 start
      if (shippingaddressadded == 0 && billingaddressadded > 0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no shipping address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 3);

            this.storage.store("address_type", 2)
            // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }
      else if (shippingaddressadded > 0 && billingaddressadded == 0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no billing address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 3);

            this.storage.store("address_type", 1)
            // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }
      else if (shippingaddressadded == 0 && billingaddressadded == 0) {
        Swal.fire({
          title: 'Warning',
          text: "Currently there is no billing and shipping address added in user profile. Do you want to add address?",

          showCancelButton: true,

          cancelButtonText: 'No',
          confirmButtonText: 'YES',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.storage.store("goingthroughgeneratepickup", 3);

            this.storage.store("address_type", 2)
            // ...end...
            this.router.navigate(['/homepage/addAddress']);
          }
        })
      }
      else {
        if (this.Quantitysell < 1) {
          if (this.language == 'Hindi') {
            Swal.fire("कृपया एक मान्य मात्रा जोड़ें")
          }
          else if (this.language == 'Gujrati') {
            Swal.fire("કૃપા કરી માન્ય માત્રા ઉમેરો")
          }
          else {
            Swal.fire("Please add a valid quantity")
          }
        }
        // added currency based alert  phase 5 by Kunal bansal on 21 sep 2021 start
        else if (this.selectedcountry == "India" && (this.sellingprice < this.productdetails.min_sellprice || this.sellingprice > this.productdetails.max_sellprice)) {

          Swal.fire("The price range specified for this model is ₹" + this.productdetails.min_sellprice + " - ₹" + this.productdetails.max_sellprice + " . Either enter a price between this range or contact EOL")

        }

        else if (this.selectedcountry == "UAE" && (this.sellingprice / this.productdetails.uae_courrency_conversion < this.productdetails.min_sellprice || this.sellingprice / this.productdetails.uae_courrency_conversion > this.productdetails.max_sellprice)) {
          Swal.fire("The price range specified for this model is AED" + (this.productdetails.min_sellprice * this.productdetails.uae_courrency_conversion).toFixed(2) + " - AED" + (this.productdetails.max_sellprice * this.productdetails.uae_courrency_conversion).toFixed(2) + " . Either enter a price between this range or contact EOL")
        }
        // ...end...
        // else if (this.assigned == 1 && this.grouparray.length == 0) {

        //   Swal.fire("Please Select at least one Group")


        // }
        else if (this.moq != 0 && this.moq > this.Quantitysell) {
          Swal.fire("MOQ cannot be greater than Quantity")
        }
        // modified by Kunal bansal on 20 jan 2021
        else if (this.sellingstatus == undefined || this.sellingstatus == "Select") {
          Swal.fire("Please select product status")
        }

        else {
          this.submitsell(id)
        }
      }
    }
    else {
      this.router.navigate(['/homepage/login'])
    }
  }

  // toggleVisibility(event, groupid, groupname) {
  pushorderid(item: any) {

    this.grouparray.push(item.id)

    // var index4 = this.groupname.indexOf(groupname);
    // if (event.target.checked == true) {
    //   this.groupname.push(groupname);
    //   this.grouparray.push(groupid)

    // }
    // else {
    //   var index2 = this.grouparray.indexOf(groupid);
    //   this.grouparray.splice(index2, 1);
    //   this.groupname.splice(index4, 1)
    // }





  }

  removeorderid(item: any) {
    var index4 = this.grouparray.indexOf(item.id);
    this.grouparray.splice(index4, 1)
  }

  wantmore(catid, productname) {

    this.storage.store('detailproductname', productname)
    this.storage.store("buydata", this.productdetails.variant_id);
    this.router.navigate(['/homepage/buyrequest'])
    this.storage.store('productdetailscatid', this.categoryid);


  }


  //Added by Kunal bansal on 15 april 2020;
  sellerpdctlstng(id, sellername) {
    let body = {
      platform: 0,
      offset: 1,
      product_seller_id: id,
      // Geolocation module added by Kunal bansal on 09 sep 2021 start
      "location": this.locationstatus == 1 ? this.Districtname : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation
    }
    this.authservice.apipost('SellerProductList/', body).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 401) {
        Swal.fire("No seller has been added with that variant")
      }
      else if (response.status == 200) {
        this.storage.store("slctprdctlstng", response.response.buying_list);
        this.storage.store("Sellerid", id)
        this.storage.store("Sellname", sellername);
        this.storage.clear("brandlabelslisting");
        this.router.navigate(['/homepage/sellerproductlstng'])
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }



  // phase 6 min q module added by kunal bansal on 21 april 2022 start
  checkquantity() {
    if (this.Quantitysell < this.minimumquantity) {
      Swal.fire(`Minimum quantity limit for that product is ${this.minimumquantity}`);
      this.Quantitysell = this.minimumquantity;
    }
  }

}
