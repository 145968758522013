import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-buy-list',
  templateUrl: './buy-list.component.html',
  styleUrls: ['./buy-list.component.css']
})
  
export class BuyListComponent implements OnInit {
  @Input() Buyselllist: any;
  @Input() recent: any;
  logged: any = 0;
  p: number = 1;
  
  constructor(private storage: SessionStorageService, private router: Router) { }

  ngOnInit() {
    this.logged = this.storage.retrieve('islogged');
  }

  productdetail(id) {
    this.storage.store("pdid", id);
    this.storage.store("ptype", 0);
    this.router.navigate(['/homepage/productdetail']);
  }

  pdetail(id) {
    this.storage.store("pdid", id);
    this.storage.store("ptype", 0);
    this.router.navigate(['/homepage/productdetail']);
  }
  
}