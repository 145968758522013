import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
import { HttpModule } from '@angular/http';
import { appRoutes } from './app.routing';
import { NgbModule , NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Ng2Webstorage } from 'ngx-webstorage';
import { NgxSpinnerModule } from "ngx-spinner";
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxAsideModule } from 'ngx-aside';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './auth.service';
import { Statecitylisting } from './Statecitylisting';
import { Globals } from './globals';
import { WindowRefService } from './window-ref.service';
import { AutocompleteModule } from 'ng2-input-autocomplete';
// autorotation image library imprt on 09feb2021 start
import { SlickCarouselModule } from 'ngx-slick-carousel';
// end


import { HomepageComponent } from './homepage/homepage.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { SellListComponent } from './sell-list/sell-list.component';
import { BuyListComponent } from './buy-list/buy-list.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AddNewAddressComponent } from './add-new-address/add-new-address.component';
import { KycUploadComponent } from './kyc-upload/kyc-upload.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CartComponent } from './cart/cart.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignupVerificationComponent } from './signup-verification/signup-verification.component';
import { FaqComponent } from './faq/faq.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NotificationComponent } from './notification/notification.component';
import { AskNowComponent } from './ask-now/ask-now.component';
import { BuyRequestComponent } from './buy-request/buy-request.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { InventoryComponent } from './inventory/inventory.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { InventoryApprovedComponent } from './inventory-approved/inventory-approved.component';
import { InventoryPendingComponent } from './inventory-pending/inventory-pending.component';
import { InventoryRejectedComponent } from './inventory-rejected/inventory-rejected.component';
import { SearchedListComponent } from './searched-list/searched-list.component';
import { KycEditComponent } from './kyc-edit/kyc-edit.component';
import { InventoryExpiringComponent } from './inventory-expiring/inventory-expiring.component';
import { MyOrdersSellComponent } from './my-orders-sell/my-orders-sell.component';
import { MyOrdersBuyComponent } from './my-orders-buy/my-orders-buy.component';
import { BannerDetailsComponent } from './banner-details/banner-details.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { CouponComponent } from './coupon/coupon.component';
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgBufferingModule } from 'videogular2/compiled/buffering';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { ConnectionServiceModule } from 'ng-connection-service';
import { Sellerproductlisting } from './sellerproductlisting/sellerproductlisting';
import { Changeaddress } from './changeaddress/changeaddress';
import { Videoplayer } from './Videoplayer/Videoplayer';
import { Orderqueries } from './orderqueries/Orderqueries';
import { Querydetails } from './querydetails/querydetails';
import { Categorylisting } from './Categorylisting/Categorylisting';
import { Brandlisting } from './Brandlisting/Brandlisting';


// phase 3 module
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';


import { CareersComponent } from './careers/careers.component';


import { TechnologyComponent } from './technology/technology.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';

import { BuySellComponent } from './buy-sell/buy-sell.component';
import { HelpComponent } from './help/help.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TeamComponent } from './team/team.component';
import { ContactComponent } from './contact/contact.component';
import { NgxImgZoomModule } from 'ngx-img-zoom';

import { Uaepoboxes } from '../app/Services/Uaepoboxes.service';
import { Countrylisting } from '../app/Services/Countrylisting';

// phase 5 cr ledger point neew transaction screen added by Kunal bansal on 18 oct 2021 start
import { MyTransactionsComponent } from '../app/my-transactions/my-transactions.component'
// ...end...
// Phase 5 new api common service created by KUnal bansal on 21 oct 2021 start
import {ApiserviceService} from '../app/Services/apiservice.service';
import { MyBuyersComponent } from './my-buyers/my-buyers/my-buyers.component';
import { Currentdate } from './Services/getcurrentdate';
import { SellerLedgerComponent } from './seller-ledger/seller-ledger/seller-ledger.component';
import { EwallbillComponent } from './ewaybill/ewallbill/ewallbill.component';
import { CommonbannerscreenComponent } from './commonbannerscreen/commonbannerscreen/commonbannerscreen.component';
import { BannerpopupComponent } from './bannerpopup/bannerpopup/bannerpopup.component';
   // <!-- phase 6 code shortening anf optimizind added by 24 may 2022 start -->
import { SellrenderComponent } from './sellrender/sellrender.component';
import { BuyrenderComponent } from './buyrender/buyrender/buyrender.component';
import { MyorderlabelsComponent } from './myorderlabels/myorderlabels/myorderlabels.component';
import { OtpinputuiComponent } from './otpinputui/otpinputui/otpinputui.component';
import { RazorpayreplicaComponent } from './razorpayreplica/razorpayreplica/razorpayreplica.component';
// added by Kunal bansal for making code totally resuable start
import { CommonhomepagefilterComponent } from './commonhomepagefilter/commonhomepagefilter/commonhomepagefilter.component';
import { CommonhomepagesortComponent } from './commonhomepagesort/commonhomepagesort/commonhomepagesort.component';
import { CommoncategorypopupComponent } from './commoncategorypopup/commoncategorypopup.component';
// ...end...



@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    ProductListingComponent,
    SellListComponent,
    BuyListComponent,
    ContactUsComponent,
    ProductDetailsComponent,
    MyProfileComponent,
    AddNewAddressComponent,
    KycUploadComponent,
    AboutUsComponent,
    CartComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    SignupVerificationComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    ChangePasswordComponent,
    NotificationComponent,
    AskNowComponent,
    BuyRequestComponent,
    MyOrdersComponent,
    InventoryComponent,
    OrderDetailsComponent,
    InventoryApprovedComponent,
    InventoryPendingComponent,
    InventoryRejectedComponent,
    SearchedListComponent,
    KycEditComponent,
    InventoryExpiringComponent,
    MyOrdersSellComponent,
    MyOrdersBuyComponent,
    BannerDetailsComponent,
    OurTeamComponent,
    CouponComponent,
    OrderTrackingComponent,
    Sellerproductlisting,
    Changeaddress,
    Videoplayer,
    Orderqueries,
    Querydetails,
    Categorylisting,
    Brandlisting,

    HomeComponent,
    HeaderComponent,
    FooterComponent,
    WhoWeAreComponent,


    CareersComponent,
    WhoWeAreComponent,
    TechnologyComponent,
    WhatWeDoComponent,
    TeamComponent,
    BuySellComponent,
    HelpComponent,
    TermsConditionsComponent,
    ContactComponent,
    // phase 5 cr ledger point neew transaction screen added by Kunal bansal on 18 oct 2021 start
    MyTransactionsComponent,
    MyBuyersComponent,
    SellerLedgerComponent,
    EwallbillComponent,
    CommonbannerscreenComponent,
    BannerpopupComponent,
    SellrenderComponent,
    BuyrenderComponent,
    MyorderlabelsComponent,
    OtpinputuiComponent,
    RazorpayreplicaComponent,
    CommonhomepagefilterComponent,
    CommonhomepagesortComponent,
    CommoncategorypopupComponent
    // ...end...
  ],
  imports: [
    BrowserModule,
    ConnectionServiceModule,
    VgCoreModule,
    NgxImgZoomModule,

    VgControlsModule,
    VgBufferingModule,
    VgOverlayPlayModule,
    RouterModule.forRoot(appRoutes),
    AutocompleteModule.forRoot(),
    NgbModule,
    HttpModule,
    Ng2Webstorage,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AngularMultiSelectModule,
    Ng5SliderModule,
    NgxAsideModule,
    HttpClientModule,
    ClickOutsideModule,
    NgxPaginationModule,
    // autorotation image library imprt on 09feb2021 start
    SlickCarouselModule,
    // end
    NgbPaginationModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthService,
    Statecitylisting,
    WindowRefService,
    DatePipe,
    Globals,
    Uaepoboxes,
    Countrylisting,
    ApiserviceService,
    Currentdate,
    CommonbannerscreenComponent,
    // <!-- phase 6 code shortening anf optimizind added by 24 may 2022 start -->
    SellrenderComponent,
    BuyrenderComponent,
    MyorderlabelsComponent,
    OtpinputuiComponent,
    RazorpayreplicaComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}