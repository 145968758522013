import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuyerprotectGuard implements CanActivate {


  constructor(private storage:SessionStorageService,private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let checkbuyerstatus = sessionStorage.getItem("userisagent");
    let x = this.storage.retrieve('islogged');
    if (checkbuyerstatus == 'no' || (x==0|| x== undefined || x == null)) {
      this.router.navigate(['homepage/productListing'])
    }
    else {
      return true;
    }

  }
}
