import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-banner-details',
  templateUrl: './banner-details.component.html',
  styleUrls: ['./banner-details.component.css']
})
export class BannerDetailsComponent implements OnInit {
  image: any;

  constructor(private storage: SessionStorageService) { }

  ngOnInit() {
    this.image = this.storage.retrieve('image_path');
  }

}