import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { RNS3 } from 'react-native-s3-upload-new';
import { Statecitylisting } from '../Statecitylisting';
import { Countrylisting } from '../Services/Countrylisting';

@Component({
  selector: 'app-rejected-approved',
  templateUrl: './inventory-rejected.component.html',
  styleUrls: ['./inventory-rejected.component.css']
})

export class InventoryRejectedComponent implements OnInit {
  closeResult: string;
  formquantity: FormGroup;
  sellerid: string;
  inventory: any[];
  totalcount: any;
  flag: number = 1;
  pstatus: string;
  category: any[];
  filter = [];
  selectedprodname = [];
  selectedprodtype = [];

  dropdownSettings = {};
  dropdownSettings2 = {};
  selectedItems2: any[];
  selectedItems3: any[];
  selectedItems4: any[];
  brands: any[];
  temp: number;
  temp2: number = 0;
  checkradio: number = 0;
  faq: any[];
  checkedsel: number = 1;
  pushbrandid = [];
  prodname: any[];
  producttype = [];

  assignTo: number;
  alluseractive: number;

  offset: number = 1;
  inventoryactive: number = 0;

  showcategory = [];
  selectactive = 1;


  Appliedcatfilters = [];
  duplicatecatfilters = [];
  Appliedbrandfilter = [];
  Appliedproductname = [];
  Appliedproducttype = [];
  showlabel: number = 0;
  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  Applieddatefrom = [];
  Applieddateto = [];
  pickupdatefrom: string = "";
  pickupdateto: string = "";
  tomindate: string = "";
  frommaxdate: string = "";
  tomaxdate: string = "";
  // end
  selectedimages = [];
  sendimagesinapi = [];
  actualimageselection = [];


  imageupload = [];
  imagesinapi = [];
  Loading: number = 0;
  manualsearchfield: string;
  // phase 4 googleapi district state multiselect drodown module New fucntionality added  by kunal bansal on 24 june 2021
  cityfetch: any = Statecitylisting;
  Selectedcity = [];
  temp3: number;
  checklengthdiststate: number;
  secretcode: number;
  // end
  // <!-- phase 4 cr payment option filteration module added by Kunal bansal on 15 july 2021 start -->
  paymentmodule = [];
  paymentlisting: any[];
  // end
  // phase 4 new cr partial payment module params added by Kunal bansal on 27 july 2021 start
  maximum_order_quantity: number;
  codactivate: number = 0;
  partialactivate: number = 0;
  payment_mode = [];
  // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
  selectedpayment = [];
  selectedpaymentlabels = [];
  Appliedpaymentlabels = [];
  Applieddatelabelfrom = [];
  Applieddatelabelto = [];
  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  countrymodulelist = [];
  temp4: number;
  country = [];
  selectedcountry: string;
  // ...end...
  // added by Kunal bansal on 23 sep 2021 
  selectedcurrency: string = "India";
  // ...end...
    // ............phase 6 dynamic selection of product status added by kunal bansal on 22 april 2022 start....
    productstatus:any=[];

  constructor(private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal, private formBuilder: FormBuilder, private statefetch: Statecitylisting, private countrymodule: Countrylisting) {
    this.cityfetch = statefetch;
  }

  // Pagination inventory issue fixed on 28 aug 2020 by Kunal bansal
  @HostListener("window:scroll", ["$event"])

  onWindowScroll(e) {

    if (($(window).scrollTop() + $(window).innerHeight()) >= $(document).height() - this.getScrollbarWidth()) {
      if (this.inventory.length < this.totalcount && this.inventoryactive == 0) {
        this.getList(this.flag)
      }
      else {
        if (this.inventory.length < this.totalcount) {
          this.filterapi()
        }
      }
    }
  }

  getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  }

  ngOnInit() {
    this.getList(1)
    this.formquantity = this.formBuilder.group({
      quantity: [''],
      price: [''],
      moq: [''],
      // phase 4 new cr partial payment module params added by Kunal bansal on 27 july 2021 start
      maxq: [''],
      // end
      desc: [''],
      instructions: [''],
      status: ['']
    });
    // this.producttype.push({
    //   "name":"Feature Phone",id:1},{
    //     "name":"Smart Phone",id:2
    //   }
    // )
    this.dropdownSettings = {
      singleSelection: true,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      enableCheckAll: false,
      labelKey: "category_name",
      classes: "font_drop",
      maxHeight: 200


    };
    this.dropdownSettings2 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",

      labelKey: "name",
      classes: "font_drop",
      maxHeight: 200


    };
    // <!-- phase 4 cr payment option filteration module added by Kunal bansal on 15 july 2021 start -->
    this.paymentmodule = [{ "name": "Online Payment", id: 2 }, { "name": "POD", id: 1 }, { "name": "Partial Payment", id: 3 }];
    // end
    // <!-- phase 5 module country module in sell now added by Kunal bansal on 25 aug 2021 start -->
    this.countrymodule.getcountrylisting().map(res => {


      this.countrymodulelist = res.listing;
      console.log(this.countrymodulelist);
    })
    this.country = ['India', 'UAE'];
    this.selectedcountry = "India";
    // ...end...
  }

  // phase 4 googleapi district state multiselect drodown module New fucntionality added  by kunal bansal on 24 june 2021
  ngOnDestroy() {
    this.setglobalcitystatedefault();
  }
  // end

  // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
  paymentselection(event) {
    if (this.selectedpayment.includes(event.id)) {

    }
    else {
      this.selectedpayment.push(event.id);
      this.selectedpaymentlabels.push({ "name": event.name });
    }
  }

  paymentDeselection(event) {
    let find = this.selectedpayment.indexOf(event.id);
    this.selectedpayment.splice(find, 1);
    this.selectedpaymentlabels.splice(find, 1);
  }

  // end

  // New module adding  flilter in inventory by Kunal bansal on 05 march 2021 start
  fromdate(event) {

    var fromdate = event.target.value.split('-').reverse().join('/');
    this.Applieddatefrom = fromdate;
    // added by Kunal bansal on 02feb2021 


    this.tomindate = event.target.value;

  }

  // end

  // <!-- Image upload in edit inventory added by Kunal bansal on 22 april 2021 start -->
  opengallery() {
    $(".imm").trigger("click");
  }

  resetimage() {
    $("#imagereset").val("");
  }
  selimagedelete(z) {

    this.selectedimages.splice(z, 1);
    this.sendimagesinapi.splice(z, 1);
  }
  imageuploaddelete(z) {
    this.imageupload.splice(z, 1);
    this.imagesinapi.splice(z, 1);
  }
  imagedelete(z) {
    // alert(z)
    this.actualimageselection.splice(z, 1);
  }


  // Image directly upload to s3 bucket scenario added by Kunal bansal on 22 april 2021 start
  uploadfile(file) {

    return new Promise((resolve, reject) => {
      try {
        console.log(file)
        // react native methos implement here by Kunal bansal on 19 april 2021 start
        let headers = new Headers();

        const options = {
          keyPrefix: "inventory/" + file.name,
          bucket: "eolproductimages",
          region: "ap-south-1",
         // latest changed cred of s3 added by Kunal bansal on 12 oct 2021 start
         accessKey: "AKIAX55O2DCSCI2V27AN",
         secretKey: "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",
         // ...end...
          successActionStatus: 201,
          Key: "images/myimage.jpg"
        };
        RNS3.put(file, options)
          .then((response) => {
            console.log("------RNS3----response-------->", response);
            if (response.status !== 201) {
              reject({ message: "status failed s3 upload" })
            } else if (response.status == 201) {
              console.log("-----201------>", response);
              // const parser = new DOMParser();
              // const xml = parser.parseFromString(response.text, 'text/xml');
              // const obj = [];
              // obj.push(this.ngxXml2jsonService.xmlToJson(xml));

              // var slice = obj[0].PostResponse.location;
              // slice = slice.slice(0, slice.length - 1);
              // var remofir = slice;
              // remofir = remofir.slice(1);
              var remofir = response.body.postResponse.key;
              resolve(remofir);

              // this.sorebuckettrans.push(response.body.postResponse.location);


            }
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reject(e);
      }
    });

  }

  // for(let g=0;g<this.imagesinapi.length;g++){
  //   this.sorebuckettrans.push(chpanaayaarplease)
  // }


  // end

  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  opencountry(y) {
    if (this.temp3 == y) {
      this.temp3 = undefined;
    }
    else {


      this.temp3 = y

    }
  }

  changecountry(event) {

    // added by Kunal bansal on 23 sep 2021 
    this.countrymodulelist = [];

    this.temp3 = undefined;
    this.selectedcountry = event.target.value;

    if (event.target.value == "India") {
      this.country = ['India', 'UAE'];
      this.selectedcurrency = "India";
      this.countrymodule.getcountrylisting().map(res => {


        this.countrymodulelist.push(res.listing[0]);
        console.log(this.countrymodulelist);
      })
      this.Resetcitystate();
    }
    else {

      this.countrymodule.getcountrylisting().map(res => {


        this.countrymodulelist.push(res.listing[1]);
        console.log(this.countrymodulelist);
      })
      this.country = ['UAE', 'India'];
      this.selectedcurrency = "UAE";
      this.Resetcitystate();

    }
    // ...end...


  }

  // ..............end.............
  // ....end....


  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  deletedistrict(b) {
    let k: string;
    k = this.Selectedcity[b];


    this.Selectedcity.splice(b, 1);
    for (let y = 0; y < this.countrymodulelist.length; y++) {
      for (let i = 0; i < this.countrymodulelist[y].listing.length; i++) {
        if (this.countrymodulelist[y].listing[i].State_name == k) {

          this.countrymodulelist[y].listing[i].checked = false;
        }
        for (let j = 0; j < this.countrymodulelist[y].listing[i].dist_name.length; j++) {
          if (this.countrymodulelist[y].listing[i].dist_name[j].c == k) {
            this.countrymodulelist[y].listing[i].dist_name[j].checked = false;
          }
        }
      }
    }
    console.log(this.Selectedcity)
  }

  openfaq3(i) {

    if (this.temp3 == i) {
      this.temp3 = undefined;
    }
    else {
      this.temp3 = i
    }
  }

  // phase 4 googleapi district state multiselect drodown module New fucntionality added  by kunal bansal on 24 june 2021
  opencitystatepopup(statecity) {

    this.temp3 = undefined;
    this.modalService.open(statecity, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  }

  // phase 4 googleapi district state multiselect drodown module New fucntionality added  by kunal bansal on 24 june 2021
  Resetcitystate() {
    this.Selectedcity = [];
    this.setglobalcitystatedefault();
  }

  // phase 4 googleapi district state multiselect drodown module New fucntionality added  by kunal bansal on 24 june 2021
  submitcitystate() {
    this.modalService.dismissAll();
  }

  // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
  setglobalcitystatedefault() {
    for (let y = 0; y < this.countrymodulelist.length; y++) {
      for (let i = 0; i < this.countrymodulelist[y].listing.length; i++) {
        this.countrymodulelist[y].listing[i].checked = false;
        for (let j = 0; j < this.countrymodulelist[y].listing[i].dist_name.length; j++) {
          this.countrymodulelist[y].listing[i].dist_name[j].checked = false;
        }
      }
    }
  }

  // phase 4 googleapi module New fucntionality added if all child selecting parent automatic selected by Kunal bansal on 23 june 2021 start
  onebyone(event, y, i, c) {

    // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
    if (event.target.checked == true) {
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].dist_name[c].c)) {
      }
      else {
        this.Selectedcity.push(this.countrymodulelist[y].listing[i].dist_name[c].c);

      }
      // Added By Kunal bansal on 01 july 2021 start
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
      }
      else {
        this.Selectedcity.push(this.countrymodulelist[y].listing[i].State_name);

      }
      this.countrymodulelist[y].listing[i].checked = true;
      // end
      let g = 0;
      for (let n = 0; n < this.countrymodulelist[y].listing[i].dist_name.length; n++) {
        if (this.countrymodulelist[y].listing[i].dist_name[n].checked == true) {
          // console.log(n)
          // console.log('true available')
          g = g + 1;
          // console.log(k);
        }
        else {
          // console.log("not found");
        }
        // console.log(k)
      }

      if (this.countrymodulelist[y].listing[i].dist_name.length == g) {
        console.log("all selected");
        this.countrymodulelist[y].listing[i].checked = true;
        // Added By Kunal bansal on 01 july 2021 start
        if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
        }
        else {
          this.Selectedcity.push(this.countrymodulelist[y].listing[i].State_name);

        }
        // this.Selectedcity.push(this.cityfetch.listing[i].State_name);
      }
      // console.log(g)
      console.log(this.Selectedcity);
    }
    // end

    else {
      // commented by Kunal bansal on 01 july 2021 start
      // this.cityfetch.listing[i].checked = false;
      // end
      let p = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].dist_name[c].c);
      this.Selectedcity.splice(p, 1);
      // commented by Kunal bansal on 01 july 2021 start
      // if (this.Selectedcity.includes(this.cityfetch.listing[i].State_name)) {
      //   let po = this.Selectedcity.indexOf(this.cityfetch.listing[i].State_name);
      //   this.Selectedcity.splice(po, 1);

      // }
      // end
      console.log(this.Selectedcity);
    }

    let k = 0;
    for (let n = 0; n < this.countrymodulelist[y].listing[i].dist_name.length; n++) {
      if (this.countrymodulelist[y].listing[i].dist_name[n].checked == true) {
        k = k + 1;
        // console.log(k);
      }
      else {
        // console.log("not found");
      }
      // console.log(k)
    }
    if (k == 0) {
      this.countrymodulelist[y].listing[i].checked = false;
      let f = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].State_name);
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
        this.Selectedcity.splice(f, 1);
      }
      console.log(this.Selectedcity)
    }
    // end
    // alert(JSON.stringify(this.Selectedcity))
  }
  // end

  // phase 4 googleapi module New fucntionality added if all child selecting parent automatic selected by Kunal bansal on 23 june 2021 start
  Selectall(event, y, i) {

    if (event.target.checked == true) {
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {

      }
      else {
        this.Selectedcity.push(this.countrymodulelist[y].listing[i].State_name)
      }
      for (let k = 0; k < this.countrymodulelist[y].listing[i].dist_name.length; k++) {
        this.countrymodulelist[y].listing[i].dist_name[k].checked = true;
        if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].dist_name[k].c)) {

        }
        else {
          this.Selectedcity.push(this.countrymodulelist[y].listing[i].dist_name[k].c)
        }
      }
      console.log(this.Selectedcity)
    }
    else {
      for (let k = 0; k < this.countrymodulelist[y].listing[i].dist_name.length; k++) {
        this.countrymodulelist[y].listing[i].dist_name[k].checked = false;
        if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].dist_name[k].c)) {
          let v = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].dist_name[k].c);
          this.Selectedcity.splice(v, 1);
        }
      }
      if (this.Selectedcity.includes(this.countrymodulelist[y].listing[i].State_name)) {
        let po = this.Selectedcity.indexOf(this.countrymodulelist[y].listing[i].State_name);
        this.Selectedcity.splice(po, 1);
      }
      console.log(this.Selectedcity)
    }
  }
  // end

  // <!-- /* phase 4 new cr search bar in inventory code styling added by Kunal bansal on 21 june 2021 start */ -->
  manualsearch() {

    this.offset = 1;
    this.authservice.apipost('inventory/', {
      "inventory_flag": 3,
      "offset": this.offset,
      "search": this.manualsearchfield
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.duplicatecatfilters = [];
        this.Applieddatefrom = [];
        this.Applieddateto = [];
        this.Appliedbrandfilter = [];
        this.Appliedproductname = [];
        this.Appliedproducttype = [];

        if (this.offset == 1) {
          this.inventory = response.response.final_list;
        }
        else {
          this.inventory = this.inventory.concat(response.response.final_list);
        }
        this.totalcount = response.response.total_count;
        this.manualsearchfield = "";



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("Connect to the Internet")
        Swal.fire("Network Error")
      }
    )
    // this.listing=[];
  }




  uploadimages(event: any) {

    if (event.target.files.length > 5 || event.target.files.length + this.imageupload.length + this.selectedimages.length > 5) {
      Swal.fire("Maximum 5 images can be uploaded")
    }
    else {

      for (let k = 0; k < event.target.files.length; k++) {
        if (event.target.files[k].size / 1020 / 1020 > 4) {

          // alert(event.target.files[k].name + " image size is greater or equal to 4mb so it cannot be uploaded")
          Swal.fire("Maximum Image Upload size can be 4mb/image")
        }

        else {
          this.imagesinapi.push(event.target.files[k])
          // formData.append('name', event.target.files[k]);
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[k])

          reader.onload = (event: ProgressEvent) => {

            this.imageupload.push({ "img": (<FileReader>event.target).result });

          }
        }
      }
    }
    this.resetimage();
  }
  //  // end





  todate(event) {
    if (this.pickupdatefrom.length == 0) {
      Swal.fire("please select upper date first")
      this.pickupdateto = "";
    }
    else {

    }
    var todate = event.target.value.split('-').reverse().join('/');
    this.Applieddateto = todate;
  }

  // end

  // phase 4 new cr partial payment module added by Kunal bansal on 26 july 2021 start
  enablepayment(event, checkmode) {


    if (event.target.checked == true && checkmode == 2) {
      if (this.payment_mode.includes("Partial")) {

      }
      else {
        this.partialactivate = 1;
        this.payment_mode.push("Partial");
      }

    }
    else if (event.target.checked == false && checkmode == 2) {
      let find = this.payment_mode.indexOf("Partial");
      this.payment_mode.splice(find, 1);
      this.partialactivate = 0;
    }
    else if (event.target.checked == true && checkmode == 1) {
      if (this.payment_mode.includes("COD")) {

      }
      else {
        this.codactivate = 1;
        this.payment_mode.push("COD");
      }
    }
    else if (event.target.checked == false && checkmode == 1) {
      let find = this.payment_mode.indexOf("COD");
      this.payment_mode.splice(find, 1);
      this.codactivate = 0;
    }
    else {

    }
    console.log(this.payment_mode)

  }
  // end

  // <!-- adding Filter module on 02 sep 2021 by Kunal bansal -->
  opencategorymodal(shopbycategory) {
    this.offset = 1;
    // New module adding  flilter in inventory by Kunal bansal on 05 march 2021 start
    this.pickupdatefrom = "";
    this.pickupdateto = "";
    this.tomindate = "";
    // end
    this.showcategory = [];
    this.producttype = [];
    this.selectactive = 1;
    this.selectedItems2 = [];
    this.selectedItems3 = [];
    this.selectedItems4 = [];
    this.showcategory = [];
    this.brands = [];
    this.prodname = [];
    this.pushbrandid = [];
    this.selectedprodname = [];
    this.selectedprodtype = [];

    this.temp = undefined;
    this.temp2 = undefined;
    this.checkradio = 0;
    this.filter = [];
    this.checkedsel = 1;
    //  phase 3 new cr adding date filter in inventory on 05 march 2021 start
    var dateobj = new Date();
    var month = dateobj.getUTCMonth() + 1;
    var date = dateobj.getUTCDate();
    var year = dateobj.getUTCFullYear();
    if (month.toString().length == 1 && date.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 2 && date.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 1 && date.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else {
      var fulldate = year + "-" + month + "-" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    // end
    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    this.authservice.apipost('CategoryList/', {
      "flag": "filtercatgory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))
        this.category = response.response;
        this.faq = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("ERROR : " + err)
        Swal.fire("Network Error")
      }
    )
    this.authservice.apiget('static_Filter_Data/?rtype=all_brand').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        if (this.filter.length == 0) {
          this.brands = [];
          this.prodname = [];
          this.selectedItems2 = [];
          this.selectedItems3 = [];
        }

      }
      else if (response.status == 200) {
        // this.selectedItems2 = [];

        this.brands = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();

        Swal.fire("Network Error")
      }

    )

  }

  // <!-- // Google api module ui city state ui added by Kunal bansal on 24 june 2021 start -->
  updateuser(user, secret, statecity, length) {
    this.assignTo = user;
    this.secretcode = 0;
    this.checklengthdiststate = length;
    // <!-- // Google api module ui city state ui added by Kunal bansal on 24 june 2021 start -->
    if (secret == 4) {
      this.temp3 = undefined;
      this.secretcode = secret;
      this.checklengthdiststate = length;
      this.modalService.open(statecity, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    }
    // end
  }


  categories(item: any) {
    // alert(item.id)
    this.authservice.apipost('CategoryBrandList/', {
      category: item.id

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.brands = response.response;

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
  }

  Bybrand(item: any) {
    this.pushbrandid.push(item.id);
    // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
    // this.Appliedbrandfilter.push(item)
    // end
    this.authservice.apipost('static_Filter_Data/?rtype=product', {
      "category": this.filter,
      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {

        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.prodname = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
    this.authservice.apipost('static_Filter_Data/?rtype=product_type', {

      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {

        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.producttype = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
  }

  removebrand(item: any) {
    var orno = this.pushbrandid.indexOf(item.ordernumber);
    // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
    // var brandid=this.Appliedbrandfilter.indexOf(item.name)
    // this.Appliedbrandfilter.splice(brandid,1)
    for (let i = 0; i < this.Appliedbrandfilter.length; i++) {
      var bra = this.Appliedbrandfilter[i].name;
      if (bra == item.name) {
        this.Appliedbrandfilter.splice(i, 1)
      }

    }
    // end
    this.pushbrandid.splice(orno, 1)
    this.authservice.apipost('static_Filter_Data/?rtype=product', {
      "category": this.filter,
      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {

        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.prodname = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )

    this.authservice.apipost('static_Filter_Data/?rtype=product_type', {

      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {

        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.producttype = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )

  }

  Byproductname(item: any) {
    this.selectedprodname.push(item.name);
    // this.Appliedproductname.push(item);

  }

  removeproductname(item: any) {
    var prname = this.selectedprodname.indexOf(item.name);

    this.selectedprodname.splice(prname, 1)
  }

  Byproducttype(item: any) {
    this.selectedprodtype.push(item.name);
    // this.Appliedproducttype.push(item);
  }

  removeproducttype(item: any) {
    var prtype = this.selectedprodtype.indexOf(item.name);

    this.selectedprodtype.splice(prtype, 1)
  }

  removeshowcategory(s, idchild, parent, child) {
    let index = this.filter.indexOf(idchild)

    this.Appliedcatfilters.splice(s, 1);
    this.filter.splice(index, 1)

    this.showcategory.splice(s, 1)


    this.category[parent].child_category[child].checked = false;

    this.authservice.apipost('static_Filter_Data/?rtype=brand', {
      "category": this.filter
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        if (this.filter.length == 0) {
          this.brands = [];
          this.prodname = [];
          this.selectedItems2 = [];
          this.selectedItems3 = [];
        }

      }
      else if (response.status == 200) {
        this.selectedItems2 = [];

        this.brands = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("ERROR : " + err)
        Swal.fire("Network Error")
      }
    )



  }
  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  //  removeappliedbrandfilter(index,id){
  //    this.offset=1;
  // this.Appliedbrandfilter.splice(index,1)
  // let v=this.pushbrandid.indexOf(id)
  // this.pushbrandid.splice(v,1)
  // // alert(JSON.stringify(this.pushbrandid))
  // this.authservice.apipost('inventoryFilter/', {
  //   "platform":0,
  //     "tab":1,
  //     "offset":this.offset,
  //     "category_id":this.filter,
  //             "brand":this.pushbrandid,
  //             "product":this.selectedprodname,
  //             "product_type":this.selectedprodtype

  // }).subscribe((res: any) => {
  //   let response = res.json();
  //     if (response.status == 400 || response.status == 500 || response.status == 100) {
  //       Swal.fire(response.message)
  //     }
  //     else if (response.status == 200) {

  //       if(this.offset==1){
  //         this.inventory = response.response.inventory_list[0];
  //         }
  //         else{
  //           this.inventory = this.inventory.concat(response.response.inventory_list[0]);
  //         }
  //       this.totalcount = response.response.total_count;
  //       this.inventoryactive=1;
  //       // this.offset=this.offset+1;

  // // this.filter=[];
  //       this.modalService.dismissAll();


  //     }
  //     else { Swal.fire("Server Down") }
  //   }
  //     , err => {   
  //       this.spinner.hide();
  //       Swal.fire("ERROR : " + err)
  //     }
  // )
  //  }
  //  end

  commoninventoryfilter() {
    if (this.filter.length == 0 && this.pushbrandid.length == 0 && this.selectedprodname.length == 0 && this.selectedprodtype.length == 0) {
      this.getList(1)
    }
    else {
      this.authservice.apipost('inventoryFilter/', {
        "platform": 0,
        "tab": 3,
        "offset": this.offset,
        "category_id": this.filter,
        "brand": this.pushbrandid,
        "product": this.selectedprodname,
        "product_type": this.selectedprodtype,
        // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
        "payment_mode": this.selectedpayment

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          if (this.offset == 1) {
            this.inventory = response.response.inventory_list[0];
          }
          else {
            this.inventory = this.inventory.concat(response.response.inventory_list[0]);
          }
          this.totalcount = response.response.total_count;
          // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
          this.selectedpayment = [];
          this.paymentlisting = [];
          //  end
          this.inventoryactive = 1;
          this.offset = this.offset + 1;

          // this.filter=[];
          this.modalService.dismissAll();


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )
    }
    // }
  }


  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removecatfilter(index, id) {

    this.offset = 1;
    this.Appliedcatfilters.splice(index, 1)
    let k = this.filter.indexOf(id);
    this.filter.splice(k, 1)
    this.commoninventoryfilter()
  }
  //  end


  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeappliedbrandfilter(index, id) {
    this.offset = 1;
    this.Appliedbrandfilter.splice(index, 1)

    let v = this.pushbrandid.indexOf(id);
    this.pushbrandid.splice(v, 1)
    this.commoninventoryfilter()
  }
  //  end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeappliedproductname(index, id) {
    this.offset = 1;
    this.Appliedproductname.splice(index, 1)
    let z = this.selectedprodname.indexOf(id);
    this.selectedprodname.splice(z, 1)
    this.commoninventoryfilter()
  }
  //  end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeappliedproducttype(index, id) {

    this.offset = 1;
    this.Appliedproducttype.splice(index, 1)
    let y = this.selectedprodtype.indexOf(id);
    this.selectedprodtype.splice(y, 1)
    this.commoninventoryfilter()
  }
  //  end



  toggleVisibility(e, a, catname, parindex, chindex) {





    var index2 = this.filter.indexOf(a);


    if (e.target.checked == true) {
      this.filter.push(a)
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      if (this.Appliedcatfilters.includes(catname)) {

      }
      else {
        this.Appliedcatfilters.push({ 'id': a, 'name': catname });

      }
      // end

      this.showcategory.push({ 'name': catname, "id": a, "parindex": parindex, "chiindex": chindex })
      // alert(JSON.stringify(this.showcategory))


      this.authservice.apipost('static_Filter_Data/?rtype=brand', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {

        }
        else if (response.status == 200) {

          this.brands = response.response;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();

          Swal.fire("Network Error")
        }
      )

      this.authservice.apipost('static_Filter_Data/?rtype=product_type', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // this.modalService.dismissAll();
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.selectedItems2=[];
          this.producttype = response.response;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("ERROR : " + err)
          Swal.fire("Network Error")
        }
      )


    }
    else {
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      var element2 = this.Appliedcatfilters.indexOf(catname);
      this.Appliedcatfilters.splice(element2, 1)
      // end

      for (let index = 0; index < this.showcategory.length; index++) {
        var element = this.showcategory[index].name;
        if (element == catname) {
          this.showcategory.splice(index, 1)

        }
      }

      this.filter.splice(index2, 1)
      this.authservice.apipost('static_Filter_Data/?rtype=brand', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {

          if (this.filter.length == 0) {
            this.brands = [];
            this.prodname = [];
            this.selectedItems2 = [];
            this.selectedItems3 = [];
          }

        }
        else if (response.status == 200) {

          this.brands = response.response;




        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();

          Swal.fire("Network Error")
        }
      )


      this.authservice.apipost('static_Filter_Data/?rtype=product_type', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // this.modalService.dismissAll();
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.selectedItems2=[];
          this.producttype = response.response;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("ERROR : " + err)
          Swal.fire("Network Error")
        }
      )




    }



  }
  filterapi() {
    //     if(this.filter.length==0 ){
    // Swal.fire("Select at least one category filter cannot be empty")
    //     }
    // else{
    var fromdate = this.pickupdatefrom.split('-').reverse().join('/');
    var todate = this.pickupdateto.split('-').reverse().join('/');
    if (this.pickupdateto.length == 0 && this.pickupdatefrom.length != 0) {
      Swal.fire("Please select To Date")
    }
    else {
      this.authservice.apipost('inventoryFilter/', {
        "platform": 0,
        "tab": 3,
        "offset": this.offset,
        "category_id": this.filter,
        "date_range": this.pickupdatefrom.length != 0 && this.pickupdateto.length != 0 ? fromdate + "-" + todate : "",
        "brand": this.pushbrandid,
        "product": this.selectedprodname,
        "product_type": this.selectedprodtype,
        // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
        "payment_mode": this.selectedpayment

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.duplicatecatfilters = this.Appliedcatfilters;
          this.Appliedcatfilters = [];
          this.Appliedbrandfilter = this.selectedItems2;
          this.Appliedproductname = this.selectedItems3;
          this.Appliedproducttype = this.selectedItems4;
          // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start

          this.paymentlisting = [];
          this.selectedpayment = [];
          this.Appliedpaymentlabels = this.selectedpaymentlabels;
          this.Applieddatelabelfrom = this.Applieddatefrom;
          this.Applieddatelabelto = this.Applieddateto;

          this.selectedpaymentlabels = [];
          this.Applieddatefrom = [];
          this.Applieddateto = [];
          //  end
          this.showlabel = 1;
          if (this.offset == 1) {
            this.inventory = response.response.inventory_list[0];
          }
          else {
            this.inventory = this.inventory.concat(response.response.inventory_list[0]);
          }
          this.totalcount = response.response.total_count;
          this.inventoryactive = 1;
          this.offset = this.offset + 1;

          // this.filter=[];
          this.modalService.dismissAll();


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )
    }
    // }
  }

  Removefilter() {

    this.showcategory = [];
    this.filter = [];
    this.selectedItems2 = [];
    this.selectedItems3 = [];
    this.selectedItems4 = [];
    this.brands = [];
    this.prodname = [];
    this.producttype = [];

    $(".yup[type='checkbox']").prop('checked', false);
    location.reload();
  }

  getList(flag) {
    this.authservice.apipost('inventory/', {
      "inventory_flag": 3,
      "offset": flag
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.inventoryactive = 0;
        if (flag == 1) {
          this.inventory = response.response.final_list;


          this.totalcount = response.response.total_count
        }
        else {
          this.inventory = this.inventory.concat(response.response.final_list)
        }
        this.flag = this.flag + 1
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("ERROR : " + err)
        Swal.fire("Network Error")
      }
    )
  }

  // popup on another popup issue wwant to buy module fixed by Kunal Bansal on 30 june 2021 start
  closestatedist(statecity) {
    this.modalService.dismissAll();

    setTimeout(() => {
      this.modalService.open(statecity, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    }, 10);
  }

  //  end

  closeall() {
    this.modalService.dismissAll();
  }

  edit(modify, quantity, id, price, moq, maxq, desc, instruction, status, assign, invimages, selectedstatedist, Partialpayment, country,catid) {

    // phase 4 new cr partial payment module params added by Kunal bansal on 27 july 2021 start
    // pod issue fixed on 01 sep 2021
    this.payment_mode = [];
    // pod issue fixed on 01 sep 2021
    this.payment_mode.push("RazorPay");
    this.codactivate = 0;
    this.partialactivate = 0;
    for (let part = 0; part < Partialpayment.length; part++) {
      // pod issue fixed on 01 sep 2021
      if (Partialpayment[part] == "COD" || Partialpayment[part] == "POD") {
        this.codactivate = 1;
        this.payment_mode.push("COD")
      }
      if (Partialpayment[part] == "Part Payment") {
        this.partialactivate = 1;
        this.payment_mode.push("Partial")
      }


    }
    console.log(this.payment_mode)
    // end
    // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
    this.countrymodulelist = [];
    if (country == 'UAE') {
      this.country = ['UAE', 'India'];
      this.selectedcountry = "UAE";
      // added by Kunal bansal on 23 sep 2021 
      this.selectedcurrency = "UAE";
      this.countrymodule.getcountrylisting().map(res => {


        this.countrymodulelist.push(res.listing[1]);
        console.log(this.countrymodulelist);
      })
      // ...end...
    }
    else {
      this.country = ['India', 'UAE'];
      this.selectedcountry = "India";
      // added by Kunal bansal on 23 sep 2021 
      this.selectedcurrency = "India";
      this.countrymodule.getcountrylisting().map(res => {


        this.countrymodulelist.push(res.listing[0]);
        console.log(this.countrymodulelist);
      })
      // ...end...
    }
    // ...end...

    // phase 4 googleapi district state multiselect drodown module New fucntionality added  by kunal bansal on 24 june 2021
    this.checklengthdiststate = selectedstatedist.length;
    if (this.checklengthdiststate == 0) {
      this.secretcode = 0;
    }
    else {
      this.secretcode = 4;
    }
    this.setglobalcitystatedefault();
    this.Selectedcity = [];
    for (let state = 0; state < selectedstatedist.length; state++) {
      this.Selectedcity.push(selectedstatedist[state]);
    }
    //  this.Selectedcity.push("Andaman & Nicobar Islands", "Adilabad", "Kurnool", "Andhra Pradesh", "Srikakulam");
    // end
    // <!-- phase 5 module country render new service added by Kunal bansal on 25 aug 2021 start -->
    for (let i = 0; i < this.Selectedcity.length; i++) {
      for (let y = 0; y < this.countrymodulelist.length; y++) {
        for (let f = 0; f < this.countrymodulelist[y].listing.length; f++) {
          if (this.Selectedcity[i] == this.countrymodulelist[y].listing[f].State_name) {
            this.countrymodulelist[y].listing[f].checked = true;
            if (this.Selectedcity.includes(this.countrymodulelist[y].listing[f].State_name)) {

            }
            else {
              this.Selectedcity.push(this.countrymodulelist[y].listing[f].State_name);
            }

          }
          for (let k = 0; k < this.countrymodulelist[y].listing[f].dist_name.length; k++) {

            if (this.Selectedcity[i] == this.countrymodulelist[y].listing[f].dist_name[k].c) {
              this.countrymodulelist[y].listing[f].dist_name[k].checked = true;
              if (this.Selectedcity.includes(this.countrymodulelist[y].listing[f].dist_name[k].c)) {

              }
              else {
                this.Selectedcity.push(this.countrymodulelist[y].listing[f].dist_name[k].c);
              }

            }
          }
        }
      }
    }
    // end
    this.selectedimages = [];
    this.imageupload = [];
    this.imagesinapi = [];
    // alert(JSON.stringify(invimages))
    for (let v = 0; v < invimages.length; v++) {
      this.selectedimages.push(invimages[v].url)
      this.sendimagesinapi.push(invimages[v].image_key)
    }
    if (assign == false) {
      this.assignTo = 0;
    }
    else {
      this.assignTo = 1;
    }
    this.sellerid = id
    this.pstatus = status;
    this.formquantity.controls['quantity'].setValue(quantity)
    this.formquantity.controls['price'].setValue(price)
    this.formquantity.controls['moq'].setValue(moq)
    // phase 4 new cr partial payment module params added by Kunal bansal on 27 july 2021 start
    this.formquantity.controls['maxq'].setValue(maxq)
    // end
    this.formquantity.controls['desc'].setValue(desc)
    this.formquantity.controls['instructions'].setValue(instruction)
     // ............phase 6 dynamic selection of product status added by kunal bansal on 22 april 2022 start.... 
     this.authservice.apipost('productStatusList/', { "category_id": catid }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        console.log(response.message)
      }
      else if (response.status == 200) {
  
        this.productstatus = response.response;
        this.formquantity.controls['status'].setValue(status)
        // this.sellingstatus=response.response[0].product_status__name;
        // alert(this.sellingstatus)
  
  
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // .....end.....
    $(document).ready(function () {
      $("input[type=number]").keypress(function (evt) {
         // commented by Kunal bansal on 07 oct 2021 to add decimal scenario as per phase 5 cr
        // if ((evt.which < 48 || evt.which > 57) || ($(this).val().length == 10)) {
        //   if (evt.which == 8 || evt.which == 0) { }
        //   else { evt.preventDefault(); }
        // }
      })
    })
    this.modalService.open(modify, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }



  selectall(a) {

    this.selectactive = 0;
    for (let index = 0; index < this.category.length; index++) {
      for (let Innerindex = 0; Innerindex < this.category[index].child_category.length; Innerindex++) {

        if (this.filter.includes(this.category[index].child_category[Innerindex].id)) {
        }
        else {

          this.filter.push(this.category[index].child_category[Innerindex].id);

          this.Appliedcatfilters.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id });
          this.showcategory.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id, 'parindex': index, 'chiindex': Innerindex });
          //  $(".yup[type='checkbox']").prop('checked', true);
          for (let i = 0; i < this.category.length; i++) {
            for (let k = 0; k < this.category[i].child_category.length; k++) {
              this.category[i].child_category[k].checked = true;
            }

          }


        }
      }
    }
    //  this.authservice.apipost('static_Filter_Data/?rtype=brand', {
    //   "category":this.filter
    // }).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     if(this.filter.length==0){
    //       this.brands=[];
    //       this.prodname=[];
    //       this.selectedItems2=[];
    //       this.selectedItems3=[];
    //                 }

    //   }
    //   else if (response.status == 200) {
    //     this.selectedItems2=[];

    //    this.brands=response.response;



    //   }
    //   else { Swal.fire("Server Down") }
    // }
    //   , err => {
    //     this.spinner.hide();

    //     Swal.fire("Network Error")
    //   }
    // )
    this.temp2 = 1;
    this.temp = 1;
  }

  Deselectall() {
    for (let i = 0; i < this.category.length; i++) {
      for (let k = 0; k < this.category[i].child_category.length; k++) {
        this.category[i].child_category[k].checked = false;
      }

    }
    // $(".yup[type='checkbox']").prop('checked', false);
    this.selectactive = 1;
    this.Appliedcatfilters = [];
    this.filter = [];
    this.showcategory = [];
    // this.authservice.apipost('static_Filter_Data/?rtype=brand', {
    //   "category":this.filter
    // }).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     if(this.filter.length==0){
    //       this.brands=[];
    //       this.prodname=[];
    //       this.selectedItems2=[];
    //       this.selectedItems3=[];
    //                 }

    //   }
    //   else if (response.status == 200) {
    //     this.selectedItems2=[];

    //    this.brands=response.response;



    //   }
    //   else { Swal.fire("Server Down") }
    // }
    //   , err => {
    //     this.spinner.hide();

    //     Swal.fire("Network Error")
    //   }
    // )
    this.temp = 0;
    // this.temp=undefined;
    // this.temp2=undefined;
  }

  openfaq(i) {
    this.checkradio = 5;
    if (this.temp == i) {
      this.temp = undefined;
    }
    else {

      // if(this.selectactive==0){
      //   $(".yup[type='checkbox']").prop('checked', true);
      // }
      // else{
      //   $(".yup[type='checkbox']").prop('checked', false);
      // }

      this.temp = i



    }

  }

  onOpen() {
    this.checkradio = 0;
    this.temp2 = undefined;
    this.temp = undefined;
    //  if(this.filter.length==0){
    //    Swal.fire('Please select Category First')
    //  }

  }
  onOpenproduct() {
    // if(this.pushbrandid.length==0){
    //   Swal.fire('Please select Brand First')
    // }
  }

  openfaq2(c) {
    // this.temp=undefined;
    // this.temp2 = undefined;
    if (this.temp2 == c) {
      this.checkradio = 0;
      this.temp = undefined;
      this.temp2 = undefined;
    }
    else {

      this.checkradio = 5;
      this.temp2 = 1;


    }

  }

  delete(id) {

    Swal.fire({
      title: 'Are you sure you want to Delete this item from your inventory ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.authservice.apipost('inventory/', {
          action: "Delete",
          sell_request_id: id
        }).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            location.reload();
            // this.inventory=response.response.inventory_list[0];
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("ERROR : " + err)
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return false;
      }
    })

  }


  async updatequantity(id) {


    if (this.formquantity.controls['price'].value == null || this.formquantity.controls['price'].value.length == 0) {
      Swal.fire('Please add a valid Price range')
    }
    else if (this.formquantity.controls['quantity'].value == null || this.formquantity.controls['quantity'].value == 0) {
      Swal.fire('Please add a valid Quantity')
    }
    else if (this.formquantity.controls['moq'].value == 0) {
      Swal.fire('Please add a valid MOQ')
    }
    else if (this.secretcode == 4 && this.Selectedcity.length == 0) {
      Swal.fire('Please select at least on State/District')
    }
    else {
      this.Loading = 1;
      this.spinner.show();
      // Image directly upload to s3 bucket scenario added by Kunal bansal on 14 april 2021 start
      for (let c = 0; c < this.imagesinapi.length; c++) {


        try {
          const file = this.imagesinapi[c];
          let urlfroms3 = await this.uploadfile(file);
          // this.selectedimages.push(urlfroms3);
          this.sendimagesinapi.push(urlfroms3);
        } catch (e) {
          console.log(e);
        }

      }
      // added by Kunal bansal on 23 sep 2021 
      let body = {}
        // phase 5 new cr added decimal scenario by Kunal bansal on 07 oct 2021 start
      if (this.selectedcountry == 'India') {
        body = {
          "action": "Edit",
          "product_seller_id": id,
          "sell_request_id":id,
          "bulk_price": Math.round(this.formquantity.controls['moq'].value),
          "quantity": Math.round(this.formquantity.controls['quantity'].value),
          "sell_price": Number(this.formquantity.controls['price'].value.toFixed(2)),
          "assign_to": this.assignTo,
          "description": this.assignTo == 0 ? "" : this.formquantity.controls['desc'].value,
          "image": this.sendimagesinapi,
          "product_status": this.formquantity.controls['status'].value,
          "product_instruction": this.formquantity.controls['instructions'].value,
          "location": this.secretcode == 4 ? this.Selectedcity : [],
          // phase 4 new cr partial payment module params added by Kunal bansal on 27 july 2021 start
          "maximum_order_quantity": this.formquantity.controls['maxq'].value == null ? "" : Math.round(this.formquantity.controls['maxq'].value),
          "payment_mode": this.selectedcountry == "India" ? this.payment_mode : ["COD"],
          // end
          "country": this.selectedcountry,
          
          "inventory_currency": "INR"
        }
      }
      // added by Kunal bansal on 23 sep 2021 
      else {
        body = {
          "action": "Edit",
          "product_seller_id": id,
          "sell_request_id":id,
          "bulk_price": Math.round(this.formquantity.controls['moq'].value),
          "quantity": Math.round(this.formquantity.controls['quantity'].value),
          "sell_price": Number(this.formquantity.controls['price'].value.toFixed(2)),
          "assign_to": this.assignTo,
          "description": this.assignTo == 0 ? "" : this.formquantity.controls['desc'].value,
          "image": this.sendimagesinapi,
          "product_status": this.formquantity.controls['status'].value,
          "product_instruction": this.formquantity.controls['instructions'].value,
          "location": this.secretcode == 4 ? this.Selectedcity : [],
          // phase 4 new cr partial payment module params added by Kunal bansal on 27 july 2021 start
          "maximum_order_quantity": this.formquantity.controls['maxq'].value == null ? "" : Math.round(this.formquantity.controls['maxq'].value),
          "payment_mode": this.selectedcountry == "India" ? this.payment_mode : ["COD"],
          // end
          // "country": this.assignTo == 0 && this.checklengthdiststate == 0 ? this.selectedcountry : "",
          "country": this.selectedcountry,
          "inventory_currency": "AED"
        }
      }
      // alert(this.formquantity.controls['status'].value)
      this.authservice.apipost('inventory/', body).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          this.Loading = 0;
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.Loading = 0;
          Swal.fire({
            title: response.message,
            allowOutsideClick: false,
            showCancelButton: false,
          }).then((result) => {
            if (result.value) {
              location.reload();
            }
          })
          this.modalService.dismissAll();
        }
        else {
          this.Loading = 0;
          Swal.fire("Server Down")
        }
      }
        , err => {
          this.spinner.hide();
          this.Loading = 0;
          Swal.fire("ERROR : " + err)
        }
      )
    }
  }

  // increase() {
  //   this.formquantity.controls['quantity'].setValue(this.formquantity.controls['quantity'].value + 1)
  // }

  // decrease() {
  //   this.formquantity.controls['quantity'].setValue(this.formquantity.controls['quantity'].value - 1)
  // }

}
