import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth.service'
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare let Email: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css', '../../assets/Newwebfiles/style.css']
})
export class ContactComponent implements OnInit {

  contents: any = {};
  angForm: FormGroup;
  status: any;

  constructor(private server: DataService, private fb: FormBuilder, private router: Router, public sanitizer: DomSanitizer, private authservice: AuthService,private spinner:NgxSpinnerService) {
    this.createForm();
  }

  createForm() {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10),
      ]],
      message: ['', Validators.required],
    });
  }

  // added by Kunal bansal for Adding Mail system on 25 feb 2021
  addProduct(name, email, subject, phone, message) {
    
    this.authservice.apipost('WebQueryMail/', {
      'name': name, 'email': email, 'phone': phone, 'subject': subject, 'message': message,'form_type':'contact_us'
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.status = 'Your request has been successfully submitted. we will contact you soon.';
        this.angForm.reset();
      }
      else {
        Swal.fire('Server down')
      }
    }, err => {
      this.spinner.hide();
      Swal.fire("Connect to the Internet")

    }
    )
    //  end
    // commented by Kunal bansal on 25 feb 2021 to remove smtp module and adding new module
    // Email.send({
    //   Host : 'smtp.gmail.com',
    //   Username : 'naveen.sbinnovations@gmail.com',
    //   Password : 'yqlwqeuuijezuymf',
    //   To : 'business@eolstocks.com',
    //   From : 'naveen.sbinnovations@gmail.com',
    //   Subject : 'EOL - Contact Us',
    //   Body : '<i>This is sent as a request from EOL contact us page.</i><br> <br/> <b>Name: </b> '+name+' <br /> <b>Email: </b>'+email+'<br /> <b>Phone: </b>'+phone+'<br /><b>Subject: </b>'+subject+'<br /> <b>Message:</b> <br /> '+message+' <br><br> <b>Team<br>EOL</b>'
    //   }).then( 
    //     message => 
    //     {
    //       this.status = 'Your request has been successfully submitted. we will contact you soon.'; 
    //       this.angForm.reset(); 
    //     } 
    //   );


    // this.server.createContactUs(newEvent).then((response: any) => {
    //   console.log('Response', response);
    //   this.status = response.success;
    //   this.angForm.reset();
    // });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.getcontactcontent();
  }

  private getcontactcontent() {
    this.server.getcontactcontent().then((response: any) => {
      this.contents = response.data[0];
    });
  }

}
