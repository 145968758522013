import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { WindowRefService } from '../window-ref.service';
import { Globals } from '../globals';
import { environment } from '../../environments/environment';
import $ from 'jquery';
import { ApiserviceService } from '../Services/apiservice.service';




let cart_from = 0;
let myVarsecond;
let myVar;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})

export class CartComponent implements OnInit {
  cart: any;
  address: any = [];
  closeResult: string;
  cart_from: number;
  buydata: any;
  switchcartvalue: 1;
  p_type: number = 1;
  marked = true;        //added by Kunal on 23 april 2020
  checkmagic: number = 1;         //added by Kunal on 23 april 2020
  marked2 = false;            //added by Kunal on 23 april 2020
  options = {};
  rzp1: any;
  order_id: any;
  email: '';
  phonenumber: '';
  productsellerid: any = [];
  coupon: '';
  removalmessage: '';
  categoryname: string = 'category';
  checkdiscount: number = 0; //added by Kunal on 23 march 2020
  codmessage: string;
  // added on 09 aug 2021 start
  partpaymentmessage: string;
  // end

  checklanguage: string;
  // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 01 march 2021 start -->
  agentcode: string = "";
  // end
  // <!-- phase 3 new cr selecting or add address module added by Kunal bansal on 15 march 2021 start-->
  seladdress: any = [];
  // end
  editquantity: number;
  finalquantity: number;
  editquantyproductsellerid: number;

  marked3 = false;

  country: string;


  agentbehalfactive: number = 0;
  // phase 5 search place order on behalf of buyer added by Kunal bansal on 08 sep 2021 start -->
  Buyerlisting = [
  ]
  otpactive: number = 0;
  selectedbuyerdetail = [];
  // ...end...
  // phase 5 search place order on behalf of buyer added by Kunal bansal on 08 sep 2021 start -->
  buyersearch: string;
  buyerfilterarray = [];
  // phase 5 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
  buyerlistactive: number = 0;
  // phase 5 search place order on behalf of buyer added by Kunal bansal on 10 sep 2021 start -->
  otptimerminute: any;
  permanentotptimestore: any;
  otptimersecond: any = 60;
  otpbuttondisabled = false;
  otpvisible: boolean = false;
  // phase 5 search place order on behalf of buyer added by Kunal bansal on 17 sep 2021 start -->
  placeorderdisabled: boolean = false;
  permanentotpsecondstimestore: any;
  validateotpverify: boolean = false;

  // phase 5 razor pay dynamic payment mode handle from backend added by Kunal bansal on 13 oct 2021 start
  paymentmethod = {
    netbanking: false,
    card: false,
    wallet: false,
    upi: false,
    emi: false
  };

  razorpayreplicamodes = [];
  walletbalance: number = 200;
  walletactive: boolean = false;
  userisagent: string;

  // Dynamic payment mode price added by Kunal bansal on 06 dec 2021 start
  Dynamicpaymentmodeprices={
    NetBanking:0,
    Card:0,
    Wallet:0,
    UPI:0,
    EMI:0
  }
  Eolspecialcharge:number;
  // ...end...

  selectedpaymodeforwallet:string;
  browsername: number = 1;
  couponcode:string='';
  productNotAvailable: string = 'Product is not available';


  constructor(private trans: ApiserviceService, private globals: Globals, private ngZone: NgZone, private storage: SessionStorageService,
    private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal,
    private router: Router, private winRef: WindowRefService) { }

  ngOnInit() {

    // phase 6 added by kunal bansal on 24 may 2022 to fix otp atomatic input siwtch b/w firebox and other browsers start
    let browsername = navigator.userAgent;
    if (browsername.includes('Firefox')) {
      this.browsername = 0;
    }
    else {
      this.browsername = 1;
    }
    // ....end.....
    this.checklanguage = this.storage.retrieve("language");
    let checkwalletstatus=this.storage.retrieve("walletactive");
    if(checkwalletstatus==null){
      this.walletactive=false;
    }
  else{
    this.walletactive=checkwalletstatus;
  }


    // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 15 sep 2021 start -->
    let getbuyerradio;
    getbuyerradio = this.storage.retrieve("placeorderbuyerradio");

    if (getbuyerradio == 'self') {
      this.agentbehalfactive = 0;
    }
    else if (getbuyerradio == 'other') {
      let checkplaceorderstatus;
      checkplaceorderstatus = this.storage.retrieve('placeorderdisabled');

      this.agentbehalfactive = 1;

      this.placeorderdisabled = true;
    }
    else {
      this.agentbehalfactive = 0;
    }

    // ....end...

    this.otptimerminute = "0" + 1;
    //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
    this.country = sessionStorage.getItem('phase5country');
    // phase 5 agent check for cart and side drawer added by kunal bansal on 22 nov 2021 start
    this.userisagent = sessionStorage.getItem("userisagent");
    // ...end...
    if (this.country == "UAE") {
      this.storage.store('checkcodpre', 0)
    }
    // ....end....

    this.p_type = this.storage.retrieve('checkcodpre');


    if (this.p_type == null) {   //Added by Kunal on 01 may 2020
      this.p_type = 1;

    }
    // phase 5 search place order on behalf of buyer added by Kunal bansal on 17 sep 2021 start -->

    this.selectedbuyerdetail = this.storage.retrieve("selectedbuyerdetail");
    // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 20 sep 2021 start -->
    if (this.selectedbuyerdetail == null) {
      this.selectedbuyerdetail = [];
    }
    // ...end...

    // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 20 sep 2021 start -->
    let otp_validate;
    let checkgenerateotpstatus;
    otp_validate = this.storage.retrieve("verifyotp");
    checkgenerateotpstatus = this.storage.retrieve("generateotpactive");
    if (otp_validate != null || checkgenerateotpstatus != null) {


      if (otp_validate == false && checkgenerateotpstatus == 1) {
        // ...end...
        if (this.selectedbuyerdetail != null) {
          clearInterval(myVar);
          clearInterval(myVarsecond);
          this.otptimerminute = this.storage.retrieve('cartotptimerstore');
          this.permanentotptimestore = this.otptimerminute;
          this.permanentotpsecondstimestore = this.storage.retrieve('cartotptimersecondstore');
          this.resetonoinitotp();
        }
        else {
          this.selectedbuyerdetail = [];
        }
      }
    }


    // ...end...

    this.buydata = this.storage.retrieve('direct_buy_data');
    this.cart_from = this.storage.retrieve('cart_from');

    if (this.cart_from == 1) {
      if (this.p_type == 0) {
        this.marked2 = true;
        this.marked = false;
        this.marked3 = false;
      }
      // <!-- phase 4 new cr partial payment module added by Kunal bansal on 29 june 2021 start -->
      else if (this.p_type == 2) {
        this.marked3 = true;
        this.marked2 = false;
        this.marked = false;
      }
      // end
      else {
        this.marked = true;
        this.marked2 = false;
        this.marked3 = false;
      }
    }
    this.coupon = this.storage.retrieve('coupon');
// phase 6 added by Kunal bansal on 27 june 2022 start
this.couponcode=this.storage.retrieve("couponcode")
// ...end....
    if (this.cart_from != 1) {
      let body = {};
      if (this.coupon != null || this.coupon != undefined || this.coupon != '') {
        body = {
          coupon: this.coupon,
          // phase 6 added by Kunal bansal on 27 june 2022 start
          serial_number:this.couponcode,
// ...end....
          payment_flag: this.p_type,
        }

      }

      this.authservice.apipost('getCartProduct/', body).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 102 || response.status == 500 || response.status == 100) {
          this.checkmagic = this.storage.retrieve('checkcodpre')    //added by Kunal on 23 april 2020
          if (this.checkmagic == 0) {
            this.marked2 = true;
            this.marked = false;
            this.marked3 = false;
          }
          // <!-- phase 4 new cr partial payment module added by Kunal bansal on 29 june 2021 start -->
          else if (this.checkmagic == 2) {
            this.marked3 = true;
            this.marked2 = false;
            this.marked = false;
          }
          // end
          else {
            this.marked2 = false;                   //added by Kunal on 23 april 2020
            this.marked = true;
            this.marked3 = false;

          }
          this.hitapi();   //added by Kunal on 25 march 2020
          // added by Kunal bansal on 09 march 2021 to add gap b/w 2 alerts start
          setTimeout(() => {
            Swal.fire(response.message);
          }, 1500);
          // end

          // added by kunal bansal on 03 march 2021
          // added by Kunal bansal to add scenario api hit on evry tm radio button change on 04 march 2021 start
          this.storage.store("coupon", "");
          this.coupon = "";
          // end
        }

        else if (response.status == 200) {
          // modified by Kunal bansal on 15feb2021 to fix cart count not updated issue
            if (response.error==1 && (this.coupon != null || this.coupon != undefined || this.coupon != '')) {
              Swal.fire(response.message);
              this.storage.clear('coupon');
              this.storage.clear("couponcode")
            }
          this.cart = response.response;

          this.globals.cartcount = response.response.cart_count;
          this.codmessage = (response.response.payment_text_cod.cod_message);
          //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
          if (this.country == 'UAE') {
            this.Uaemodulecodmessage();
          }
          // ...end...

          //  added module if partial payment charges zero no need to show alert by Kunal bansal on 09 aug 2021 start
          this.partpaymentmessage = response.response.pay_partial.cod_message;
          // end



          this.p_type = this.storage.retrieve('checkcodpre')
          if (this.p_type == null) {   //Added by Kunal on 01 may 2020
            this.p_type = 1;

          }

          this.checkmagic = this.storage.retrieve('checkcodpre')   //added by Kunal on 23 april 2020
          if (this.cart.payment_text_cod.discount != 0) {
            this.checkdiscount = 1;
          }
          else {
            this.checkdiscount = 0;
          }//added by Kunal on 23 april 2020
          if (this.checkmagic == 0) {
            this.marked2 = true;
            this.marked = false;
            this.marked3 = false;
          }
          // <!-- phase 4 new cr partial payment module added by Kunal bansal on 29 june 2021 start -->
          else if (this.checkmagic == 2) {
            this.marked3 = true;
            this.marked2 = false;
            this.marked = false;
          }
          // end
          else {
            this.marked2 = false;                   //added by Kunal on 23 april 2020
            this.marked = true;
            this.marked3 = false;

          }

          // <!-- phase 4 new cr partial payment coupon apply module added by Kunal bansal on 29 july 2021 start -->

          if (this.cart.payment_text_prepaid.discount != 0 && this.cart.payment_text_cod.discount != 0 && this.cart.pay_partial.discount != 0) {     //added by Kunal on 23 march 2020
            this.checkdiscount = 1;

          }
          else {
            if (this.p_type == 0 && this.cart.payment_text_cod.discount != 0) {
              this.checkdiscount = 1
            }
            // else {
            //   this.checkdiscount = 0;
            // }
            if (this.p_type == 1 && this.cart.payment_text_prepaid.discount != 0) {
              this.checkdiscount = 1

            }
            // else {
            //   this.checkdiscount = 0;
            // }
            if (this.p_type == 2 && this.cart.pay_partial.discount != 0) {
              this.checkdiscount = 1
            }
            // else {
            //   this.checkdiscount = 0;
            //   alert(this.checkdiscount)
            // }

          }
          // end


          if (response.response.length != 0) {
            this.cart.product_list.forEach((key) => {
              this.productsellerid.push(key.product_sellers_id)
            })
            this.globals.cartcount = response.response.cart_count;
            this.email = response.response.address.email
            this.phonenumber = response.response.address.phone

          }
          else if (response.response.length == 0) {
            this.globals.cartcount = 0;
          }

          // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
          this.Buyerlisting = [];
          this.buyerfilterarray = [];
          for (let k = 0; k < response.response.referral_user.length; k++) {

            this.Buyerlisting.push({
              "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
              email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
              address: response.response.referral_user[k].address.user_address
            })
            this.buyerfilterarray.push({
              "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
              email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
              address: response.response.referral_user[k].address.user_address
            })
          }
          // ...end...
          // this.fetchbuyerlist();
          this.walletbalance = response.response.ledger_balance;
          // phase 6 added by kunal bansal on 10 june 2022 start
         if(response.remove_product==1){
          Swal.fire({
            // title: 'Warning',
            text:response.remove_message,
            backdrop:false

            // showCancelButton: true,

            // // cancelButtonText: 'No',
            // confirmButtonText: 'ok',
            // // reverseButtons: true
          }).then((result) => {
            if (result.value) {
              this.remove(response.item_id)
            }
          })
         }

        }
        // .....end....
        // else if (response.status == 104) {
        //   // Swal.fire(response.message)
        //   // added by kunal bansal on 02 june 2022 start
        //   Swal.fire({
        //     // title: 'Warning',
        //     text:response.message,
        //     backdrop:false

        //     // showCancelButton: true,

        //     // // cancelButtonText: 'No',
        //     // confirmButtonText: 'ok',
        //     // // reverseButtons: true
        //   }).then((result) => {
        //     if (result.value) {
        //       this.remove(response.id)
        //     }
        //   })
        //   // ...end...


        // }

        // logout of all devices when change password module added by Kunal bansal on 18jan2021
        else if (response.status == 401) {


        }
        else { Swal.fire(response.message); 0 }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
    else {
      let body = {};
      if (this.coupon == null || this.coupon == undefined || this.coupon == '') {
        body = {
          product_seller_id: this.buydata.sellerid,
          quantity: this.buydata.quantity
        }
      }
      else {
        body = {
          coupon: this.coupon,
          product_seller_id: this.buydata.sellerid,
          quantity: this.buydata.quantity,
          // added by Kunal bansal on 05 march 2021
          payment_flag: this.p_type,
           // phase 6 added by Kunal bansal on 27 june 2022 start
           serial_number:this.couponcode,
           // ...end....
        }
      }
      this.productsellerid.push(this.buydata.sellerid)
      this.authservice.apipost('buyNow/', body).subscribe((res: any) => {
        let response = res.json();
         if (response.status == 102 || response.status == 500 || response.status == 100) {
          this.hitapiforbuynow();
          // added by Kunal bansal on 09 march 2021 to add gap b/w 2 alerts start
          setTimeout(() => {
            Swal.fire(response.message);
          }, 1500);
          //  end
        }

        else if (response.status == 200) {
          if (this.coupon != null || this.coupon != undefined || this.coupon != '') {
            if(response.error==1)
            {
              Swal.fire(response.message);
            }
          }
          else if (response.response.product_list.length == 0) {

          }
          else {
            this.editquantity = response.response.product_list[0].quantity;
            this.editquantyproductsellerid = response.response.product_list[0].product_sellers_id;
            this.storage.store("direct_buy_data", {
              quantity: this.editquantity,
              sellerid: this.editquantyproductsellerid
            })
            this.buydata = this.storage.retrieve('direct_buy_data');
          }

          this.cart = response.response;
          this.codmessage = (response.response.payment_text_cod.cod_message);
          //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
          if (this.country == 'UAE') {
            this.Uaemodulecodmessage();
          }
          // ...end...

          //  added module if partial payment charges zero no need to show alert by Kunal bansal on 09 aug 2021 start
          this.partpaymentmessage = response.response.pay_partial.cod_message;
          // end

          this.email = response.response.address.email;
          this.phonenumber = response.response.address.phone;
          // coupon not showing when coming thorugh buy now at first  on cod fixed by Kunal bansal on 01 march 2021
          // added by Kunal bansal to add scenario api hit on evry tm radio button change on 04 march 2021 start
          if (this.p_type == 1) {
            if (this.p_type == 1 && this.cart.payment_text_prepaid.discount != 0) {
              this.checkdiscount = 1;
            }
            else {
              this.checkdiscount = 0;
              this.coupon = "";
              this.storage.store("coupon", "");
            }
          }
          // <!-- phase 4 new cr partial payment coupon apply module added by Kunal bansal on 29 july 2021 start -->
          else if (this.p_type == 2) {
            if (this.p_type == 2 && this.cart.pay_partial.discount != 0) {
              this.checkdiscount = 1;
            }
            else {
              this.checkdiscount = 0;
              this.coupon = "";
              this.storage.store("coupon", "");
            }
          }
          // end
          else {
            if (this.p_type == 0 && this.cart.payment_text_cod.discount != 0) {
              this.checkdiscount = 1;
            }
            else {
              this.checkdiscount = 0;
              this.coupon = "";
              this.storage.store("coupon", "");
            }
          }
          // end
          // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
          for (let k = 0; k < response.response.referral_user.length; k++) {

            this.Buyerlisting.push({
              "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
              email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
              address: response.response.referral_user[k].address.user_address
            })
            this.buyerfilterarray.push({
              "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
              email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
              address: response.response.referral_user[k].address.user_address
            })
          }
          // ...end...
          // this.fetchbuyerlist();
          this.walletbalance = response.response.ledger_balance;
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }

  }

  walletproceed() {
    let cartamount;
    if (this.p_type == 0) {
      cartamount = this.cart.payment_text_cod.amount_payble;
    }
    else if (this.p_type == 1) {
      cartamount = this.cart.payment_text_prepaid.amount_payble;
    }
    else {
      cartamount = this.cart.pay_partial.prepaid_amount;
    }

    if (this.walletactive == true) {
      // phase 5 my wallet scenario addedby Kunal bansal on 15 nov 2021 start
      if (Number(Math.sign(this.walletbalance - cartamount)) == -1) {

        this.Walletpayment();
      }
      else if (Number(Math.sign(this.walletbalance - cartamount)) == 1 || Number(Math.sign(this.walletbalance - cartamount)) == 0) {

        this.usewallet();
      }
      else {

        this.Walletpayment();
      }
    }
    else {
      this.makepayment();
    }
  }




  usewallet() {
    Swal.fire({
      title: 'Warning',
      text: "Order is going to be placed using Wallet balance " + this.walletbalance,
      showCancelButton: true,
      cancelButtonText: 'NO',
      confirmButtonText: 'YES',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
         // phase 5 place order via walley added by Kunal bansal on 24 nov 2021 start
        this.finalwalletpayment();
      }
    })
  }

  // phase 5 place order via walley added by Kunal bansal on 24 nov 2021 start
  finalwalletpayment(){
    this.authservice.apipost('payment/', {
      order_id: this.order_id,
      payment_flag: 3,
      coupon: this.coupon,   //added by Kunal bansal on 04 april 2020
       // phase 6 added by Kunal bansal on 27 june 2022 start
       serial_number:this.couponcode,
       // ...end....
      payment_mode:this.selectedpaymodeforwallet

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {
        Swal.fire(response.message);
        this.router.navigate(['/homepage/productlisting']);
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }
  // ...end...

  walletchange(event){
    if(event.target.checked==true){
      this.storage.store("walletactive",true);
    }
    else{
      this.storage.store("walletactive",false);
    }

    if(event.target.checked==true && this.p_type==0){
      this.handleChange(true,1)
    }
  }

  Walletpayment() {

    // phase 5 wallet implementation in ledger point by Kunal bansal on 22 oct 2021 start
    //online
    if (this.p_type == 1) {
      let remaining = this.walletbalance - Number(this.cart.payment_text_prepaid.amount_payble);
      let percentamount=this.Eolspecialcharge/100;
      let further=Math.abs(Number(remaining))*percentamount;
      Swal.fire({
        title: 'Warning',
        text: "Order is going to be placed using Wallet balance " + this.walletbalance + " remaining rs " + (Math.abs(remaining)+further) + " can be deducted from your account. Do you want to proceed ?",
        showCancelButton: true,
        cancelButtonText: 'NO',
        confirmButtonText: 'YES',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.authservice.apipost('getOrderId/', {
            amount: (Math.abs(remaining)+further) * 100,
            aversion: "1.0.1.5",

          }).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 400 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message);
            }
            else if (response.status == 200) {

              this.options = {
                //"key": "rzp_test_Xl0iS8BgLClxsP",
                //"key":"rzp_live_ubK3anJmWUsySw",
                "key": environment.razor_key,
                "amount": (Math.abs(remaining)+further) * 100,
                "name": "Payment to EOL ",
                "currency": "INR",
                // phase 5 razor pay dynamic payment mode handle from backend added by Kunal bansal on 13 oct 2021 start
                'method': this.paymentmethod,
                // ...end...
                "prefill": {
                  email: this.email,
                  contact: this.phonenumber,
                  //'method':'netbanking',
                },
                'handler': this.paymentCapture.bind(this),
                "order_id": response.response.Order_Id,
              }
              this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
              this.rzp1.open();
            }
            else { Swal.fire("Server Down"); }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet");
            }
          )

        }
      })
    }
    // ...end...
    // <!-- phase 4 new cr partial payment module added by Kunal bansal on 28 july 2021 start -->
    if (this.p_type == 2) {
      let remaining = this.walletbalance - Number(this.cart.pay_partial.prepaid_amount);
      let percentamount=this.Eolspecialcharge/100;
      let further= Math.abs(Number(remaining))*percentamount;
      Swal.fire({
        title: 'Warning',
        text: "Order is going to be placed using Wallet balance " + this.walletbalance + " remaining rs " + (Math.abs(remaining)+further) + " can be deducted from your account. Do you want to proceed ?",
        showCancelButton: true,
        cancelButtonText: 'NO',
        confirmButtonText: 'YES',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.authservice.apipost('getOrderId/', {
            amount: (Math.abs(remaining)+further) * 100,
            aversion: "1.0.1.5",

          }).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 400 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message);
            }
            else if (response.status == 200) {

              this.options = {
                //"key": "rzp_test_Xl0iS8BgLClxsP",
                //"key":"rzp_live_ubK3anJmWUsySw",
                "key": environment.razor_key,
                "amount": this.cart.pay_partial.prepaid_amount * 100,
                "name": "Payment to EOL ",
                "currency": "INR",
                // phase 5 razor pay dynamic payment mode handle from backend added by Kunal bansal on 13 oct 2021 start
                'method': this.paymentmethod,
                // ...end...
                "prefill": {
                  email: this.email,
                  contact: this.phonenumber,
                  //'method':'netbanking',
                },
                'handler': this.paymentCapture.bind(this),
                "order_id": response.response.Order_Id,
              }
              this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
              this.rzp1.open();
            }
            else { Swal.fire("Server Down"); }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet");
            }
          )
        }
      })
    }
    else if (this.p_type == 0) {//cod
      let remaining = this.walletbalance - Number(this.cart.payment_text_prepaid.amount_payble);
      Swal.fire({
        title: 'Warning',
        text: "Order is going to be placed using Wallet balance " + this.walletbalance + " remaining rs " + Math.abs(remaining) + " can be deducted from your account. Do you want to proceed ?",
        showCancelButton: true,
        cancelButtonText: 'NO',
        confirmButtonText: 'YES',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.authservice.apipost('payment/', {
            order_id: this.order_id,
            payment_flag: this.walletactive==false?this.p_type:3,
            coupon: this.coupon,   //added by Kunal bansal on 04 april 2020
 // phase 6 added by Kunal bansal on 27 june 2022 start
 serial_number:this.couponcode,
 // ...end....
          }).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 400 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message);
            }
            else if (response.status == 200) {
              Swal.fire(response.message);
              this.router.navigate(['/homepage/productlisting']);
            }
            else { Swal.fire("Server Down"); }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet");
            }
          )
        }
      })
    }

  }


  // ...end...

  mytransactio(){

    sessionStorage.setItem("tokentype","usertoken");
    this.router.navigate(['/homepage/transactions']);
  }

  fetchbuyerlist() {
    this.razorpayreplicamodes = [];
    this.trans.getpaymentmodes().subscribe(res => {
      let response = res.json();
      this.spinner.hide();
      if (response.status == 200) {
        console.log(response);
        this.paymentmethod = response.result.paymentmethod;
        for (let h = 0; h < response.result.list.length; h++) {
          if (response.result.list[h].name == "Card" && response.result.list[h].enable == true) {
// Dynamic payment mode price added by Kunal bansal on 06 dec 2021
this.Dynamicpaymentmodeprices.Card=response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "Card", "info": "Visa, MasterCard, RuPay & More", value: "card",amount:response.result.list[h].amount })
          }
          else if (response.result.list[h].name == "UPI" && response.result.list[h].enable == true) {
            this.Dynamicpaymentmodeprices.UPI=response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "UPI / QR", "info": "Google Pay, PhonePe & more", value: "upi",amount:response.result.list[h].amount  })
          }
         else  if (response.result.list[h].name == "NetBanking" && response.result.list[h].enable == true) {
          this.Dynamicpaymentmodeprices.NetBanking=response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "Netbanking", "info": "All Indian banks", value: "netbanking",amount:response.result.list[h].amount  })
          }
          else if (response.result.list[h].name == "Wallet" && response.result.list[h].enable == true) {
            this.Dynamicpaymentmodeprices.Wallet=response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "Wallet", "info": "Freecharge and PayZapp", value: "wallet",amount:response.result.list[h].amount  })
          }
          else if (response.result.list[h].name == "EMI" && response.result.list[h].enable == true) {
            this.Dynamicpaymentmodeprices.EMI=response.result.list[h].amount;
            this.razorpayreplicamodes.push({ "mode": "EMI", "info": "EMI via Credit & Debit Cards", value: "emi",amount:response.result.list[h].amount  })
          }
          else{

          }

        }
        console.log(this.Dynamicpaymentmodeprices)

      }
      else if (response.status == 401) {
        Swal.fire(response.message)
      }
      else {
        Swal.fire(response.message);
      }

    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  // ...end...

  // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 02 sep 2021 start -->
  agentbehalf(e) {
    // this.selectedbuyerdetail = [];

    if (e == 0) {
      this.agentbehalfactive = 0;
      // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 15 sep 2021 start -->
      this.storage.store("placeorderbuyerradio", "self");
      this.placeorderdisabled = false;
    }
    else {
      this.agentbehalfactive = 1;
      this.storage.store("walletactive",false);
      this.walletactive=false;
      // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 15 sep 2021 start -->
      this.storage.store("placeorderbuyerradio", "other");
      if (this.validateotpverify == false) {
        this.placeorderdisabled = true;
      }
      else {
        this.placeorderdisabled = false;
      }

    }
  }
  // end

  // phase 4 search place order on behalf of buyer added by Kunal bansal on 10 sep 2021 start -->
  setotptimer() {
    // myVar = setInterval(() => {
    //   if (this.otptimerminute <= 10) {
    //     let otpminute;

    //     otpminute = this.otptimerminute - 1;
    //     this.otptimerminute = "0" + otpminute;
    //   }
    //   else {
    //     this.otptimerminute = this.otptimerminute - 1;
    //   }

    //   if (this.otptimerminute == 0) {
    //     clearInterval(myVar);

    //   }
    // }, 60000);

  }

  setotptimersecond() {
    myVarsecond = setInterval(() => {


      if (this.otptimersecond <= 10) {
        let otp;

        otp = this.otptimersecond - 1;
        this.otptimersecond = "0" + otp;
      }
      else {
        this.otptimersecond = this.otptimersecond - 1;
      }
      if (this.otptimersecond == 0) {
        if (this.otptimerminute == 0) {
          this.otptimersecond = 60;
          clearInterval(myVar);
          this.otptimersecond = "0" + 0;
          clearInterval(myVarsecond);
          this.otpbuttondisabled = false;
          // setTimeout(() => {


          // }, 60000);


        }
        else {
          this.otptimersecond = 60;
          if (this.otptimerminute <= 10) {
            let otpminute;

            otpminute = this.otptimerminute - 1;
            this.otptimerminute = "0" + otpminute;
          }
          else {
            this.otptimerminute = this.otptimerminute - 1;
          }

        }


      }
    }, 1000);
  }
  // ...end....

  // phase 5 add otp timer module added by Kunal bansal on 14 sep 2021 start -->

  onsuggesionotpreset() {
    // let time = this.permanentotptimestore;
    this.otpvisible = false;
    this.otpactive = 0;
    // this.otptimerminute = time;
    // this.otptimersecond = 60;
    this.otpbuttondisabled = false;
    // clearInterval(myVar);
    // clearInterval(myVarsecond);

    // this.setotptimer();
    // this.setotptimersecond();
  }

  resetonoinitotp() {
    let time = this.permanentotptimestore;
    let second = this.permanentotpsecondstimestore
    this.otpvisible = true;
    this.otpactive = 1;
    this.otptimerminute = time;
    this.otptimersecond = second;
    this.otpbuttondisabled = true;
    clearInterval(myVar);
    clearInterval(myVarsecond);

    this.setotptimer();
    this.setotptimersecond();
  }

  lastseconds() {

    myVarsecond = setInterval(() => {


      if (this.otptimersecond <= 10) {
        let otp;

        otp = this.otptimersecond - 1;
        this.otptimersecond = "0" + otp;
      }
      else {
        this.otptimersecond = this.otptimersecond - 1;
      }
      if (this.otptimersecond == 0) {




        this.otptimersecond = "0" + 0;
        clearInterval(myVarsecond);
        this.otpbuttondisabled = false;





      }
    }, 1000);

  }


  resetotp(expiretime) {
    let time = this.permanentotptimestore;
    this.otpvisible = true;
    this.otpactive = 1;
    this.otptimerminute = time;
    this.otptimersecond = 60;
    this.otpbuttondisabled = true;
    clearInterval(myVar);
    clearInterval(myVarsecond);

    if (expiretime == 1) {
      this.lastseconds();
    }
    else {
      this.setotptimersecond();

  }
  }

  generateotp() {

    this.authservice.apipost('sendValidateOTP/', {
      "phone": this.selectedbuyerdetail[0].mobno,
      "email": this.selectedbuyerdetail[0].email,
      "country": this.cart.payment_text_cod.country,

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);

      }
      else if (response.status == 200) {
        console.log(response);
        this.storage.store("generateotpactive", 1)
        this.validateotpverify = false;
        let concatminute=response.expiry_time-1;
          if(response.expiry_time<10){
           this.otptimerminute = "0"+concatminute;
           this.permanentotptimestore = "0"+concatminute;
          }
          else{
           this.otptimerminute = response.expiry_time-1;
           this.permanentotptimestore = response.expiry_time-1;
          }
        this.resetotp(response.expiry_time);

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )


  }
  // ...end...

  validateotp() {

    if ($('#first').val().length == 0 && $('#second').val().length == 0 && $('#third').val().length == 0 && $('#fourth').val().length == 0) {
      Swal.fire("Please enter a valid OTP")
    }
    else {
      let emailotp = $('#first').val() + "" + $('#second').val() + "" + $('#third').val() + "" + $('#fourth').val();
      this.authservice.apipost('sendValidateOTP/', {
        "phone": this.selectedbuyerdetail[0].mobno,
        "email": this.selectedbuyerdetail[0].email,
        "country": this.cart.payment_text_cod.country,
        "otp": emailotp

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);

        }
        else if (response.status == 200) {
          console.log(response);
          this.storage.store("verifyotp", true);
          this.storage.store('placeorderdisabled', false);
          this.placeorderdisabled = false;
          this.otpvisible = false;
          this.validateotpverify = true;
          if (response.expiry_time) {
            let concatminute=response.expiry_time-1;
            if(response.expiry_time<10){
             this.otptimerminute = "0"+concatminute;
             this.permanentotptimestore = "0"+concatminute;
            }
            else{
             this.otptimerminute = response.expiry_time-1;
             this.permanentotptimestore = response.expiry_time-1;
            }
          }



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }


  // <!-- // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
  selectedsugession(event) {
    // phase 5 add otp timer module added by Kunal bansal on 13 sep 2021 start -->
    this.onsuggesionotpreset();
    // ...end...
    // let name = event.target.value;
    // let list = this.Buyerlisting.filter(x => x.name === name)[0];
    this.storage.store("verifyotp", false);
    this.storage.store("generateotpactive", 0);
    this.storage.store('placeorderdisabled', true);
    this.otpactive = 0;
    this.placeorderdisabled = true;
    this.validateotpverify = false;
    this.buyerlistactive = 0;

    this.selectedbuyerdetail = [{
      "storename": this.buyerfilterarray[event].storename,
      "mobno": this.buyerfilterarray[event].mobno,
      "address": this.buyerfilterarray[event].address,
      "email": this.buyerfilterarray[event].email
    }]
    this.storage.store("selectedbuyerdetail", this.selectedbuyerdetail);
    console.log(this.selectedbuyerdetail)
  }

  buyersea() {
    this.buyerfilterarray = [];
    for (let b = 0; b < this.Buyerlisting.length; b++) {
      if (this.Buyerlisting[b].buyernamephone.toLowerCase().includes(this.buyersearch.toLowerCase())) {
        this.buyerfilterarray.push(this.Buyerlisting[b])
        // console.log(this.buyerfilterarray)
      }
    }
    // console.log(this.buyerfilterarray)
  }
  // end


  // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 03 sep 2021 start -->
  agentcodeselect(agentindex) {
    this.otpactive = 0;
    let agent = agentindex.target.value;
    this.selectedbuyerdetail = [{
      "storename": this.Buyerlisting[agent].storename,
      "mobno": this.Buyerlisting[agent].mobno,
      "address": this.Buyerlisting[agent].address,
      "email": this.Buyerlisting[agent].email
    }]
    console.log(this.selectedbuyerdetail)
  }



  hitapiforbuynow() {
    this.authservice.apipost('buyNow/', {
      coupon: null,
      product_seller_id: this.buydata.sellerid,
      quantity: this.buydata.quantity,
       // phase 6 added by Kunal bansal on 27 june 2022 start
       serial_number:this.couponcode,
       // ...end....
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 102 || response.status == 500 || response.status == 100) {

        Swal.fire(response.message);
      }
      else if (response.status == 200) {
        if (response.response.product_list.length == 0) {

        }
        else {
          this.editquantity = response.response.product_list[0].quantity;
          this.editquantyproductsellerid = response.response.product_list[0].product_sellers_id;
          this.storage.store("direct_buy_data", {
            quantity: this.editquantity,
            sellerid: this.editquantyproductsellerid
          })
          this.buydata = this.storage.retrieve('direct_buy_data');
        }
        this.cart = response.response;
        this.email = response.response.address.email;
        this.phonenumber = response.response.address.phone;
        this.codmessage = (response.response.payment_text_cod.cod_message);
        //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
        if (this.country == 'UAE') {
          this.Uaemodulecodmessage();
        }
        // ...end...

        //  added module if partial payment charges zero no need to show alert by Kunal bansal on 09 aug 2021 start
        this.partpaymentmessage = response.response.pay_partial.cod_message;
        // end

        // added by Kunal bansal to add scenario api hit on evry tm radio button change on 05 march 2021 start
        if (this.p_type == 1) {
          if (this.p_type == 1 && this.cart.payment_text_prepaid.discount != 0) {
            this.checkdiscount = 1;
          }
          else {
            this.checkdiscount = 0;
            this.coupon = "";
            this.storage.store("coupon", "");
          }
        }
        // <!-- phase 4 new cr partial payment coupon apply module added by Kunal bansal on 29 july 2021 start -->
        else if (this.p_type == 2) {
          if (this.p_type == 2 && this.cart.pay_partial.discount != 0) {
            this.checkdiscount = 1;
          }
          else {
            this.checkdiscount = 0;
            this.coupon = "";
            this.storage.store("coupon", "");
          }
        }
        // end
        else {
          if (this.p_type == 0 && this.cart.payment_text_cod.discount != 0) {
            this.checkdiscount = 1;
          }
          else {
            this.checkdiscount = 0;
            this.coupon = "";
            this.storage.store("coupon", "");
          }
        }
        // end
        // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
        for (let k = 0; k < response.response.referral_user.length; k++) {

          this.Buyerlisting.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].address.user_address
          })
          this.buyerfilterarray.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].address.user_address
          })
        }
        // ...end...
        // this.fetchbuyerlist();
        this.walletbalance = response.response.ledger_balance;
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }


  // Edit Quantity module added in cart when user comes direct Buynow added by Kunal bansal on 09 april 2021 start
  cartquanity(event) {

    console.log(event)

    if (event.key) {
      return false
    }
    else {
      this.finalquantity = event.target.value;

    }


  }


  // Below validation added for custom input type number to allow only numbers by Kunal bansal on 15 april 2021 start
  cartquanityvalidate(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 39 || (event.keyCode >= 96 && event.keyCode <= 105)) {
      return true
    }
    else {
      return false
    }
  }
  // end

  increment(pid, quant, check) {
    if (check == 0) {
      this.editquantity = Number(quant);
    }
    else if (check == 2) {
      this.editquantity = quant;
    }
    else {
      this.editquantity = quant;
    }

    let body = {};
    if (this.coupon == null || this.coupon == undefined || this.coupon == '') {
      body = {
        product_seller_id: pid,
        quantity: this.editquantity,
        payment_flag: this.p_type,
      }
    }
    else {
      body = {
        coupon: this.coupon,
        product_seller_id: pid,
        quantity: this.editquantity,
        // added by Kunal bansal on 05 march 2021
        payment_flag: this.p_type,
         // phase 6 added by Kunal bansal on 27 june 2022 start
         serial_number:this.couponcode,
         // ...end....
      }
    }
    this.productsellerid.push(pid);

    this.authservice.apipost('buyNow/', body).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 102 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
        this.hitapiforbuynow();

        // setTimeout(() => {
        //   Swal.fire(response.message);
        // }, 1500);

        // this.cart=response.response;

      }
      else if (response.status == 200) {
        if (response.response.product_list.length == 0) {

        }
        else {
          this.editquantity = response.response.product_list[0].quantity;
          this.editquantyproductsellerid = response.response.product_list[0].product_sellers_id;
          this.storage.store("direct_buy_data", {
            quantity: this.editquantity,
            sellerid: this.editquantyproductsellerid
          })
          this.buydata = this.storage.retrieve('direct_buy_data');
        }
        this.cart = response.response;
        this.codmessage = (response.response.payment_text_cod.cod_message);
        //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
        if (this.country == 'UAE') {
          this.Uaemodulecodmessage();
        }
        // ...end...

        //  added module if partial payment charges zero no need to show alert by Kunal bansal on 09 aug 2021 start
        this.partpaymentmessage = response.response.pay_partial.cod_message;
        // end

        this.email = response.response.address.email;
        this.phonenumber = response.response.address.phone;
        // coupon not showing when coming thorugh buy now at first  on cod fixed by Kunal bansal on 01 march 2021
        // added by Kunal bansal to add scenario api hit on evry tm radio button change on 04 march 2021 start
        if (this.p_type == 1) {
          if (this.p_type == 1 && this.cart.payment_text_prepaid.discount != 0) {
            this.checkdiscount = 1;
          }
          else {
            this.checkdiscount = 0;
            this.coupon = "";
            this.storage.store("coupon", "");
          }
        }
        // <!-- phase 4 new cr partial payment coupon apply module added by Kunal bansal on 29 july 2021 start -->
        else if (this.p_type == 2) {
          if (this.p_type == 2 && this.cart.pay_partial.discount != 0) {
            this.checkdiscount = 1;
          }
          else {
            this.checkdiscount = 0;
            this.coupon = "";
            this.storage.store("coupon", "");
          }
        }
        // end
        else {
          if (this.p_type == 0 && this.cart.payment_text_cod.discount != 0) {
            this.checkdiscount = 1;
          }
          else {
            this.checkdiscount = 0;
            this.coupon = "";
            this.storage.store("coupon", "");
          }
        }
        // end
        // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
        for (let k = 0; k < response.response.referral_user.length; k++) {

          this.Buyerlisting.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].address.user_address
          })
          this.buyerfilterarray.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].address.user_address
          })
        }
        // ...end...
        // this.fetchbuyerlist();
        this.walletbalance = response.response.ledger_balance;
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }
  // end

  // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
  buyerlistingactive() {
    if (this.buyerlistactive == 1) {
      this.buyerlistactive = 0;
    }
    else {
      this.buyerlistactive = 1;
    }

  }
  // ....end....

  // Edit Quantity module added in cart when user comes through addtocart added by Kunal bansal on 09 april 2021 start
  incrementcart(pid, quant, check) {
    console.log(this.cart.product_list)
    if (check == 0) {
      this.editquantity = Number(quant);
    }
    else if (check == 2) {
      this.editquantity = quant;
    }
    else {
      this.editquantity = quant;
    }

    let body = {};
    if (this.coupon != null || this.coupon != undefined || this.coupon != '') {
      body = {
        coupon: this.coupon,
         // phase 6 added by Kunal bansal on 27 june 2022 start
         serial_number:this.couponcode,
         // ...end....
        payment_flag: this.p_type,
        order_item_id: pid,
        quantity: this.editquantity
      }

    }

    this.authservice.apipost('getCartProduct/', body).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 102 || response.status == 500) {
        this.checkmagic = this.storage.retrieve('checkcodpre')    //added by Kunal on 23 april 2020
        if (this.checkmagic == 0) {
          this.marked2 = true;
          this.marked = false;
          this.marked3 = false;
        }
        else if (this.checkmagic == 2) {
          this.marked3 = true;
          this.marked2 = false;
          this.marked = false;
        }
        else {
          this.marked2 = false;                   //added by Kunal on 23 april 2020
          this.marked = true;
          this.marked3 = false;

        }
        this.hitapi();   //added by Kunal on 25 march 2020
        // added by Kunal bansal on 09 march 2021 to add gap b/w 2 alerts start
        setTimeout(() => {
          Swal.fire(response.message);
        }, 1500);
        // end

        // added by kunal bansal on 03 march 2021
        // added by Kunal bansal to add scenario api hit on evry tm radio button change on 04 march 2021 start
        this.storage.store("coupon", "");
        this.coupon = "";
        // end
      }
      else if (response.status == 100) {

      }
      else if (response.status == 200) {
        // modified by Kunal bansal on 15feb2021 to fix cart count not updated issue
        this.cart = response.response;
        this.globals.cartcount = response.response.cart_count;
        this.codmessage = (response.response.payment_text_cod.cod_message);
        //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
        if (this.country == 'UAE') {
          this.Uaemodulecodmessage();
        }
        // ...end...

        //  added module if partial payment charges zero no need to show alert by Kunal bansal on 09 aug 2021 start
        this.partpaymentmessage = response.response.pay_partial.cod_message;
        // end



        this.p_type = this.storage.retrieve('checkcodpre')
        if (this.p_type == null) {   //Added by Kunal on 01 may 2020
          this.p_type = 1;

        }

        this.checkmagic = this.storage.retrieve('checkcodpre')   //added by Kunal on 23 april 2020
        if (this.cart.payment_text_cod.discount != 0) {
          this.checkdiscount = 1;
        }
        else {
          this.checkdiscount = 0;
        }//added by Kunal on 23 april 2020
        if (this.checkmagic == 0) {
          this.marked2 = true;
          this.marked = false;
          this.marked3 = false;
        }
        else if (this.checkmagic == 2) {
          this.marked3 = true;
          this.marked2 = false;
          this.marked = false;
        }
        else {
          this.marked2 = false;                   //added by Kunal on 23 april 2020
          this.marked = true;
          this.marked3 = false;

        }



        if (this.cart.payment_text_prepaid.discount != 0) {     //added by Kunal on 23 march 2020
          this.checkdiscount = 1;
        }
        if (response.response.length != 0) {
          this.cart.product_list.forEach((key) => {
            this.productsellerid.push(key.product_sellers_id)
          })
          this.globals.cartcount = response.response.cart_count;
          this.email = response.response.address.email
          this.phonenumber = response.response.address.phone

        }
        else if (response.response.length == 0) {
          this.globals.cartcount = 0;
        }
        // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
        for (let k = 0; k < response.response.referral_user.length; k++) {

          this.Buyerlisting.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].address.user_address
          })
          this.buyerfilterarray.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].address.user_address
          })
        }
        // ...end...
        // this.fetchbuyerlist();
        this.walletbalance = response.response.ledger_balance;
         // phase 6 added by kunal bansal on 10 june 2022 start
         if(response.remove_product==1){
          Swal.fire({
            // title: 'Warning',
            text:response.remove_message,
            backdrop:false

            // showCancelButton: true,

            // // cancelButtonText: 'No',
            // confirmButtonText: 'ok',
            // // reverseButtons: true
          }).then((result) => {
            if (result.value) {
              this.remove(response.item_id)
            }
          })
         }
      }
      // else if (response.status == 104) {
      //   // Swal.fire(response.message)
      //    // added by kunal bansal on 02 june 2022 start
      //    Swal.fire({
      //     // title: 'Warning',
      //     text:response.message,
      //     backdrop:false

      //     // showCancelButton: true,

      //     // // cancelButtonText: 'No',
      //     // confirmButtonText: 'ok',
      //     // // reverseButtons: true
      //   }).then((result) => {
      //     if (result.value) {
      //       this.remove(response.id)
      //     }
      //   })
      //   // ...end...
      //   // this.removalmessage = response.message;
      //   // this.remove(response.id)
      // }
      // logout of all devices when change password module added by Kunal bansal on 18jan2021
      else if (response.status == 401) {


      }
      else { Swal.fire(response.message); 0 }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }
  // end



  hitapi()      //new function added by Kunal bansal on 25 march 2020
  {

    this.authservice.apipost('getCartProduct/', { coupon: null, payment_flag: this.p_type,serial_number:null }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);

      }
      else if (response.status == 200) {

        this.cart = response.response;


        if (this.cart.payment_text_prepaid.discount != 0) {
          this.checkdiscount = 1;
        }
        if (response.response.length != 0) {
          this.cart.product_list.forEach((key) => {
            this.productsellerid.push(key.product_sellers_id)
          })
          this.globals.cartcount = response.response.cart_count;
          this.codmessage = (response.response.payment_text_cod.cod_message);
          //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
          if (this.country == 'UAE') {
            this.Uaemodulecodmessage();
          }
          // ...end...

          //  added module if partial payment charges zero no need to show alert by Kunal bansal on 09 aug 2021 start
          this.partpaymentmessage = response.response.pay_partial.cod_message;
          // end
          this.email = response.response.address.email
          this.phonenumber = response.response.address.phone

        }
        else if (response.response.length == 0) {
          this.globals.cartcount = 0;
        }
        // phase 4 search place order on behalf of buyer added by Kunal bansal on 09 sep 2021 start -->
        for (let k = 0; k < response.response.referral_user.length; k++) {

          this.Buyerlisting.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].name + ", " + response.response.referral_user[k].address.user_address
          })
          this.buyerfilterarray.push({
            "name": response.response.referral_user[k].name, "mobno": response.response.referral_user[k].phone, buyernamephone: response.response.referral_user[k].store_name + " " + response.response.referral_user[k].phone,
            email: response.response.referral_user[k].email, "storename": response.response.referral_user[k].store_name,
            address: response.response.referral_user[k].address.user_address
          })
        }
        // ...end...
        // this.fetchbuyerlist();
        this.walletbalance = response.response.ledger_balance;
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }


  // remain on cart after removing coupon module added by Kunal bansal on 06 jan 2021

  removecoupon() {
    this.storage.clear('coupon');
    // added by Kunal bansal on 15 jan 2021 for buy now cross module
// phase 6 added by Kunal bansal on 27 june 2022 start
this.storage.clear("couponcode")
// ...end....
    this.ngOnInit();
  }


  couponscreen() {

    //console.log('navigate',this.productsellerid)
    this.storage.store("p_seller_id", JSON.stringify(this.productsellerid))
    this.storage.store("paymenttype", JSON.stringify(this.p_type))//modified by Kunal 11/march/2020 send data to coupon_components
    this.storage.store('cartotptimerstore', this.otptimerminute);
    this.storage.store('cartotptimersecondstore', this.otptimersecond);
    this.router.navigate(['/homepage/applyCoupon']);

    // alert(this.p_type)

  }

  ngOnDestroy() {
    this.storage.clear('coupon');
    clearInterval(myVarsecond);
    clearInterval(myVar);

    //  Modifed by Kunal bansal on 15 march 2021 to add Phase 3 new cr add address while place order scenario
    if (window.location.pathname == "/homepage/applyCoupon" || window.location.pathname == "/homepage/addAddress" || window.location.pathname == "/homepage/changeaddrss") {
      this.p_type = this.storage.retrieve('checkcodpre');



    }
    else {
      this.storage.clear("walletactive");
      this.storage.store('checkcodpre', 1)
      this.storage.clear('cart_from');
      // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 15 sep 2021 start -->
      this.storage.store("placeorderbuyerradio", "self");
      this.storage.clear("selectedbuyerdetail");
      // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 20 sep 2021 start -->
      this.storage.store("verifyotp", false);
      this.storage.store("generateotpactive", 0)

    }

  }

  remove(id) {
    this.authservice.apidelete('addToCart/', '?order_id=' + id).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {
        // added by Kunal bansal on 15feb2021 to fix cart count not updated issue
        if (this.removalmessage == undefined) {
          Swal.fire(response.message);
        }
        else {
          Swal.fire(this.removalmessage)
        }
        this.ngOnInit();
        //   if(this.removalmessage==undefined){
        //     Swal.fire(response.message);
        //   }
        //   else{
        //     Swal.fire(this.removalmessage)
        //   }

        //  setTimeout(() => {
        //    location.reload();
        //  }, 3000);

      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }

  shop() {
    this.router.navigate(['/homepage/productListing']);
  }

  //  added module if cod category charges zero no need to show alert by Kunal bansal on 24 aug 2021
  Uaemodulecodmessage() {

    if (this.codmessage == "0.0₹" || this.codmessage == "0%" || this.codmessage == "0₹") {
    }
    else {

      if (this.checklanguage == null || this.checklanguage == "English") {
        Swal.fire("Extra&nbsp" + this.codmessage + "&nbspwill be charged if order is placed via POD")
      }
      else if (this.checklanguage == "Hindi") {
        Swal.fire("सीओडी पर अतिरिक्त &nbsp" + this.codmessage + "&nbspशुल्क लिया जाएगा")
      }
      else {
        Swal.fire("સીઓડી પર વધારાના &nbsp" + this.codmessage + "&nbsp નો શુલ્ક લેવામાં આવશે")
      }
    }
  }

  handleChange(event, x) {

    if (x == 0) {
      this.storage.store('checkcodpre', 0)
      //  added module if cod category charges zero no need to show alert by Kunal bansal on 15 jan 2021
      if (this.codmessage == "0.0₹" || this.codmessage == "0%" || this.codmessage == "0₹") {
      }
      else {
        if (this.checklanguage == null || this.checklanguage == "English") {
          Swal.fire("Extra&nbsp" + this.codmessage + "&nbspwill be charged if order is placed via POD")
        }
        else if (this.checklanguage == "Hindi") {
          Swal.fire("सीओडी पर अतिरिक्त &nbsp" + this.codmessage + "&nbspशुल्क लिया जाएगा")
        }
        else {
          Swal.fire("સીઓડી પર વધારાના &nbsp" + this.codmessage + "&nbsp નો શુલ્ક લેવામાં આવશે")
        }
      }
      this.p_type = 0;
      // added by Kunal bansal to add scenario api hit on evry tm radio button change on 04 march 2021 start
      // <!-- phase 4 new cr partial payment coupon apply module added by Kunal bansal on 29 july 2021 start -->
      if (this.cart.payment_text_cod.discount != 0 && this.cart.payment_text_prepaid.discount != 0 && this.cart.pay_partial.discount != 0) {
        this.checkdiscount = 1;

      }

      else if (this.cart.payment_text_cod.discount != 0) {  //Modified by Kunal on 23 april 2020
        this.checkdiscount = 1;
        this.storage.store('coupon', "");
        this.storage.store('cartotptimerstore', this.otptimerminute);
        this.storage.store('cartotptimersecondstore', this.otptimersecond);
        this.ngOnInit();

      }
      else {
        this.checkdiscount = 0;
        this.couponcode='';
        this.storage.clear('couponcode')
        this.storage.store('cartotptimerstore', this.otptimerminute);
        this.storage.store('cartotptimersecondstore', this.otptimersecond);
        this.ngOnInit();
      }
      // end
    }
    if (x == 1) {

      this.storage.store('checkcodpre', 1)
      // added by Kunal bansal to add scenario api hit on evry tm radio button change on 04 march 2021 start
      // <!-- phase 4 new cr partial payment coupon apply module added by Kunal bansal on 29 july 2021 start -->
      if (this.cart.payment_text_prepaid.discount != 0 && this.cart.payment_text_cod.discount != 0 && this.cart.pay_partial.discount != 0) {  //Modified by Kunal on 23 april 2020
        this.checkdiscount = 1;
      }
      else if (this.cart.payment_text_prepaid.discount != 0) {  //Modified by Kunal on 04 march 2021
        this.checkdiscount = 1;
        this.storage.store('coupon', "");

        this.storage.store('cartotptimerstore', this.otptimerminute);
        this.storage.store('cartotptimersecondstore', this.otptimersecond);
        this.ngOnInit();

      }
      else {
        this.checkdiscount = 0;
        this.couponcode='';
        this.storage.clear('couponcode')
        this.storage.store('cartotptimerstore', this.otptimerminute);
        this.storage.store('cartotptimersecondstore', this.otptimersecond);
        this.ngOnInit();
      }
      // end
      this.p_type = 1;
    }
    // <!-- phase 4 new cr partial payment coupon apply module added by Kunal bansal on 29 july 2021 start -->
    if (x == 2) {
      this.storage.store("walletactive",false);
      this.walletactive=false;
      this.storage.store('checkcodpre', 2)
      //  added module if partial payment charges zero no need to show alert by Kunal bansal on 09 aug 2021
      if (this.partpaymentmessage == "0.0₹" || this.partpaymentmessage == "0%" || this.partpaymentmessage == "0₹") {
      }
      else {
        if (this.checklanguage == null || this.checklanguage == "English") {
          Swal.fire("POD Extra&nbsp" + this.partpaymentmessage + "&nbspwill be charged if order is placed via Part Payment")
        }
        else if (this.checklanguage == "Hindi") {
          Swal.fire("सीओडी पर अतिरिक्त &nbsp" + this.partpaymentmessage + "&nbspशुल्क लिया जाएगा")
        }
        else {
          Swal.fire("સીઓડી પર વધારાના &nbsp" + this.partpaymentmessage + "&nbsp નો શુલ્ક લેવામાં આવશે")
        }
      }



      if (this.cart.payment_text_prepaid.discount != 0 && this.cart.payment_text_cod.discount != 0 && this.cart.pay_partial.discount != 0) {
        this.checkdiscount = 1;
      }
      else if (this.cart.pay_partial.discount != 0) {

        this.checkdiscount = 1;
        this.storage.store('coupon', "");
        this.storage.store('cartotptimerstore', this.otptimerminute);
        this.storage.store('cartotptimersecondstore', this.otptimersecond);
        this.ngOnInit();

      }
      else {
        this.checkdiscount = 0;
        this.couponcode='';
        this.storage.clear('couponcode')
        this.storage.store('cartotptimerstore', this.otptimerminute);
        this.storage.store('cartotptimersecondstore', this.otptimersecond);
        this.ngOnInit();
      }
      // end
      this.p_type = 2;
    }
  }

  // Agent code validate api implemented on 17 march 2021 by Kunal bansal start
  validateagent() {
    if (this.agentcode.trim() == "" || this.agentcode.length == 0) {
      // Swal.fire("agent code not be empty");
      this.agentcode = "";
      Swal.fire('Agent code is not valid');

    }
    else {
      this.authservice.apipost('validateAgentCode/', {
        agent_code: this.agentcode

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
          this.agentcode = "";
        }
        else if (response.status == 200) {
          // Swal.fire(response.message)
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

  }
  // end

  // <!-- phase 3 new cr selecting or add address module added by Kunal bansal on 15 march 2021 start-->
  addaddress() {
    // this.modalService.dismissAll();
    this.storage.store("goingthroughgeneratepickup", 2);
     // phase 6 added by Kunal bansal on 13 may 2022 start
     this.storage.store("address_type",3)
     // ...end...
    this.modalService.dismissAll();
    this.router.navigate(['/homepage/addAddress'])
  }

  changeaddss(name, mob, add1, add2, land, pin, city, state, id) {

    this.storage.store("goingthroughgeneratepickup", 2);
     // phase 6 added by Kunal bansal on 13 may 2022 start
     this.storage.store("address_type",3)
     // ...end...
    let storeaddress = {
      name, mob, add1, add2, land, pin, city, state, id
    }
    this.storage.store("storedadd", storeaddress);
    this.storage.store("fullname", name)
    this.storage.store("mobile", mob),
      this.storage.store("Add1", add1)
    this.storage.store("Add2", add2)
    this.storage.store("land", land)
    this.storage.store('pincode1', pin)
    this.storage.store('city1', city)
    this.storage.store('state1', state)
    this.modalService.dismissAll();
    this.router.navigate(['/homepage/changeaddrss']);

  }

  markdefaultnewaddress(e, id) {
    this.authservice.apipost('Address/?flag=2', {
      adid: id
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // Swal.fire(response.message);
        //this.router.navigate(['/homepage/profile']);
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  selectadd(selectaddress) {
    this.authservice.apiget('Profile/?flag=1').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.seladdress = response.response.Address[2].address_list;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    this.modalService.open(selectaddress, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      // this.modalService.open(pickup, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      //       this.closeResult = `Closed with: ${result}`;
      //     }, (reason) => {
      //       this.dispatchtype = 1;

      //       this.closeResult = `Dismissed `;
      //     });
      this.closeResult = `Dismissed `;
    });

  }

  submitaddress(razorpayreplica) {
    this.modalService.dismissAll();
    this.placeorder(razorpayreplica);
  }
  // end
  closeaddress() {
    this.modalService.dismissAll();
  }

  placeorder(razorpayreplica) {
    // directly buy module not working fixed by kunal bansal on 08 june 2020
    if (this.cart_from != 1) {//multiple
      let body = {};
      if (this.agentbehalfactive == 1) {
        if (this.selectedbuyerdetail == null || this.selectedbuyerdetail.length == 0) {
          Swal.fire("Please select buyer")
        }
        body = {
          request_flag: 0,
          payment_flag: this.walletactive==false?this.p_type:3,
          coupon: this.coupon,
           // phase 6 added by Kunal bansal on 27 june 2022 start
           serial_number:this.couponcode,
           // ...end....
          agent_code: this.agentcode,
          // phase 5 add otp timer module added by Kunal bansal on 14 sep 2021 start -->
          phone: this.selectedbuyerdetail[0].mobno,
          email: this.selectedbuyerdetail[0].email
        }
      }
      else {
        body = {
          request_flag: 0,
          payment_flag: this.walletactive==false?this.p_type:3,
          coupon: this.coupon,
           // phase 6 added by Kunal bansal on 27 june 2022 start
           serial_number:this.couponcode,
           // ...end....
          agent_code: this.agentcode
        }
      }

      this.authservice.apipost('makePayment/', body).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          let route = this.router;
              if(response.message==this.productNotAvailable) {
                this.authservice.apipost('getCartProduct/', { coupon: null, payment_flag: this.p_type,serial_number:null }).subscribe((res: any) => {
                  let response = res.json();
                if (response.status == 400 || response.status == 500 || response.status == 100) {
                  Swal.fire(response.message);
                }
                else if (response.status == 200) {

                  response.response.product_list.map(product =>{
                  this.authservice.apidelete('addToCart/', '?order_id=' + product.order_items_id).subscribe((res: any) => {})
                  })
                }
                })
                Swal.fire(response.message).then(function(){route.navigate(['/homepage/productListing'])})
              }else
              {
                Swal.fire(response.message)
              }
        }
        else if (response.status == 200) {
          this.order_id = response.order_id
          // this.makepayment();
          // phase 5 my wallet added by Kunal bansal on 24 nov 2021 start
          let cartamount;
          if (this.p_type == 0) {
            cartamount = this.cart.payment_text_cod.amount_payble;
          }
          else if (this.p_type == 1) {
            cartamount = this.cart.payment_text_prepaid.amount_payble;
          }
          else {
            cartamount = this.cart.pay_partial.prepaid_amount;
          }


          if (this.walletactive == true && (Number(Math.sign(this.walletbalance - cartamount)) == 1 || Number(Math.sign(this.walletbalance - cartamount)) == 0)) {

            this.walletproceed();
          }
          else if(this.p_type==1 || this.p_type==2) {

            this.razorpayreplica(razorpayreplica);
          }
          else{
            this.makepayment();
          }
          // ...end...

        }
        else { Swal.fire(response.message); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
    else {//single
      let body = {};
      if (this.agentbehalfactive == 1) {
        if (this.selectedbuyerdetail == null || this.selectedbuyerdetail.length == 0) {
          Swal.fire("Please select buyer")
        }
        body = {
          request_flag: 1,
          product_seller_id: this.cart.product_list[0].product_sellers_id,
          quantity: this.cart.product_list[0].quantity,
          payment_flag: this.walletactive==false?this.p_type:3,
          coupon: this.coupon,
           // phase 6 added by Kunal bansal on 27 june 2022 start
           serial_number:this.couponcode,
           // ...end....
          agent_code: this.agentcode,
          // phase 5 add otp timer module added by Kunal bansal on 14 sep 2021 start -->
          phone: this.selectedbuyerdetail[0].mobno,
          email: this.selectedbuyerdetail[0].email
          // ...end...

        }
      }

      else {
        body = {
          request_flag: 1,
          product_seller_id: this.cart.product_list[0].product_sellers_id,
          quantity: this.cart.product_list[0].quantity,
          payment_flag:this.walletactive==false?this.p_type:3,
          coupon: this.coupon,
           // phase 6 added by Kunal bansal on 27 june 2022 start
           serial_number:this.couponcode,
           // ...end....
          agent_code: this.agentcode,

        }
      }

      this.authservice.apipost('makePayment/', body).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {
          this.order_id = response.order_id
          // this.makepayment();
            // phase 5 my wallet added by Kunal bansal on 24 nov 2021 start
            let cartamount;
            if (this.p_type == 0) {
              cartamount = this.cart.payment_text_cod.amount_payble;
            }
            else if (this.p_type == 1) {
              cartamount = this.cart.payment_text_prepaid.amount_payble;
            }
            else {
              cartamount = this.cart.pay_partial.prepaid_amount;
            }


            if (this.walletactive == true && (Number(Math.sign(this.walletbalance - cartamount)) == 1 || Number(Math.sign(this.walletbalance - cartamount)) == 0)) {

              this.walletproceed();
            }
            else if(this.p_type==1 || this.p_type==2) {

              this.razorpayreplica(razorpayreplica);
            }
            else{
              this.makepayment();
            }
            // ...end...
        }
        else { Swal.fire(response.message); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
  }

  // phase 5 new cr add money in wallet razor pay replica added by Kunal bansal on 02 nov 2021 start
  razorpayreplica(razorpayreplica) {
    this.modalService.open(razorpayreplica, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed `;
    });
    this.fetchbuyerlist()

  }

  // ...end...



  makepayment() {
    // phase 5 wallet implementation in ledger point by Kunal bansal on 22 oct 2021 start
    //online
    // Dynamic payment mode price added by Kunal bansal on 06 dec 2021
    let percentamount=this.Eolspecialcharge/100;
    let further=Number(this.cart.payment_text_prepaid.amount_payble)*percentamount;
    // ...end...
    if (this.p_type == 1) {
      this.authservice.apipost('getOrderId/', {
        amount: (this.cart.payment_text_prepaid.amount_payble+further) * 100,
        aversion: "1.0.1.5",

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {

          this.options = {

            "key": environment.razor_key,
            "amount": (this.cart.payment_text_prepaid.amount_payble+further) * 100,
            "name": "Payment to EOL",
            "currency": "INR",

            'method': this.paymentmethod,

            "prefill": {
              email: this.email,
              contact: this.phonenumber,

            },
            'handler': this.paymentCapture.bind(this),
            "order_id": response.response.id,

          }

          this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )


    }
    // ...end...
    // <!-- phase 4 new cr partial payment module added by Kunal bansal on 28 july 2021 start -->
    if (this.p_type == 2) {

      let percentamount=this.Eolspecialcharge/100;
      let further=Number(this.cart.pay_partial.prepaid_amount)*percentamount;
      this.authservice.apipost('getOrderId/', {
        amount: (this.cart.pay_partial.prepaid_amount+further) * 100,
        aversion: "1.0.1.5",

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {

          this.options = {
            //"key": "rzp_test_Xl0iS8BgLClxsP",
            //"key":"rzp_live_ubK3anJmWUsySw",
            "key": environment.razor_key,
            "amount": (this.cart.pay_partial.prepaid_amount+further)* 100,
            "name": "Payment to EOL ",
            "currency": "INR",
            // phase 5 razor pay dynamic payment mode handle from backend added by Kunal bansal on 13 oct 2021 start
            'method': this.paymentmethod,
            // ...end...
            "prefill": {
              email: this.email,
              contact: this.phonenumber,
              //'method':'netbanking',
            },
            'handler': this.paymentCapture.bind(this),
            "order_id": response.response.Order_Id,
          }
          this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
          this.rzp1.open();
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
    else if (this.p_type == 0 ) {//cod
      this.authservice.apipost('payment/', {
        order_id: this.order_id,
        payment_flag: this.walletactive==false?this.p_type:3,
        coupon: this.coupon,   //added by Kunal bansal on 04 april 2020
         // phase 6 added by Kunal bansal on 27 june 2022 start
         serial_number:this.couponcode,
         // ...end....
        payment_mode:"POD"

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {
          Swal.fire(response.message);
          this.router.navigate(['/homepage/productlisting']);
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }

  }

  paymentCapture(response) {
    console.log(response)

    this.authservice.apipost('payment/', {
      coupon: this.coupon,   //added by Kunal bansal on 21 march 2020
       // phase 6 added by Kunal bansal on 27 june 2022 start
       serial_number:this.couponcode,
       // ...end....
      razor_payment_id: response.razorpay_payment_id,
      order_id: this.order_id,
      payment_flag: this.walletactive==false?this.p_type:3,
      razor_order_id: response.razorpay_order_id,

      payment_mode:this.p_type==2?"Part Payment":this.selectedpaymodeforwallet
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {

        Swal.fire(response.message);
        this.ngZone.run(() => this.router.navigate(['/homepage/productlisting'])).then();

      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }

  closeall() {
    this.modalService.dismissAll();
    this.ngOnInit();
  }

  changepopup(changep) {
    this.authservice.apiget('Profile/?flag=1').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {

        this.address = response.response.Address[2].address_list;

        this.modalService.open(changep, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed `;
        });

      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }

  markdefault(e, id) {
    this.authservice.apipost('Address/?flag=2', {
      adid: id
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {
        //this.router.navigate(['/homepage/profile']);
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }


  openrazorpay(){
    let cartamount;
    if (this.p_type == 0) {
      cartamount = this.cart.payment_text_cod.amount_payble;
    }
    else if (this.p_type == 1) {
      cartamount = this.cart.payment_text_prepaid.amount_payble;
    }
    else {
      cartamount = this.cart.pay_partial.prepaid_amount;
    }

    if (this.walletactive == true) {
      // phase 5 my wallet scenario addedby Kunal bansal on 15 nov 2021 start
      if (Number(Math.sign(this.walletbalance - cartamount)) == -1) {
        this.Walletpayment();
      }
      else if (Number(Math.sign(this.walletbalance - cartamount)) == 1 || Number(Math.sign(this.walletbalance - cartamount)) == 0) {
        this.usewallet();
      }
      else {
        this.Walletpayment();
      }
    }
    else {
      this.makepayment();
    }

  }

 // phase 6 code shrtening and optimization added by kunal bansal 25 may 2022 start

 selectpayment(event:any){

  this.Eolspecialcharge=event.Eolspecialcharge;
  this.selectedpaymodeforwallet=event.selectedpaymodeforwallet;
  this.paymentmethod = event.paymentmethod;
  console.log(event);
  this.openrazorpay();
}

}

