import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css','../../assets/Newwebfiles/style.css']
})
export class TermsConditionsComponent implements OnInit {

  contents:any={};
  terms:any=[];

  constructor(private server: DataService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.gettermcontent();
    // this.getTerms();
  }

  private gettermcontent() {
    this.server.gettermcontent().then((response: any) => {
      this.contents =response.termcontant;
      this.terms =response.data;
    });
  }

  // private getTerms() {
  //   this.server.getTerms().then((response: any) => {
  //     this.terms = response.data;
  //   });
  // }

}
