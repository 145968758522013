import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-ask-now',
  templateUrl: './ask-now.component.html',
  styleUrls: ['./ask-now.component.css']
})
export class AskNowComponent implements OnInit {
  querytype: string = '1';
  brands: any[];
  categories: any[];
  askform: FormGroup;
  searchlistactive:number=0;
  checkradio:number=1;
  lastlevelcategory:any[];
  isChecked:any;
  isCheckedName:any[];
  childindex:number;
 childcategoryid:number;
 childcategoryid2:number;
 childcategoryid3:number;
 

ordernum:any[];
selectedItems:any[];
dropdownSettings3={};


  constructor(private formBuilder: FormBuilder, private storage: SessionStorageService, private modalService: NgbModal,private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
this.childcategory();
this.authservice.orderapiget('static_Filter_Data/?rtype=sell_buy').subscribe((res: any) => {
  let response = res.json();
  if (response.status == 400 || response.status == 500 || response.status == 100) {
    // Swal.fire(response.message)
  }
  else if (response.status == 200) {
    // alert(JSON.stringify(response.response.buying_list))
    this.ordernum=response.response;
   }
  else { Swal.fire("Server Down") }
}
  , err => {
    this.spinner.hide();
    Swal.fire("Connect to the Internet")
  }
)
    // no product found radio switch module added by Kunal bansal on 24 august 2020 start
    this.searchlistactive = this.storage.retrieve('missproduct');
    if(this.searchlistactive==2){
      this.checkradio=2;
      this.querytype='3';
    }
    else{
      this.checkradio=1;
    }

    this.dropdownSettings3 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text:"Select",
    
      labelKey:"ordernumber",
      classes: "font_drop",
     maxHeight:200
    
    };
// end



    this.askform = this.formBuilder.group({
      title1: ['', Validators.maxLength(50)],
      query1: ['', Validators.maxLength(15)],
      brand2: [''],
      category:[''],
      category2:[''],
      category3:[''],
      modelname2: [''],
      description2: [''],
      orderid3: [''],
      query3: ['']
    });
    this.authservice.apipost('askNow/?request_flag=0', {}).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.brands = response.response.brands
        this.categories = response.response.categories
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  ngOnDestroy(){
    // alert('dsds')
    this.storage.clear('missproduct')
  }
  // toggleVisibility(e,a,index){
  //   // alert(e.target.checked)
  //   this.childindex=index;
  //   this.childcategoryid=a;
  //   if(e.target.checked==false){
  //     this.childcategoryid=undefined;
  //   }
    
  // }
  // Applyfilter(){
  //   alert(this.childcategoryid)
  // }

  // childcategory(shopbycategory){
  //   this.childindex=-1;
  //   this.childcategoryid=undefined;

  //   this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  
  //   this.authservice.apipost('CategoryList/', {
  //     flag: 'subcategoryall'

  //   }).subscribe((res: any) => {
  //     let response = res.json();
  //     if (response.status == 400 || response.status == 500 || response.status == 100) {
  //       Swal.fire(response.message)
  //     }
  //     else if (response.status == 200) {
  //       this.lastlevelcategory=response.response;
       
  //     }
  //     else { Swal.fire("Server Down") }
  //   }
  //     , err => {
  //       this.spinner.hide();
  //       Swal.fire("Connect to the Internet")
  //     }
  //   )
  //   // end
  
  // }
  childcategory(){
    this.authservice.apipost('CategoryList/', {
          flag: 'subcategoryall'
    
        }).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            this.lastlevelcategory=response.response;
           
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
  }

  // new module added For adding phase 3 dynamic brand dropdown in ask now radio button by Kunal bansal 29 oct 2020
  missingproduct(event){
    
  this.askform.value.brand2='';
    this.authservice.apipost('CategoryBrandList/', {
      category: event.target.value

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.brands=response.response;
       
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  askapi(i, obj) {
    this.authservice.apipost('askNow/?request_flag=' + i, obj).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        Swal.fire({
          title: response.response,
          allowOutsideClick: false,
          showCancelButton: false,
        }).then((result) => {
          if (result.value) {
            this.ngOnInit();
          }
        })
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  orderqueries() {
    this.authservice.apipost('getQueryList/',
      {
        platform: 0, //0 for web and 1 for   app
        offset: 1,
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }

        else if (response.status == 200) {

          this.storage.store("orderquerylist", response.response.query_list);
          this.storage.store('Totalorderqueries', response.response.total_query)
          this.router.navigate(['/homepage/orderqueries']);

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
  }


 

  ask({ value, valid }) {
   this.childcategoryid=value.category;
   this.childcategoryid2=value.category2;
   this.childcategoryid3=value.category3;
   var numberValue = Number(this.childcategoryid);
   var numberValue2=Number(this.childcategoryid2);
   var numberValue3=Number(this.childcategoryid3);
    if (this.querytype == '1') {
      if (JSON.stringify(value.title1).length == 0 || $.trim(value.title1).length == 0) {
        Swal.fire("Please enter a Subject")
      }
      else if (JSON.stringify(value.category).length == 0 || $.trim(value.category).length == 0) {
        Swal.fire("Please select a Category")
      }
      else if (JSON.stringify(value.query1).length == 0 || $.trim(value.query1).length == 0) {
        Swal.fire("Please enter a Query")
      }
      
      else {
        this.askapi(1, {
          "query": "general",
          "title": value.title1,
          "description": value.query1,
          "category_id":numberValue,
        })
      }
    }

    else if (this.querytype == '3') {
       if (JSON.stringify(value.category3).length == 0 || $.trim(value.category3).length == 0) {
        Swal.fire("Please select a Category")
      }
      // else if (JSON.stringify(value.brand2).length == 0 || $.trim(value.brand2).length == 0) {
      //   Swal.fire("Please select a brand")
      // }
      
      else if (JSON.stringify(value.modelname2).length == 0 || $.trim(value.modelname2).length == 0) {
        Swal.fire("Please enter a Model Name")
      }
      else if (JSON.stringify(value.description2).length == 0 || $.trim(value.description2).length == 0) {
        Swal.fire("Please enter a Description")
      }
      else {
        this.askapi(3, {
          "query": "product",
          "category_id":numberValue3,
          "brand_id": value.brand2,
          "name": value.modelname2,
          "description": value.description2
        })
      }
    }
    else if (this.querytype == '2') {
      console.log(value.orderid3, value.query3)
      if (JSON.stringify(value.orderid3).length == 0 || $.trim(value.orderid3).length == 0) {
        Swal.fire("Please enter an order number")  
        // Modified on 28 april 2020
      }
      else if (JSON.stringify(value.category2).length == 0 || $.trim(value.category2).length == 0) {
         // modified by Kunal bansal on 12 jan 2021
        if (JSON.stringify(value.query3).length == 0 || $.trim(value.query3).length == 0) {
          Swal.fire("Please enter your query")
        }
        else{
       
        this.askapi(2, {
          "query": "order",
          "order_id": value.orderid3,
          "description": value.query3,
          "category_id":"",
        })
      }
      }
      else if (JSON.stringify(value.query3).length == 0 || $.trim(value.query3).length == 0) {
        Swal.fire("Please enter your query")
      }
      
      else {
        this.askapi(2, {
          "query": "order",
          "order_id": value.orderid3,
          "description": value.query3,
          "category_id":numberValue2,
        })
      }
    }
  }
  query(event){
    if(event.target.value==1){
      this.storage.store('buystatus',0);
    }
    else{
      this.storage.store('buystatus',1);
    }
    // alert(event.target.value)
    this.authservice.apipost('getQueryList/',
    {
      platform: 0, //0 for web and 1 for   app
      offset: 1,
      "buy_request":event.target.value==1?'no':"yes"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }

      else if (response.status == 200) {

        this.storage.store("orderquerylist", response.response.query_list);
        this.storage.store('Totalorderqueries', response.response.total_query)
        this.router.navigate(['/homepage/orderqueries']);

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  onChange(value) {
    this.askform.value.orderid3='';
    this.querytype = value;
   if(this.querytype == '1'){
    this.childcategory();
   }
   if(this.querytype == '2'){
   
  // order query load api implmented here changed on 23 feb 2021
   }
  
  }

}