import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'app-bannerpopup',
  templateUrl: './bannerpopup.component.html',
  styleUrls: ['./bannerpopup.component.css']
})
export class BannerpopupComponent implements OnInit {
  showpopup:number=0;
@Input() actiontype:number;
@Input() bannerimage:string="";
@Input() bannersubimage:string="";
@Input() bannerid:number;

  @Input() popupimage:string="";
  @Input() disablepopup:number;

  constructor(private router:Router,private storage :SessionStorageService) { }

  ngOnInit() {
      // <!-- phase 6 popup banner added by kunal bansal on 29 april 2022 start -->
  
      let checkpopupstatus= sessionStorage.getItem('showpopup')
      if(checkpopupstatus==null){
   
     
          sessionStorage.setItem('showpopup','1')
          this.showpopup=1;
        
      
      
      }
      else{
        this.showpopup=0;
      }
  }

  hidepopup(){
    this.showpopup=0;
  }

  bannerdetails(actiontype, videolink, ban, banid) {
  
    this.hidepopup();
    // Added by Kunal on 23 april 2020
    // alert(actiontype)
    if (actiontype == 4) {
      this.router.navigate(['./homepage/Video']);
      this.storage.store("videostorage", videolink);
    }

    // modifed by Kunal bansal on 23 feb 2021 start
    else if (actiontype == 2) {
      // Added on 17 june 2020 by Kunal bansal start
      // document.location.href = ;
      // added on 29 june 2020 start
      window.open(videolink, "_blank");
      // end
      // end
    }
    // end
    else if (actiontype == 3) {
      // Modified on 22 june 2020 by Kunal bansal start
      // added on 29 june 2020 start
      // 719 point(amazon link not open) fixed on 13 july 2020 by Kunal bansal start
      if (videolink.includes('http')) {

        window.open(videolink, "_blank");
      }
      else {

        window.open("//" + videolink, "_blank");
      }
      // end
      // commented on 29 june 2020
      // document.location.href = videolink;  // Added by Kunal on 25 april 2020
      // end
    }
    else if (actiontype == 5) {
      // added by Kunal on 22 june 2020 start
      this.storage.store("bannerid", banid);
      this.storage.store('checkbanner', 1);
    
     this.storage.clear('brandlabelslisting');
      this.router.navigate(['/homepage/search']);
      // alert('action type 5')
      // modified on 17 june 2020 by Kunal bansal start

      // end

    }
    else {
      // Added on 23 feb 2021 by Kunal bansal start

      window.open(ban, "_blank");
    }

  }

}
