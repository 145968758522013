import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-myorderlabels',
  templateUrl: './myorderlabels.component.html',
  styleUrls: ['./myorderlabels.component.css']
})
export class MyorderlabelsComponent implements OnInit {
  @Input() duplicatecatfilters:any=[];
  @Input() Applieddatefrom:any=[];
  @Input() Applieddateto:any=[];
  @Input() Appliedordero:any=[];
  @Input() Appliedorderstatus:any=[];
  @Input() Appliedordertype:any=[];
  @Input() Appliedagentcodeapi:any=[];
  @Input() Appliedpaymentlabels:any=[];

  constructor() { }

  ngOnInit() {
  }

}
