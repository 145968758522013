import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-querydetails',
  templateUrl: './querydetails.html',
  styleUrls: ['./querydetails.css']
})

export class Querydetails implements OnInit {
  submitForm: FormGroup;

  actionflag:string='';
  name:string='';
  type:string='';
  brief:string='';
  querycode:any[];
  createdon:any[];
  comments: any[];
  buystatus:number;

 
  queryid2: number;
  orderid:number;
  querydetail:any[];





  constructor(private globals: Globals, private formBuilder: FormBuilder, private storage: SessionStorageService, private authservice: AuthService, private router: Router, private spinner: NgxSpinnerService) { }




  ngOnInit() {
    // this.querylist = this.storage.retrieve('querylisting');
    this.orderid=this.storage.retrieve('orderqueryid');
    this.buystatus=this.storage.retrieve('buystatus');
 
    // this.admincomment = this.storage.retrieve('querydetailing');
    this.submitForm = this.formBuilder.group({
      chat: ['']
    });
    this.authservice.apipost('getQueryList/',
    {

      platform: 0, //0 for web and 1 for   app
      offset: 1,
      query_id:this.orderid,
      "buy_request":this.buystatus==0?"no":"yes"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        this.router.navigate(['/homepage/orderqueries']);
        Swal.fire(response.message)
      }

      else if (response.status == 200) {
       
        this.actionflag=response.response.query_list[0].action_flag;
        this.querycode=response.response.query_list[0].query_code;
        this.name=response.response.query_list[0].name;
        this.type=response.response.query_list[0].request_type;
        this.createdon=response.response.query_list[0].created_on;
        if(this.type=="General"){
          this.brief=response.response.query_list[0].name;
        }
        else{
          this.brief=response.response.query_list[0].comments;
        }
      
        this.comments=response.response.query_list[0].admin_newcomment;
       
        
        
        // this.storage.store('querydetailing',response.response.query_list);
       
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )



  }

  ngOnDestroy() {
    this.storage.clear('querylisting');
    this.storage.clear('querydetailing');
    

  }


  submit(a) {
  //  fix only space  not allowed in chat submit issue by Kunal bansal on 27 jan 2021
    if (this.submitForm.value.chat.length == 0 || this.submitForm.value.chat.trim() == "") {
      Swal.fire('Please Enter Valid Comment');
    }
    else {
    
      this.queryid2 = a;
      this.authservice.apipost('QueryComment/',
        {
          "query_id": a,
          "message": this.submitForm.value.chat,
          "buy_request":this.buystatus==0?"no":"yes"


        }).subscribe((res: any) => {
          let response = res.json();
          
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            this.submitForm.value.chat ="";
            Swal.fire(response.message)
          }

          else if (response.status == 200) {
            this.submitForm.value.chat =""
            // Swal.fire(response.message)
            this.authservice.apipost('getQueryList/',
              {

                platform: 0, //0 for web and 1 for   app
                offset: 1,
                query_id: this.queryid2,
                "buy_request":this.buystatus==0?"no":"yes"
              }).subscribe((res: any) => {
                let response = res.json();
                if (response.status == 400 || response.status == 500 || response.status == 100) {
                  Swal.fire(response.message)
                }

                else if (response.status == 200) {
                  this.actionflag=response.response.query_list[0].action_flag;
                  this.querycode=response.response.query_list[0].query_code;
                  this.name=response.response.query_list[0].name;
                  this.type=response.response.query_list[0].request_type;
                  this.createdon=response.response.query_list[0].created_on;
                  this.brief=response.response.query_list[0].name;
                  this.comments=response.response.query_list[0].admin_newcomment;


                }
                else { Swal.fire("Server Down") }
              }
                , err => {
                  this.spinner.hide();
                  Swal.fire("Connect to the Internet")
                }
              )
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
    }

  }
}