import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import { AuthService } from 'src/app/auth.service';
import Swal from 'sweetalert2';
import { Options, LabelType } from 'ng5-slider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-commonhomepagefilter',
  templateUrl: './commonhomepagefilter.component.html',
  styleUrls: ['./commonhomepagefilter.component.css']
})
export class CommonhomepagefilterComponent implements OnInit {
  @Input() category: any = [];
  @Input() subcategory: any = [];
  @Input() categorylevel3: any = [];



  @Output() submitemitfilter = new EventEmitter<any>();

  filterdatalength: number = 0;

  selectedItems = [];
  filtersortfirst = [];

  selectedItems2 = [];
  filtersortsecond = [];

  selectedItems3 = [];

  firstlevel = [];
  secondlevel = [];

  selectedbrand = [];
  selectedcolor = [];
  selectedmemory = [];
  selectedstorage = [];
  selectedbattery = [];
  selecteddisplay = [];
  selectedhighrange: number;
  selectedlowrange: number;
  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b>' + value;
        case LabelType.High:
          return '<b>Max price:</b>' + value;
        default:
          return '' + value;
      }
    }
  };
  filterdata: any = [];

  dropdownSettings1 = {};
  dropdownSettings2 = {};
  dropdownSettings3 = {};

  constructor(private modalService: NgbModal, private storage: SessionStorageService, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.dropdownSettings2 = {
      singleSelection: false,
      text: "Select",
      labelKey: "category_name",
      classes: "font_drop",
      position: 'top',
      autoPosition: false,

      maxHeight: 200
    };

    this.dropdownSettings1 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "value",
      primaryKey: "Id",
      badgeShowLimit: 4,
      autoPosition: false,
      position: "top",
      classes: "font_drop",
     
    };

    this.dropdownSettings3 = {
      singleSelection: true,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "category_name",
      classes: "font_drop",
      position: 'top',
      autoPosition: false,

      maxHeight: 200
    };
  }

  // function added by Kunal for category based filter added on 2 july 2020 start
  onItemSelect(item: any) {
    // if (this.firstlevel.includes(item.id)) {

    // }
    // else {
    // this.firstlevel.push(item.id)
    this.filtersortfirst = [{ 'id': item.id, 'name': item.category_name }]
    this.firstlevel = item.id;

    // Added by Kunal on 02 july 2020 start
    this.authservice.apipost('CategoryList/', {
      "category_id": [this.firstlevel], "flag": "subcategory"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems2 = [];
        this.subcategory = response.response;

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
  }

  Removefirstlevel(item: any) {
    this.selectedItems2 = [];

    // Kiran code manual merge on 19jan2021 start
    let parent = this.firstlevel.indexOf(item.id);
    this.firstlevel.splice(parent, 1);

    this.authservice.apipost('CategoryList/', {
      "category_id": this.firstlevel, "flag": "subcategory"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {

        this.subcategory = response.response;

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

    // end
  }

  onDeSelectAllsecond() {
    alert('working')
    this.firstlevel = [];
    this.filtersortfirst = [];
  }

  // added on 06 july 2020 by Kunal bansal start
  onItemSelect2(item: any) {


    if (this.secondlevel.includes(item.id)) {

    }
    else {
      this.secondlevel.push(item.id)
      this.filtersortsecond.push({ 'id': item.id, 'name': item.category_name })
    }

    this.authservice.apipost('CategoryList/', {
      "category_id": this.secondlevel, "flag": "subcategorylevel2"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems3 = [];

        // if(response.response.length ==0){
        //   this.selectedItems3=[];
        //   this.fourthlevelfilter(id)

        // }
        // else{

        this.categorylevel3 = response.response;
        if (this.categorylevel3.length == 0) {
          this.fourthlevelfilter(item.id)
        }
        else {
          // console.log('ggg')
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  // end

  fourthlevelfilter(id) {

    this.selectedbrand = [];
    this.selectedcolor = [];
    this.selectedmemory = [];
    this.selectedstorage = [];
    this.selectedbattery = [];
    this.selecteddisplay = []
    this.selectedhighrange = 0;
    this.selectedlowrange = 0;

    this.authservice.apipost('filter_by/', { "category_id": id }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.filterdatalength = response.response.filters.length;
        if (response.response.filters.length > 0) {
          this.filterdata = response.response;

          this.options.floor = response.response.min_price;
          this.selectedlowrange = response.response.min_price;
          this.selectedhighrange = response.response.max_price;
          this.options.ceil = response.response.max_price;
        }
        else {

          this.filterdata = [];
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  Removesecondlevel(item: any) {
    console.log(this.filtersortsecond)
    for (let k = 0; k < this.filtersortsecond.length; k++) {
      if (this.filtersortsecond[k].name == item.category_name) {
        this.filtersortsecond.splice(k, 1)
        this.secondlevel.splice(k,1)
      }
    }
    console.log(this.filtersortsecond)
  }

  Resetfilter() {

    if (window.location.pathname == "/homepage/productListing") {
      this.storage.store('sidedraweractivate', 0)
      location.reload();
    }
    else {
      this.storage.store('sidedraweractivate', 0)
      this.router.navigate(['/homepage/productListing']);
    }
  }

  closeall() {
    this.modalService.dismissAll();
  }

  submitfilter() {
    this.submitemitfilter.emit({
      categorylevel1: this.selectedItems,
      categorylevel1filter: this.filtersortfirst, categorylevel2: this.selectedItems2,
      categorylevel2filter: this.filtersortsecond,
      minprice: this.selectedlowrange,
      maxprice: this.selectedhighrange,
      filterdata: this.filterdata,
      selectedbrand: this.selectedbrand,
      selectedcolor: this.selectedcolor,
      selectedmemory: this.selectedmemory,
      selectedstorage: this.selectedstorage,
      selectedbattery: this.selectedbattery,
      selecteddisplay: this.selecteddisplay
    });
  }

}
