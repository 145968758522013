import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css','../../assets/Newwebfiles/style.css']
})
export class WhoWeAreComponent implements OnInit {
  //public imagesUrl;
  contents: any ={};
  imagesUrl: any ={};
  imgList: any ={}; 
  public imgCarousels;
  items: any[]=[] ;


  

  constructor(private server: DataService, private router: Router) { 
    this.server.getpartner().then((response: any) => {
      // this.items = response.data;
      // this.imagesUrl.forEach(item => {
      //   let str: string = item.img;
      //   this.imgList.push(str); 
      //   //this.material_sub_total = item.img;
      // });
       
      //this.ngOnInit(this.imgList);
    });
   
   }

  ngOnInit() {
    
    
    this.getWhoweareContents();
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.partnerimages();
    //this.imgCarousels = this.imgList;
    //console.log(this.imgCarousels);
    this.imgCarousels = ['/assets/images/partners/img_1.png', '/assets/images/partners/img_2.png', '/assets/images/partners/img_3.png', '/assets/images/partners/img_4.png', '/assets/images/partners/img_5.png'];
  }

  private getWhoweareContents() {
    this.server.getWhoweareContents().then((response: any) => {
      this.contents = response.data[0];
    });
  }

  private getpartner(imgList) {
    
  }

  // <!-- Partner images Not coming and s3 production module added by Kunal bansal on 26 feb 2021 -->
  partnerimages(){

    this.items.push(
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/Croma.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/Blue_dart.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/DTDC.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/eagle_wings.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/media_mantra.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/razorpay.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/shiprocket.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/softprodigy.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/super_money.jpg'},
      {'img':'https://eolproductimages.s3.ap-south-1.amazonaws.com/web_file/Partners/udaan.jpg'},
    )
  }
  // end

}
