import { Injectable } from "@angular/core";
import { SessionStorageService } from "ngx-webstorage";
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn:'root'
})
export class SharedService {
  constructor(private storage:SessionStorageService) {

  }
  sectionChanged$: BehaviorSubject<{section:number,category:number}> =
    new BehaviorSubject<{section:number,category:number}>({section:0,category:this.storage.retrieve('catid')});

}
