import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.css']
})

export class OrderTrackingComponent implements OnInit {
  id: any;
  from: any;
  ordertrackingdetails: any;
  // <!-- order traking module added  on 25jan2021 by Kunal bansal -->
  ordernumber:number;
  paymentmode:string;

  constructor(private storage:SessionStorageService,private router: Router,private authservice: AuthService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.ordernumber= this.storage.retrieve("ordertrekkingno");
    this.paymentmode=this.storage.retrieve("ordertrekkingpaymentmode");
    this.id = this.storage.retrieve('orderid');
    if (this.id == null || this.id == undefined) {
      this.router.navigate(['/homepage/orders']);
    }
    else {
      this.from = this.storage.retrieve('from_buyorsell');
      this.authservice.apipost('orderTrackingStatus/', { order_id: this.id }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {
          this.ordertrackingdetails = response.response;
          setTimeout(()=>{ 
            $('.line_green').css('background-position','top');
          }, 2000);
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err);
        }
      )
    }
  }

}
