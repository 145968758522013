import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
// <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
import { Uaepoboxes } from '../../app/Services/Uaepoboxes.service';
// ....end....


@Component({
  selector: 'app-add-new-address',
  templateUrl: './add-new-address.component.html',
  styleUrls: ['./add-new-address.component.css']
})

export class AddNewAddressComponent implements OnInit {
  addform: FormGroup;
  goingthroughgeneratepickup: number = 0;
  add1message: any;
  add2message: any;
  // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
  country = [];
  selectedcountry: string;
  uaecities = [];

  uaelocationcities = [{ "name": "Please Select Location", "value": "" }, { "name": "Freezone", "value": "Freezone" }, { "name": "Mainland", "value": "Mainland" }];
  // ....end....
  uaelocationtype: string;
  Freezonelisting = [];
  uaecountries = [];
   // phae 6 added by Kunal bansal on 13 may 2022 start
 address_type:number;
 // ...end...



  constructor(private pobox: Uaepoboxes, private storage: SessionStorageService, private authservice: AuthService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.addform = this.formBuilder.group({
      name: [''], number: [''], address1: [''], address2: [''], pincode: [''], state: [''], city: [''], landmark: [''], location_type: [''],uaeselectedlocation:['']
    });

    $(document).ready(function () {
      $("input[type=number]").keypress(function (evt) {
        if ((evt.which < 48 || evt.which > 57) || ($(this).val().length == 10)) {
          if (evt.which == 8 || evt.which == 0) { }
          else { evt.preventDefault(); }
        }
      })
    });
    this.goingthroughgeneratepickup = this.storage.retrieve("goingthroughgeneratepickup");
    // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
    this.country = ['India', 'UAE'];
    this.selectedcountry = "India";
    // ...end...
    this.getfreezonelisting();
    //phase 5 added by Kunal bansal on 03 nov 2021 due to new scenario of pobox autofetch reklation break and city fetched based on country
    this.gettalluaecountries();
    
     // phase 6 billing and shipping address scenario added by Kunal bansal on 13 may 2022 start
     this.address_type=this.storage.retrieve("address_type")
   
     // ...end...
  }

  //  added by Kunal bansal on 15 march 2021 start
  ngOnDestroy() {
    if (window.location.pathname == "/homepage/cart") {

    }
    else {
      this.storage.store('checkcodpre', 1)
    }
  }
  // end

  // phase 5 new cr uae freezone and mainland cities module added by Kunal bansal on 03 nov 2021 start
  getfreezonelisting() {
    try {
      this.pobox.getfreezonelisting().map(res => {

        this.Freezonelisting = res.response;
        console.log(this.Freezonelisting);
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  gettalluaecountries() {

    try {
      this.pobox.getallcountries().map(res => {

        if (res.status == 200) {
          console.log(res.response);

          for (let n = 0; n < res.response.length; n++) {
            this.uaecountries.push(res.response[n].country)
          }
          console.log(this.uaecountries)
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      console.log(err)
      // this.model.state = '';
      // // this.uaecities = [];
    }

  }
  // ...end...

  locationtype(event) {
    this.addform.patchValue({
      uaeselectedlocation: '',
    });
    this.uaelocationtype = event.target.value;


  }

  // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
  getpobox() {

    if (this.addform.value.pincode == undefined || this.addform.value.pincode=="") {
      // Swal.fire('Enter valid Po Box Number')
    }
    else {
      try {
        this.pobox.Getponumber(this.addform.value.pincode).map(res => {

          if (res.status == 200) {
            console.log(res.response)

            // this.addform.patchValue({
            //   state: res.response.country,

            // });
            // this.uaecities = res.response.cities;

          }
          else {

          }
        })
      }
      catch (err) {
        Swal.fire("Enter valid Pobox number");
        // this.uaecities = [];
        this.addform.patchValue({
          pincode: '',
        });
      }

    }
  }

  selectuaecountry(event) {
    this.addform.patchValue({
      city: '',
    });
    try {
      this.pobox.getcitybycountry(event.target.value).map(res => {

        if (res.status == 200) {
          this.uaecities = res.response.cities;
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      // this.addform.patchValue({
      //   state: '',
      // });
      // this.uaecities = [];
      console.log(err)
    }
  }
  // ...end...

  changecountry(event) {

    this.selectedcountry = event.target.value;
    this.addform.patchValue({
      pincode: '',
      state: '',
      city: ''

    });
    this.uaecities = [];

    if (event.target.value == "India") {
      this.country = ['India', 'UAE'];

    }
    else {
      this.country = ['UAE', 'India'];

    }


  }

  // ..............end.............





  // <!-- hint and validation add address new phase 4 module added by Kunal bansal on 09 april 2021 start -->
  add1validate() {

    // alert(this.addform.value.address1.length)
    this.add1message = "Maximum 70 Characters are allowed";
    if (this.addform.value.address1.length == 70) {
      this.add1message = "Maximum 70 Characters limit reached";
    }
  }

  add2validate() {
    this.add2message = "Maximum 70 Characters are allowed";
    if (this.addform.value.address2.length == 70) {
      this.add2message = "Maximum 70 Characters limit reached";
    }
  }

  emptymsg() {
    this.add1message = "";
    this.add2message = "";
  }

  // end

  add() {
    if ((this.selectedcountry == 'India' && this.addform.value.pincode == '') || this.addform.value.state == '' || this.addform.value.city == '' || this.addform.value.address1 == '' || this.addform.value.name == '' || this.addform.value.number == '' || (this.addform.value.location_type == '' && this.selectedcountry == 'UAE') || (this.addform.value.uaeselectedlocation == '' && this.selectedcountry == 'UAE')) {
      Swal.fire("Please fill all mandatory fields");
    }
    else if (JSON.stringify(this.addform.value.number).length < 10) {
      Swal.fire("Please fill a valid mobile number");
    }
    else {
      this.authservice.apipost('Address/?flag=1', {
        pincode: this.addform.value.pincode,
        state: this.addform.value.state.trim(),
        city: this.addform.value.city.trim(),
        address_line_1: this.addform.value.address1,
        address_line_2: this.addform.value.address2,
        name: this.addform.value.name,
        phone: this.addform.value.number,
        // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
        country: this.selectedcountry,
        // ...end...
        landmark: this.addform.value.landmark,
        user_location: this.addform.value.uaeselectedlocation,
        location_type: this.selectedcountry == 'UAE' ? this.addform.value.location_type : "",
        defaultaddress: $("#customCheck1").prop("checked") == false ? 0 : 1,
          // phase 6 billing and shipping address scenario added by Kunal bansal on 13 may 2022 start
          address_type:this.address_type
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {
          Swal.fire(response.message)
          // <!-- phase 3 new cr selecting or add address module added by Kunal bansal on 15 march 2021 start-->
          if (this.goingthroughgeneratepickup == 1) {
            this.router.navigate(['./homepage/orders/sold'])
          }
          else if (this.goingthroughgeneratepickup == 2) {
            this.router.navigate(['./homepage/cart'])
          }
            // phase 6 add address alert scenario by kunal bansal on 07 april 2022 start
          else if(this.goingthroughgeneratepickup==3){
            let url=sessionStorage.getItem("pageurl");
            window.open(url,"_self")
          }
          // ...end...

           // phase 6 add address banner show in case no address added scenario by kunal bansal on 21 april 2022 start
           else if(this.goingthroughgeneratepickup==4){
            this.router.navigate(['./homepage/productListing'])
  
          }
          else if(this.goingthroughgeneratepickup==5){
            this.router.navigate(['./homepage/kycUpload'])
  
          }
          // ...end...
          else {
            this.router.navigate(['/homepage/profile']);
          }
          // end
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
  }


  getpincode() {
    if (JSON.stringify(this.addform.value.pincode).length < 6 || JSON.stringify(this.addform.value.pincode).length > 6) {
      Swal.fire("Please enter a valid Pincode");
    }
    else {
      this.authservice.apipost('getPincode/', {
        "pincode": JSON.stringify(this.addform.value.pincode)
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500) {
          Swal.fire(response.message)
        }
        else if (response.status == 100) {
          Swal.fire('No State City found linked to your pincode , Please try another pincode');
        }
        else if (response.status == 200) {
          this.addform.controls['state'].setValue(response.response.state)
          this.addform.controls['city'].setValue(response.response.city)
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
  }

}