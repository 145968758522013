import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})

export class FaqComponent implements OnInit {
  faq: any[];
  temp: number ;

  constructor(private authservice: AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
  
    this.authservice.apipost('faq/', {}).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.faq = response.response.faq_list;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  openfaq(i) {
    if (this.temp == i) {
      this.temp = undefined;
     }
    else {
      this.temp = i
    }
  }

}