import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-kyc-edit',
  templateUrl: './kyc-edit.component.html',
  styleUrls: ['./kyc-edit.component.css']
})
  
export class KycEditComponent implements OnInit {
  kycForm: FormGroup;
  details: any;
  closeResult: string;
  selectedimage = "";
  pancard: File = null;
  cheque: File = null;
  additional: File = null;

  country:string='India';

  constructor(private authservice: AuthService,private spinner: NgxSpinnerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.country = sessionStorage.getItem('phase5country');
    this.authservice.apiget('UploadKYCDetails/').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.details = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  viewimage(imga, url) {
    this.selectedimage = url;
    this.modalService.open(imga, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }

  // added by Kunal bansal on 27 may 2021 start
  viewgstcertificate(url){
    window.open(url)
  }
  // end

  // <!-- UAE kyc fields added by Kunal bansal on 07 dec 2021 -->
  viewvatcertificate(url){
window.open(url)
  }

  dismissall() {
    this.modalService.dismissAll();
  }

}