import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
  
export class TermsAndConditionsComponent implements OnInit {
  header: string = '';
  subheader: string = '';
  content: string = '';

  constructor(private authservice: AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.authservice.apipost('tandc/', {}).subscribe((res: any) => {
      let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.header = response.response.header;
          this.subheader = response.response.sub_header;
          this.content = response.response.content;
        }
        else { Swal.fire("Server Down") }
      }
        , err => {   
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )
  }

}