import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
  
export class ChangePasswordComponent implements OnInit {
  model: any = {};
  
  constructor(private storage: SessionStorageService,private router: Router, private authservice: AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit() { }

  onSubmit() {
    var filter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,}$/;
    if (filter.test(this.model.Oldpassword) == false) {
      Swal.fire("Please enter a valid old password")
    }
    else if (filter.test(this.model.Newpassword) == false) {
      Swal.fire("Your New password must include upper and lowercase letters, numbers and a special character")
    }
    else if (this.model.Newpassword != this.model.Confirmpassword) {
      Swal.fire("New password and confirm password must be same")
    }
    else {
      this.authservice.apipost('changePassword/', {
        old_pwd: this.model.Oldpassword,
        new_pwd: this.model.Confirmpassword
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // reset password logout module added by KUnal bansal on 27jan2021
          // Swal.fire(response.message)
          Swal.fire("You have been Signed out succesfully. Login with New Password")
          // this.router.navigate(['/homepage/productListing']);
           // reset password logout module added by KUnal bansal on 27jan2021
          this.storage.store("islogged", 0);
        this.storage.clear("token");
        this.router.navigate(['/homepage/login']);
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

}