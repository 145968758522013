import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-commoncategorypopup',
  templateUrl: './commoncategorypopup.component.html',
  styleUrls: ['./commoncategorypopup.component.css']
})
export class CommoncategorypopupComponent implements OnInit {
  temp: number;
  @Input() childcategory: any = [];
  labelling: any = [];

  @Input() category: any = [];
  @Output() emitlabelling = new EventEmitter<any>();
  @Output() submitcategorymyprofile = new EventEmitter<any>();

  activepagename: string = '';

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    if (window.location.href.includes('profile')) {
this.activepagename='profile';
    }
    else {
this.activepagename='signup';
    }
  }

  openfaq(i) {

    if (this.temp == i) {
      this.temp = undefined;
    }
    else {


      this.temp = i

    }
  }

  closeall() {
    this.modalService.dismissAll();
  }

  // <!-- New phase 3 function for select category prefrence for manual child category selection on signup added by kunal bansal on 11 march 2021 start -->
  onebyone(event, i, c) {

    if (event.target.checked == true) {


      if (this.childcategory.includes(this.category[i].child_category[c].id)) {

      }
      else {
        // added by Kunal bansal on 23 dec 2021 during phase 5 start
        if (this.childcategory.includes(this.category[i].id)) {

        }
        else {
          this.childcategory.push(this.category[i].id);
          this.category[i].checked = true;
          this.labelling.push(this.category[i]);
        }

        // ...end...

        this.childcategory.push(this.category[i].child_category[c].id);
        this.labelling.push(this.category[i].child_category[c]);
      }

      // New fucntionality added if all child selecting parent automatic selected by Kunal bansal on 01 april 2021 start
      let g = 0;
      for (let n = 0; n < this.category[i].child_category.length; n++) {


        if (this.category[i].child_category[n].checked == true) {
          // console.log(n)
          // console.log('true available')

          g = g + 1;
          // console.log(k);

        }
        else {
          // console.log("not found");

        }
        // console.log(k)
      }

      if (this.category[i].child_category.length == g) {
        console.log("all selected");
      }
      console.log(g)
    }
    // end

    else {

      let p = this.childcategory.indexOf(this.category[i].child_category[c].id);
      this.childcategory.splice(p, 1);
      this.labelling.splice(p, 1);

    }

    // New fucntionality added if al child unselect one by one on unticking last child parent also removed by Kunal bansal on 01 april 2021 start

    let k = 0;
    for (let n = 0; n < this.category[i].child_category.length; n++) {


      if (this.category[i].child_category[n].checked == true) {
        // console.log(n)
        // console.log('true available')

        k = k + 1;
        // console.log(k);

      }
      else {
        // console.log("not found");

      }
      // console.log(k)
    }
    if (k == 0) {
      this.category[i].checked = false;
      let f = this.childcategory.indexOf(this.category[i].id);
      if (this.childcategory.includes(this.category[i].id)) {
        this.childcategory.splice(f, 1);

        this.labelling.splice(f, 1);
      }

      console.log(this.childcategory)
    }
    this.emitlabelling.emit({ childcategory: this.childcategory, labels: this.labelling })

  }



  selectall(event, f) {
    if (event.target.checked == true) {
      if (this.childcategory.includes(this.category[f].id)) {

      }
      else {
        this.childcategory.push(this.category[f].id);
        this.labelling.push(this.category[f]);
      }

      for (let i = 0; i < this.category[f].child_category.length; i++) {
        this.category[f].child_category[i].checked = true;
        if (this.childcategory.includes(this.category[f].child_category[i].id)) {

        }
        else {
          this.childcategory.push(this.category[f].child_category[i].id);
          this.labelling.push(this.category[f].child_category[i])
        }

      }
    }
    else {

      for (let i = 0; i < this.category[f].child_category.length; i++) {
        this.category[f].child_category[i].checked = false;

        if (this.childcategory.includes(this.category[f].id)) {
          if (this.childcategory.includes(this.category[f].child_category[i].id)) {
            let k = this.childcategory.indexOf(this.category[f].child_category[i].id);
            this.childcategory.splice(k, 1);
            this.labelling.splice(k, 1);
          }
        }
      }
      if (this.childcategory.includes(this.category[f].id)) {
        let po = this.childcategory.indexOf(this.category[f].id);
        this.childcategory.splice(po, 1);
        this.labelling.splice(po, 1);
      }
    }

    this.emitlabelling.emit({ childcategory: this.childcategory, labels: this.labelling })
    // alert(JSON.stringify(this.childcategory))
  }

  Resetcategory() {
    for (let j = 0; j < this.category.length; j++) {
      this.category[j].checked = false;
      for (let s = 0; s < this.category[j].child_category.length; s++) {



        if (this.category[j].child_category[s].checked == true) {
          this.category[j].child_category[s].checked = false;
        }

      }
    }
    this.childcategory = [];
    this.labelling = [];
    this.emitlabelling.emit({ childcategory: this.childcategory, labels: this.labelling })

  }

  submitcategory() {
    if (window.location.href.includes('profile')) {
      this.submitcategorymyprofile.emit();
    }
    else {
      this.modalService.dismissAll();
    }


    // alert(JSON.stringify(this.childcategory))
  }
}
