import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})

export class MyOrdersComponent implements OnInit {
  orders: any[];
  orderdisplaytype: number = 0;

  constructor(private authservice: AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit() {

    
  }

}